.form {
  .error {
	background: none;
  }
  input.required.error {
	box-shadow: inset 0 0 0 1px #00e6e6;
	background: #ccfafa;
	color: #6f8a8a;
  }
  input.form-control {
	color: #8d8d8d;
  }
  input.form-control:focus {
	box-shadow: inset 0 0 0 1px #f2a38b;
	background: #ffffff;
  }
  .error .btn {
	position: static;
  }
  // Below class for hiding Spam Protection Fields
  .red {
	display: none;
  }
}
