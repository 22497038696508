body.burger-collapse {
    overflow: hidden;
    .navbar-inverse {
        height: 100%;
    }
    .navbar-fixed-top {
        overflow: auto;
    }
}
body.search-module-collapse{
    overflow: hidden;
}
.navbar-fixed-top {
    z-index: 99999;
}
.contacts {
  float     : right;
  font-size : @p;
  padding   : 8px 0;
  color     : @orange;
  p {
	float   : left;
	padding : 0 2px 0 20px;
	.phone {
	  font-weight : bold;
	  line-height : 14px;
	  font-size   : @pbig2;
	}
  }
  .social {
	float : left;
  }
}
.header-center {
  text-align: center;
  .contact-wraper {
    padding   : 24px 0 22px;
    color     : @orange;
    p {
        padding : 0;
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-family: NexaBold;
        font-weight: 600;
    }
  }
}
.header-right {
  text-align: right;
  .contact-wraper {
    font-size : @p;
    padding   : 0;
    p {
        color : #ffffff;
        padding: 23px 2px;
        margin: 0 19px 0 0;
        font-family: NexaBold;
        font-weight: 600;
        a {
            color     : @orange;
            padding-left: 8px;
        }
    }
  }
}
.navbar-inverse {
  height     : 70px;
  border     : 0;
  box-shadow : none;
  background-color: #532A3A;
  &:hover {
      background-color: #472230;
  }
  .navbar-brand {
	float       : left;
	background  : url(../images/tk-logo.svg) no-repeat;
	height      : 45px;
	text-indent : -999em;
	margin      : 43px 0 0 0 !important;
	width       : 258px;
  }
  .navbar-collapse {
	float  : right;
	margin : 26px -15px 0 0;
  }
  .dropdown-menu {
	background    : #472230;
	border        : 0;
	box-shadow    : none;
	border-radius : 0;
	padding       : 7px 0 13px;
	li {
	  font-size     : 12px;
	  margin-bottom : -6px;
	  line-height   : 20px;
	  a {
		display        : block;
		color          : @white;
		padding-top    : 7px;
		padding-bottom : 7px;
		line-height    : 20px;
		white-space    : nowrap;
		text-transform : uppercase;
		&:hover {
		  color      : @orange;
		  background : none
		}
	  }
	}
  }
  .nav-child {
      padding: 17px 0 7px;
      li {
          a {
	      color: #FFFFFF;
	      font-family: "NexaBold";
	      font-size: 16px;
              line-height: 22px;
              text-transform: capitalize;
              background: transparent;
          }
      }
  }
  .navbar-nav > li > a:focus {
      background: 0 0;
      color: #f05323;
  }
  .navbar-nav {
	.active > a {
	  background-color : transparent;
	  color            : @orange;
	  &:hover {
		background-color : transparent;
		color            : @orange;
	  }
	}
	> li > a {
	  font-size      : @p;
	  text-shadow    : none;
	  color          : @white;
	  line-height    : 55px;
	  text-transform : uppercase;
	  padding        : 0 15px;
	  &:hover {
		background : none;
		color      : @orange;
	  }
	}
  }
  .navbar-nav > li.open > a,
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:hover,
  .navbar-inverse .navbar-nav > .open > a:focus {
	background : none !important;
	color      : @orange !important;
	&:after {
	  width         : 0;
	  height        : 0;
	  border-left   : @p * .75 solid transparent;
	  border-right  : @p * .75 solid transparent;
	  border-bottom : @p * .75 solid @purple;
	  position      : absolute;
	  bottom        : 0;
	  left          : 50%;
	  margin-left   : -@p * .75;
	}
  }
}

.navbar-inverse .navbar-brand {
  width           : 180px;
  height          : 31px;
  margin          : 20px 15px 0 0 !important;
  background-size : contain;
}

.mobile-links {
  float        : left;
  margin : 0 35px 0 20px;
  padding      : 25px 0 0 0;
  .ico-contacts{
      display: none;
  }
  .contacts {
	background : @purple !important;
	width      : 100% !important;
	padding    : 13px 0 !important;
	a {
	  margin-left : 0;
	  width       : auto;
	  &:before {
		color     : @orange;
		font-size : 1em;
	  }
	}

	a, p, .social, .social ul, .social li {
	  display : inline-block;
	  float   : none;
	}
	p {
	  display       : block;
	  line-height   : 24px;
	  margin-bottom : 0;
	  .phone {
		line-height : inherit;
	  }
	  + p {
		margin-top : 8px;
	  }
	}
	.social {
	  display    : block;
	  margin-top : 13px;
	  p,
	  ul {
		display        : inline-block;
		line-height    : 21px;
		vertical-align : middle;
	  }
	  li {
		a {
		  padding : 0;
		}
	  }
	  [class^="icon-"]:before,
	  [class*=" icon-"]:before {
		margin-left  : 0;
		margin-right : 0;
	  }
	}
  }


  .dropdown {
	float : left;
  }

  .search {
	overflow   : hidden;
	text-align : center;
	h3 {
	  font-size   : @pbig2;
	  font-weight : normal;
	  text-align  : center;
	  color       : @white;
	  margin      : 0;
	  padding     : 0 0 15px;
	}
	form {
	  padding : 15px;
	  margin  : 0;
	  .clearfix;
	}
  }

  .buttons {
	overflow   : hidden;
	position   : relative;
	padding    : 10px 0;
	margin-top : 10px;
	.div {
	  position    : absolute;
	  left        : 50%;
	  width       : 1px;
	  margin-left : -1px;
	  background  : @orangelight;
	  top         : 0;
	  bottom      : 0;
	}
	.btn {
	  float       : left;
	  height      : 40px;
	  .news {
		margin         : -5px 0;
		padding-bottom : 83px;
		h3 {
		  height          : 40px;
		  background-size : auto 100%;
		  font-size       : @pbig2;
		  line-height     : 40px;
		}
		a.btn {
		  margin-top : 7px;
		}
		.tweet {
		  padding       : 40px 0 0;
		  margin-bottom : 22px;
		  line-height   : 18px;
		}
		.item {
		  padding-bottom : 5px;
		  min-height     : 355px;
		}
	  }
	  .orange-boxes {
		padding : 49px 0;
		.col-md-6:last-child {
		  .item {
			margin-left  : -8px;
			margin-right : 0;
		  }
		}
		.item {
		  background   : @orange90;
		  text-align   : center;
		  font-size    : @pbig2;
		  margin-right : -8px;
		  padding      : 20px 0 25px;
		  min-height   : 222px;
		  line-height  : @pbig2-lh;
		  color        : @white;
		  &:hover {
			background : @orange;
		  }
		  h3 {
			color     : @purpledark;
			margin    : 0 0 15px;
			font-size : 30px;
			.nexa;
		  }
		  p {
			margin : 0 0 17px;
		  }
		  .btn {
			padding    : 0 40px;
			min-width  : 218px;
			text-align : center;
		  }
		}
	  }
	  line-height : 38px;
	  width       : 44%;
	  &:last-child {
		float : right;
	  }
	}
  }

  .navbar-inverse & {
	.dropdown-menu {
	  width      : auto;
	  background : none;
	  padding    : 0;
	  left       : 0;
	  right      : 0;
	  margin     : 0;
	}
  }

  a {
	float           : left;
	text-decoration : none;
	position        : relative;
	height          : 24px;
	margin-left     : 17px;
	width           : 16px;
	&:before {
	  .tk-icon-font;
	  color     : #ffffff;
	  font-size : 1.5em;
	}
	&.ico-contacts {
	  &:before {
		content : '\e82e';
	  }
	}
	&.ico-search {
	  &:before {
		content : '\e831';
	  }
	}
	&.collapsed {
	  &:before {
		color : @orange;
	  }
	}
  }

  .navbar-toggle {
	display          : block;
	border-radius    : 0;
	box-shadow       : none;
	height           : 20px;
	width            : 24px;
	padding          : 0;
	margin           : 0;
	background-color : transparent !important;
	border           : 0;
	&:before {
	  .tk-icon-font;
	  color     : @purple;
	  content   : '\e82b';
	  font-size : 1.5em;
	}
	&.collapsing,
	&.collapsed {
	  background-position : 0 0;
	  &:before {
		color : #ffffff;
	  }
          &:hover {
              &:before {
                  color : #F05323;
              }
          }
	}
  }

}
.navbar-inverse {
  height : 70px;
  .navbar-nav {
	width : 65%;
	float : none;
  }
}
// Override Bootstrap navbar-collapse.collapse styles as it is displayed by default.
.navbar-inverse .navbar-collapse.collapsing,
.navbar-inverse .navbar-collapse.collapse.in {
  display : block !important;
  overflow-y: auto !important;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-collapse.collapse {
  background : #532A3A;
  box-shadow : none;
  border     : 0;
  clear      : both;
  display    : none !important;
  float      : left !important;
  height     : auto;
  margin     : 0;
  min-height : 820px;
  overflow   : hidden;
  position   : absolute;
  top        : 0;
  z-index: 2;
  width      : 100% !important;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-collapse.collapse.in{
    background : #472230;
}

.navbar-nav > li > .dropdown-menu {
  position : static;
  float    : none;
  overflow : hidden;
  clear    : both;
  width    : auto;
}

.navbar-inverse {
  min-height : 70px;
  height     : auto;
  margin-bottom: 0;
  .navbar-nav {
	padding    : 10px 0;
	background : #472230;
  }
  .navbar-nav > li,
  .navbar-nav > li > a {
	float       : none;
	font-size   : 32px;
	background  : #472230;
	padding     : 0;
        font-family: "NexaBold";
	line-height : 38px;
	width       : auto;
	display     : block;
        letter-spacing: 1px;
        font-weight: 600;
  }
  .navbar-nav > li > a {
        color: #F05323;
        text-transform: capitalize;
  }
  .navbar-nav > li {
        margin-left: 140px;
        padding-bottom: 22px;
	&.open {
	  a {
		&:before {
		  display : none;
		}
	  }
	}
  }
}

.navbar-collapse {
    margin-bottom: 105px;
    .burger-close {
        margin: 32px 38px 72px;
        i {
            color: #ffffff;
            font-size: 19px;
            &:hover {
                color: #f05323;
            }
        }
    }
    h3 {
        color: #ffffff;
        font-size: 16px;
        line-height: 19px;
        margin-top: 0;
        font-family: "NexaBold";
        margin-bottom: 25px;
    }
    .contact-links {
        p {
            margin-bottom: 0;
            line-height: 15px;
        }
    }
    p {
        color: #F05323;
        font-family: "NexaBold";
        &.get-phone {
            font-size: 20px;
            line-height: 24px;
            margin-top: 25px;
        }
    }
    .contact-social {
        ul {
            padding: 0;
            border-top: none;
            margin: 0;
            li {
                float: left;
                line-height: 1.75em;
                list-style: none;
                margin-left: 19px;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    line-height: 0;
                    font-size: 18px;
                    transition: all .4s ease;
                    &:hover {
                        opacity: 0.5;
                        text-decoration: none;
                        transition: all .4s ease;
                    }
                }
            }
        }
    }
    .contact-button-link {
        margin-bottom: 78px;
    }
    .footer-button {
        height: 80px;
        width: 486px;
        background-color: #532A3A;
        margin: 10px 20px;
        padding: 23px 28px 19px;
        transition: all .4s ease;
        .button-title {
            opacity: 0.35;
            color: #FFFFFF;
            font-family: "NexaBold";
            font-size: 30px;
            line-height: 38px;
            width: 60%;
            float: left;
        }
        .button-link {
            color: #F05323;
            font-size: 14px;
            font-family: "NexaBold";
            line-height: 39px;
            text-align: right;
            text-transform: uppercase;
            padding-right: 50px;
        }
        &:hover {
            width: 526px;
            margin: 10px 0;
            background-color: #492432;
            transition: all .4s ease;
            .button-title {
                opacity: 1;
            }
        }
    }
}
.navbar-inverse .navbar-nav>li.open {
    transition: all .4s ease;
}
.navbar-inverse .navbar-nav>li.faded:not(.open)>a {
    opacity: 0.35;
    transition: all .4s ease;
}
.mobile-links a.collapsed:before {
    color: #fff;
}
.mobile-links .ico-search {
    display: block;
    margin-right: 25px;
    margin-left: 1px;
    &:before {
        color: #ffffff;
    }
}
.top-search-wrapper {
    position: absolute;
    width: 100%;
    z-index: 999;
    background: #472230;
    height: 321px;
    padding: 30px;
    .burger-close {
        i {
            color: #fff;
            font-size: 19px;
            margin-left: 8px;
            &:hover {
                color: #f05323;
            }
        }
    }
    .search {
        padding-top: 51px;
        margin: 0 auto;
        width: 58%;
        max-width: 747px;
    }
    form {
        padding: 0 !important;
    }
    .search-button-type {
        text-align: center;
        label {
            width: 107px;
            height: 35px;
            color: #ffffff;
            background-color: #532A3A;
            font-family: "nexa";
            text-transform: uppercase;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            margin-bottom: 0;
            margin-right: -3px;
            top: 5px;
            opacity: 0.3;
            position: relative;
            transition: all .4s ease;
            &.checked {
                background-color: #F05323;
                height: 40px;
                line-height: 40px;
                top: 0px;
                opacity: 1;
                transition: all .4s ease;
            }
        }
        label.crf:hover {
            height: 40px;
            line-height: 40px;
            top: 0px;
            opacity: 1;
            transition: all .4s ease;
        }
    }
    .search {
	.links {
	    .btn-primary {
		font-size: 14px;
                text-transform: uppercase;
	    }
	}
	.wrapper {
	    margin-bottom: 10px;
	}
    }
    .form-group {
        float: left;
        width: 268px;
        height: 70px;
        background-color: #fff;
        margin-bottom: 0;
        &.first {
            width: 203px;
            padding-left: 14px;
            &:before {
                content: " ";
                box-sizing: border-box;
                height: 45px;
                width: 3px;
                border-left: 1px solid #532A3A;
                opacity: 0.2;
                position: absolute;
                z-index: 9;
                left: 0;
                top: 13px;
            }
        }
        &.last {
            width: 205px;
            padding-left: 14px;
            &:before {
                content: " ";
                box-sizing: border-box;
                height: 45px;
                width: 3px;
                border-left: 1px solid #532A3A;
                opacity: 0.2;
                position: absolute;
                z-index: 9;
                left: 0;
                top: 13px;
            }
        }
        &.buttons {
            width: 70px;
            height: 70px;
            padding-top: 0!important;
            .btn {
                background: #F05323;
                height: 70px;
                width: 100%;
                border: none;
            }
            .ico-search {
                display: block;
                margin-right: 0;
            }
        }
        .form-control {
            height: 70px;
	    font-size: 16px;
	    color: #9B9B9B;
	    font-weight: 300;
        }
        .crf-s {
            height: 70px;
            line-height: 73px;
            margin-bottom: 0;
            padding: 0 0 0 25px;
            font-size: 16px;
            color: #9B9B9B;
            font-weight: 300;
            &:before {
                top: 26px;
                left: -31px;
	        transform: rotate(270deg);
            }
            &:after {
                top: -2px;
                left: 0;
            }
	    &:before{
                display: none;
		height: 0;
		width: 0;
		border: none;
		font-style: normal;
		font-family: tk-icon-font!important;
		content: '\e832';
	    }
            &:after {
                height: 6.04px;
                width: 11.09px;
                border: none;
                font-style: normal;
                font-family: tk-icon-font!important;
                content: '\e832';
            }

        }
        .ico-search {
          &:before {
              content : '\e831';
              font-family: tk-icon-font!important;
              font-style: normal;
              font-weight: 400;
              speak: none;
              display: inline-block;
              text-decoration: inherit;
              width: 1em;
              margin-right: .2em;
              text-align: center;
              font-variant: normal;
              text-transform: none;
              line-height: 2em;
              margin-left: .2em;
              color: #FFFFFF;
              font-size: 1.3em;
          }
        }
    }
}
/* actual dropdown animation */
.dropdown .dropdown-menu {
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
    padding: 0;
}

.dropdown.open .dropdown-menu {
    max-height: 250px;
    opacity: 1;
    padding: 17px 0 7px;
}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
    color: #f05323 !important;
    background-color: transparent !important;
}
