@font-face {
  font-family : 'AvenirLTStd';
  src         : url("../fonts/AvenirLTStd-Heavy.eot");
  src         : url("../fonts/AvenirLTStd-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Heavy.woff") format("woff"), url("../fonts/AvenirLTStd-Heavy.ttf") format("truetype"), url("../fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy") format("svg");
  font-weight : bold;
  font-style  : normal;
}

@font-face {
  font-family : 'AvenirLTStd';
  src         : url("../fonts/AvenirLTStd-Light.eot");
  src         : url("../fonts/AvenirLTStd-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Light.woff") format("woff"), url("../fonts/AvenirLTStd-Light.ttf") format("truetype"), url("../fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light") format("svg");
  font-weight : 200;
  font-style  : normal;
}

@font-face {
  font-family : 'AvenirLTStd';
  src         : url("../fonts/AvenirLTStd-Roman.eot");
  src         : url("../fonts/AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Roman.woff") format("woff"), url("../fonts/AvenirLTStd-Roman.ttf") format("truetype"), url("../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight : normal;
  font-style  : normal;
}

@font-face {
  font-family : 'AvenirLTStd';
  src         : url("../fonts/AvenirLTStd-Book.eot");
  src         : url("../fonts/AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Book.woff") format("woff"), url("../fonts/AvenirLTStd-Book.ttf") format("truetype"), url("../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg");
  font-weight : 300;
  font-style  : normal;
}

@font-face {
  font-family : 'AvenirLTStd';
  src         : url("../fonts/AvenirLTStd-Medium.eot");
  src         : url("../fonts/AvenirLTStd-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Medium.woff") format("woff"), url("../fonts/AvenirLTStd-Medium.ttf") format("truetype"), url("../fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium") format("svg");
  font-weight : 500;
  font-style  : normal;
}

@font-face {
  font-family: 'tk-icon-font';
  src: url('../fonts/tk-icon-font.eot?7285974');
  src: url('../fonts/tk-icon-font.eot?7285974#iefix') format('embedded-opentype'),
       url('../fonts/tk-icon-font.woff?7285974') format('woff'),
       url('../fonts/tk-icon-font.ttf?7285974') format('truetype'),
       url('../fonts/tk-icon-font.svg?7285974#tk-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family : 'nexa';
  src         : url("../fonts/nexa-book.eot");
  src         : url("../fonts/nexa-book.eot?#iefix") format("embedded-opentype"), url("../fonts/nexa-book.woff") format("woff"), url("../fonts/nexa-book.ttf") format("truetype"), url("../fonts/nexa-book.svg#nexa-book") format("svg");
  font-weight : normal;
  font-style  : normal;
}

@font-face {
  font-family : 'NexaBold';
  src         : url("../fonts/NexaBold.eot");
  src         : url("../fonts/NexaBold.eot?#iefix") format("embedded-opentype"), url("../fonts/NexaBold.woff") format("woff"), url("../fonts/NexaBold.ttf") format("truetype"), url("../fonts/nexa-book.svg#NexaBold") format("svg");
  font-weight : normal;
  font-style  : normal;
}



/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'tk-icon-font';
    src: url('../fonts/tk-icon-font.svg?7285974#tk-icon-font') format('svg');
  }
}
*/

.tk-icon-font,
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family     : "tk-icon-font" !important;
  font-style      : normal;
  font-weight     : normal;
  speak           : none;
  display         : inline-block;
  text-decoration : inherit;
  width           : 1em;
  margin-right    : .2em;
  text-align      : center;
  font-variant    : normal;
  text-transform  : none;
  line-height     : 1em;
  margin-left     : .2em;
}

.icon-tk-lrg_arrow_down:before { content: '\e800'; } /* '' */
.icon-tk-lrg_arrow_left:before { content: '\e801'; } /* '' */
.icon-tk-lrg_arrow_right:before { content: '\e802'; } /* '' */
.icon-tk-lrg_banner-01:before { content: '\e803'; } /* '' */
.icon-tk-lrg_banner-02:before { content: '\e804'; } /* '' */
.icon-tk-lrg_bathroom:before { content: '\e805'; } /* '' */
.icon-tk-lrg_bedroom:before { content: '\e806'; } /* '' */
.icon-tk-lrg_burger:before { content: '\e807'; } /* '' */
.icon-tk-lrg_close:before { content: '\e808'; } /* '' */
.icon-tk-lrg_map:before { content: '\e809'; } /* '' */
.icon-tk-lrg_mobile:before { content: '\e80a'; } /* '' */
.icon-tk-lrg_dining_room:before { content: '\e80b'; } /* '' */
.icon-tk-lrg_search:before { content: '\e80c'; } /* '' */
.icon-tk-lrg_tick:before { content: '\e80d'; } /* '' */
.icon-tk-lrg_tk_logo:before { content: '\e80e'; } /* '' */
.icon-tk-lrg_twitter:before { content: '\e80f'; } /* '' */
.icon-tk-lrg_video:before { content: '\e810'; } /* '' */
.icon-tk-lrg_write:before { content: '\e811'; } /* '' */
.icon-tk-lrg_epc:before { content: '\e812'; } /* '' */
.icon-tk-lrg_facebook:before { content: '\e813'; } /* '' */
.icon-tk-lrg_floorplan-01:before { content: '\e814'; } /* '' */
.icon-tk-lrg_floorplan-02:before { content: '\e815'; } /* '' */
.icon-tk-lrg_full_screen:before { content: '\e816'; } /* '' */
.icon-tk-lrg_google:before { content: '\e817'; } /* '' */
.icon-tk-lrg_grid:before { content: '\e818'; } /* '' */
.icon-tk-lrg_heart:before { content: '\e819'; } /* '' */
.icon-tk-lrg_home:before { content: '\e81a'; } /* '' */
.icon-tk-lrg_kitchen:before { content: '\e81b'; } /* '' */
.icon-tk-lrg_list:before { content: '\e81c'; } /* '' */
.icon-tk-lrg_share:before { content: '\e81d'; } /* '' */
.icon-tk-lrg_reception:before { content: '\e81e'; } /* '' */
.icon-tk-lrg_arrow_up:before { content: '\e81f'; } /* '' */
.icon-tk-lrg_currency_eur:before { content: '\e820'; } /* '' */
.icon-tk-lrg_currency_gbp:before { content: '\e821'; } /* '' */
.icon-tk-lrg_currency_usd:before { content: '\e822'; } /* '' */
.icon-tk-lrg_geo_locator:before { content: '\e823'; } /* '' */
.icon-tk-lrg_alt_laptop:before { content: '\e824'; } /* '' */
.icon-tk-lrg_alt_mobile:before { content: '\e825'; } /* '' */
.icon-tk-lrg_alt_tablet:before { content: '\e826'; } /* '' */
.icon-tk-lrg_mobile-phone:before { content: '\e827'; } /* '' */
.icon-tk-lrg_envelope:before { content: '\e828'; } /* '' */
.icon-tk-lrg-brochure:before { content: '\e829'; }
.icon-tk-lrg-globe:before { content: '\e82a'; }
.new-instagram:before { 
    content: '\e830'; 
    font-family: tk-icon-font!important;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0;
}
.new-twitter:before {
    content: '\e82d';
    font-family: tk-icon-font!important;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0;
}
.new-linkedin:before {
    content: '\e82c';
    font-family: tk-icon-font!important;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0;
}
.new-facebook:before {
    content: '\e82f';
    font-family: tk-icon-font!important;
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0;
}


// FontAwesome
.navbar-inverse .navbar-toggle {
  padding      : 9px 7px;
  margin-right : 0px;
  border       : none;
  &:hover {
	background-color : transparent;
  }
}

.menu {
  i.fa {
	font-size : 25px;
	color     : @orange;
  }
}

.search {
  i.fa {
	font-size : 25px;
	color     : @orange;
  }
}

.mobile {
  i.fa {
	font-size : 25px;
	color     : @orange;
  }
}

footer#footer {
  .social.icons {
	i.fa {
	  margin : 5px;
	}
  }
}
