.home-slider .owl-dots {
    left: 66.66666667%;
    margin-left: 38px;
}
.featured-wrapper .owl-dots {
    left: 66.66666667%;
    margin-left: 38px;
}
.featured-wrapper .owl-dots {
    margin-left: -166px;
    left: 33.33333333%;
}
.home-people-intro .person-left .person-image {
    right: -16.5%;
}
.navbar-collapse .contact-social {
    margin-left: -116px;
}
