.featured-line {
  h3 {
	font-size   : @pbig2;
	font-weight : normal;
	text-align  : center;
	color       : @white;
	margin      : 0;
	padding     : 0 0 15px;
  }
}

/*
 * Home page slider
 */
.home .featured-line {
  margin-top      : 70px;
  background-size : cover;
  min-height      : 729px;
  @media @tablet-xl {
	margin-top : 60px;
  }
  .links {
	overflow   : hidden;
	background : @orange90;
	margin-top : 10px;
        width: 220px;
        height: 40px;
        margin: 0 auto;
        opacity: 0.7;
        transition: all .4s ease;
        &:hover {
            width: 260px;
            opacity: 1;
            transition: all .4s ease;
            .btn-primary {
                font-weight: 600;
            }
        }
	.btn {
	  display : block;
          background: none;
          border: none;
	}
	.btn + .btn {
	  margin-top : 10px;
	}
  }
  .buttons {
	overflow   : hidden;
	position   : relative;
	padding    : 10px 0;
	margin-top : 10px;
	.div {
	  position    : absolute;
	  left        : 50%;
	  width       : 1px;
	  margin-left : -1px;
	  background  : @orangelight;
	  top         : 0;
	  bottom      : 0;
	}
	.btn {
	  float : left;
	  width : 44%;
	  &:last-child {
		float : right;
	  }
	}
  }
  h2.tklogotitle.white strong {
     color: #fff !important;
  }
  h2 {
	color       : @white;
	float       : right;
	font-size   : 40px;
	line-height : 48px;
	margin      : 50px -25px;
	max-width   : 650px;
	padding     : 30px 30px;
	position    : relative;
	top         : 180px;
	right       : 445px;
	white-space : normal;
	.nexa;
	strong {
	  color       : @orange;
	  font-weight : normal;
	  display     : block;
	}
        strong.whitespan {
          color: #fff;
          display: block;
        }
	&.square {
	  right     : 370px;
	  top       : 75px;
	  max-width : 680px;
	}
        &.squareone{
	  top       : 65px;
	  max-width : 680px;
          strong{
            font-size:1.575em;
            margin-bottom:0.425em;
            display:block;
          }
          span{
            display:block;
            font-size:0.475em;
            margin-top:1.250em;
          }
        }
	&.squaretwo {
	  max-width : 680px;
	}
  }
}

@media screen and (min-width : 1001px) {
   .tklogotitle{
    right: 170px !important;
    font-size: 40px !important;
    line-height: 56px !important;
    top: 0px !important;
    .tklogonew {
       background-image: url("/images/homepageslider/tk20nlogo.png");
       background-repeat: no-repeat;
       height: 180px;
       float: left;
       width: 100%;
       margin-bottom: 15px;
    }
    .tklogo {
           float: left;
           width: 100%;
           font-size: 40px;
                   span:first-child {
                       padding-left: 0px;
                       }
                       span {
                           float: left;
                           padding: 0px 10px;
                           width: auto;
                           font-size: 40px;
                       }
       }
  }
  .home .featured-line {
	h2 {
	  right : 35px;
	  &.square {
		right : 35px;
	  }
	  &.squaretwo {
		right : 10px;
	  }
	}
	i {
	  &.icon-tk-lrg-brochure {
		right : 393px;
	  }
          &.icon-tk-lrg-globe {
                right : 393px;
          }
	  &.icon-tk-lrg_alt_laptop {
		right : 393px;
	  }
	  &.icon-tk-lrg_alt_tablet {
		right : 209px;
	  }
	  &.icon-tk-lrg_alt_mobile {
		right : 78px;
	  }
	}
  }
}

@media screen and (min-width : 1201px) {
   .tklogotitle{
    right: 428px !important;
    font-size: 40px !important;
    line-height: 56px !important;
    top: 0px !important;
    .tklogonew {
       background-image: url("/images/homepageslider/tk20nlogo.png");
       background-repeat: no-repeat;
       height: 180px;
       float: left;
       width: 100%;
       margin-bottom: 15px;
    }
    .tklogo {
           float: left;
           width: 100%;
           font-size: 40px;
                   span:first-child {
                       padding-left: 0px;
                       }
                       span {
                           float: left;
                           padding: 0px 10px;
                           width: auto;
                           font-size: 40px;
                       }
       }
  }
  .home .featured-line {
	h2 {
	  right : 250px;
	  &.square {
		right : 225px;
	  }
	  &.squaretwo {
		right : 226px;
	  }
	}
	i {
	  &.icon-tk-lrg-brochure {
		right : 610px;
	  }
          &.icon-tk-lrg-globe {
                right : 640px;
          }
	  &.icon-tk-lrg_alt_laptop {
		right : 610px;
	  }
	  &.icon-tk-lrg_alt_tablet {
		right : 426px;
	  }
	  &.icon-tk-lrg_alt_mobile {
		right : 295px;
	  }
	}
  }
}

@media screen and (min-width : 1401px) {
  .home .tklogotitle {
    right: 0;
  }
  .home .featured-line {
	h2 {
	  right : 235px;
	  &.square {
		right : 235px;
	  }
	  &.squaretwo {
		right : 205px;
	  }
	}
	i {
	  &.icon-tk-lrg-brochure {
		right : 593px;
	  }
          &.icon-tk-lrg-globe {
                right : 593px;
          }
	  &.icon-tk-lrg_alt_laptop {
		right : 593px;
	  }
	  &.icon-tk-lrg_alt_tablet {
		right : 409px;
	  }
	  &.icon-tk-lrg_alt_mobile {
		right : 278px;
	  }
	}
  }
}

@media screen and (min-width : 1600px) {
  .home .tklogotitle {
    right: 0;
  }
  .home .featured-line {
	h2 {
	  right : 445px;
	  &.square {
		right : 370px;
	  }
	  &.squaretwo {
		right : 413px;
	  }
	}
	i {
	  &.icon-tk-lrg-brochure {
		right : 803px;
	  }
          &.icon-tk-lrg-globe {
                right : 803px;
          }
	  &.icon-tk-lrg_alt_laptop {
		right : 803px;
	  }
	  // -184px
	  &.icon-tk-lrg_alt_tablet {
		right : 619px;
	  }
	  // -131px
	  &.icon-tk-lrg_alt_mobile {
		right : 488px;
	  }

	}
  }
}

@media @tablet-xl {
  .home .tklogotitle {
    right: 0;
  }
  .home .featured-line {
	.entry {
	  max-width : 100%;
	  margin    : 0 15px;
	}
	h2 {
	  margin    : 0;
	  max-width : 100%;
	  right     : auto;
	  top       : auto;
	  &.square {
		margin    : 0;
		max-width : 100%;
		right     : auto;
	  }
	  &.squaretwo {
                margin    : 0;
                max-width : 100%;
                right     : auto;
                width     : 100%;
          }
	}
	i {
	  display   : inline-block;
	  font-size : 8em;
	  position  : relative;
	  top       : 0px;
	  &:before {
		margin-left  : .1em;
		margin-right : .1em;
	  }
	  &.icon-tk-lrg-brochure {
		padding-left : 30px;
	  }
          &.icon-tk-lrg-globe {
                padding-left : 30px;
          }
	  &.icon-tk-lrg_alt_laptop {
		padding-left : 30px;
	  }
	  &.icon-tk-lrg_alt_tablet {
	  }
	  &.icon-tk-lrg_alt_mobile {
		right : 60px;
	  }
	}
  }
}

@media @mobile {
  .home .tklogotitle {
    right: 0;
  }
  .tklogotitle{
    right:0;
    font-size: 24px;
    line-height: 1.5em;
    top: 0;
    .tklogonew {
       background-image: url("/images/homepageslider/tk20nlogo-small.png");
       background-repeat: no-repeat;
       height: 80px;
       float: left;
       width: 100%;
       margin-bottom: 15px;
    }
    .tklogo {
            float: left;
            width: 100%;
            font-size: 40px;
                    span:first-child {
                        padding-left: 0px;
                        }
                        span {
                            float: left;
                            padding: 0px 10px;
                            width: auto;
                            font-size: 40px;
                        }
        }
  }

  .home .featured-line {
	h2 {
	  font-size   : @h2;
	  line-height : 1.5em;
	  margin      : 30px 20px;
	  max-width   : 100%;
	  padding     : 0 15px;
	  right       : 0;
	  top         : 0px;
	  &.square {
		line-height : 1.5em;
		margin      : 30px 10px;
		max-width   : 100%;
		padding     : 15px;
		right       : 0;
		top         : 0;
	  }
	  &.squaretwo {
		line-height : 1.5em;
		margin      : 30px 10px;
		max-width   : 100%;
		padding     : 15px;
		right       : 0;
		top         : 0;
	  }
	  strong {
		display : inline;
	  }
	}
	i {
	  position  : relative;
	  display   : inline-block;
	  top       : 10px;
	  font-size : 5em;
	  &.icon-tk-lrg-brochure {
		padding-left : 0;
		right        : auto;
	  }
          &.icon-tk-lrg-globe {
                padding-left : 0;
                right        : auto;
          }
	  &.icon-tk-lrg_alt_laptop {
		margin-left  : 20px;
		padding-left : 0;
		right        : auto;
	  }
	  &.icon-tk-lrg_alt_tablet {
		right : auto;
	  }
	  &.icon-tk-lrg_alt_mobile {
		right : 25px;
	  }
	}
  }
}

.home-slider-wrapper.loaded {
  position : absolute;
  z-index  : 0;
}

.home-slider-wrapper {
  .owl-carousel {
	max-height : 442px;
	overflow   : hidden;
        background-color: #532A3A;
        [class*="col-"]{
            padding : 0;
        }
        &:hover {
            background-color: #43202d;
        }
        .ribbon-image {
            width: 100%;
            position: absolute;
            top: -2px;
        }
        .ribbon {
            left: 100%;
            position: initial;
            float: right;
            margin-right: 18px;
            text-indent: -99999px;
        }
        img {
            height: 362px;
            width: 100%;
        }
        .text-wrapper {
            padding: 38px;
            height: 100%;
            text-align: center;
            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .propertytype {
            color: #F05323;
            text-align: left;
            font-family: "NexaBold";
            font-size: 14px;
            line-height: 30px;
        }
        h3 {
            color: #FFFFFF;
            font-family: "nexa";
            font-size: 32px;
	    text-align: left;
            padding: 0 0 8px;
            line-height: 28px;
        }
        .price{
            color: #F05323;
            font-family: "NexaBold";
            font-size: 32px;
            line-height: 41px;
	    text-align: left;
        }
        .price-text{
            color: #F05323;
            font-family: "NexaBold";
            font-size: 32px;
            line-height: 41px;
        }
        &:hover {
            .text-wrapper {
                background-color: #43202d;
            }
        }

  }
  .wrapper {
	background-color : rgba(255, 255, 255, 0.7);
	bottom           : 0;
	left             : 0;
	max-width        : 100% !important;
	position         : absolute;
	width            : 100%;
	width            : 100% !important;
	.container {
	  line-height : 80px;
	  max-width   : 905px;
	  text-align  : left !important;
	  position    : relative;

	  .content {
		display        : inline-block;
		font-size      : 18px;
		font-weight    : 700;
		margin         : 0 1.5em 0 119px;
		vertical-align : middle;
		p {
		  margin-bottom : 0;
		}
	  }
	  .address,
	  .address a {
		color : @purple;
	  }
	  .arrow-up {
		position    : absolute;
		top         : -15px;
		left        : 50%;
		margin-left : -15px;
	  }
	  .ribbon {
		display        : inline-block;
		left           : 16px;
		text-transform : uppercase;
		top            : -4px;
		position       : absolute;
	  }
	  .btn,
	  form,
	  .price {
		display        : inline-block;
		vertical-align : baseline;
	  }
	  .btn:first-of-type {
		position : relative;
		right    : 6px;
	  }
	  .price {
		color : @orange;
	  }
	}
  }
}

/*
 * End home page slider
 */

// idangerous swiper pagination
.gallery {
  .thumbs {
	&.smallThumbs {
	  margin-left  : 50px;
	  margin-right : 50px;
	  margin-top   : 2px;
	}
	.thumb {
	  max-width : 90px;
	  opacity   : 0.5;
	  &.active {
		opacity : 1
	  }
	}
  }
}

.ImageSliderControls {
  margin-top : -60px;
  a#property-details-swiper_next {
	float : right;
  }
}

.nav-text {
  display : none;
}

.slideshow.details {
  .slide {
	position : relative;
	.zoom {
	  bottom    : .5em;
	  color     : rgba(255, 255, 255, 0.8);
	  font-size : 1.75em;
	  left      : .5em;
	  position  : absolute;
	}
  }
}
.home-owl-slider {
    .owl-dots {
        position: absolute;
        bottom: 35px;
        left: 66.66666667%;
        margin-left: 40px;
        cursor: pointer;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            span {
                width: 8px;
                height: 8px;
                background: #7E606C;
                margin-right  : 5px;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                opacity: 1;
                border: none;
                border-radius: 0;
                cursor: pointer;
            }
            &:hover span {
                background: #fff; 
            }
            &.active span {
                background: #fff;
            }
        }
    }
}
.featured-wrapper:not(.featured-right) {
    .owl-dots {
        position: absolute;
        bottom: 40px;
        left: 66.66666667%;
        cursor: pointer;
        margin-left: 40px;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            span {
                display       : block;
                width         : 8px;
                height        : 8px;
                background    : #7E606C;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: 0;
                margin-right  : 5px;
                opacity       : 1;
                border: none;
                cursor        : pointer;
           }
            &:hover span {
                background: #fff;
            }
            &.active span {
                background: #fff;
            }
        }
    }
}
.featured-right {
   .owl-dots {
        position: absolute;
        bottom: 40px;
        right: 66.66666667%;
        margin-right: 36px;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            span {
                display       : block;
                width         : 8px;
                height        : 8px;
                background    : #7E606C;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: 0;
                margin-right  : 5px;
                opacity       : 1;
                border: none;
                cursor        : pointer;
           }
            &:hover span {
                background: #fff;
            }
            &.active span {
                background: #fff;
            }
        }
    }
}
