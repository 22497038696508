/** styling for share div
 * https://github.com/carrot/share-button
 */
.share-button {

  &.sharer-0 {
	-khtml-user-select    : none;
	-moz-user-select      : none;
	-ms-user-select       : none;
	-webkit-touch-callout : none;
	-webkit-user-select   : none;
	user-select           : none;
	height                : 40px !important;
	width                 : 100% !important;
	[class*=entypo-]:before {
	  .tk-icon-font;
	  color          : @orange;
	  font-size      : 24px;
	  line-height    : 26px;
	  vertical-align : top;
	}
	.entypo-export:before {
	  content     : '\e81d';
	  margin-left : 0;
	}
	.entypo-twitter:before {
	  content : '\e80f';
	}
	.entypo-facebook:before {
	  content : '\e813';
	}
	.entypo-gplus:before {
	  content : '\e817';
	}
	.entypo-paper-plane:before {
	  content : '\e828';
	}
	label {
	  font-size          : @pbig2;
	  font-weight        : 500;
	  border-radius      : 0;
	  line-height        : 38px;
	  border             : 1px solid @purple;
	  height             : 40px;
	  .btn-color() !important;
	  border-radius      : 0px !important;
	  color              : @white !important;
	  cursor             : pointer;
	  margin             : 0;
	  padding            : 5px 10px;
	  -webkit-transition : all .3s ease !important;
	  transition         : all .3s ease !important;
	  width              : 100%;
	  &:hover {
		.btn-color-hover() !important;
	  }
	  span {
		font-size              : @pbig2 !important;
		font-weight            : normal !important;
		font-family            : 'AvenirLTStd' !important;
		line-height            : 30px;
		padding-left           : 0 !important;
		text-transform         : none !important;
		-webkit-font-smoothing : antialiased;
		vertical-align         : top;
	  }
	}
	.social {
	  [class*=entypo-] {
		.share-btn() !important;
		&:hover {
		  .btn-color-hover() !important;
		}
	  }
	  &.networks-4.center {
		.share-btn();
		display     : inline-block;
		margin-left : 0 !important;
		width       : 100%;
	  }
	  ul {
		left  : 20% !important;
		width : 138px !important;
		li {
		  width : auto !important;
		}
		&:before {
		  .arrow-up;
		  border-bottom : 15px solid #42202d;
		  content       : '';
		  left          : 50%;
		  margin-left   : -15px;
		  position      : absolute;
		  top           : -15px;
		}
	  }

	}
  }
}
