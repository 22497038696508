#map_canvas {
  min-height : 800px;
}

.StarTekMapOverlay {
  background         : @white;
  border-bottom      : 2px solid @purple;
  -moz-box-shadow    : 3px 3px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow : 3px 3px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow         : 3px 3px 4px 0 rgba(0, 0, 0, 0.2);
  max-width          : 200px !important;
}

.StarTekMapOverlay {
  position : relative;
  & > img {
	display : none;
  }
  padding  : 0;
  .innerBox {
	min-height       : 75px;
	height           : auto !important;
	height           : 75px;
	background-color : @white;
	vertical-align   : top;
	div.image {
	  display : block;
	  img {
		width     : 100%;
		max-width : 100% !important;
	  }
	}
	div.text {
	  display : block;
	  padding : 0 0 15px 18px;
	}
  }
}