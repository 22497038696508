.error {
  background : @purpledark;
  color      : @white;
  .navbar-header {
	width  : 258px;
	float  : none;
	margin : 0 auto;
  }
  .main {
	position   : relative;
	top        : 190px;
	text-align : center;
	.square {
	  display : inline-block;
	  padding : 2em;
	  color   : @orange;
	}
  }
  .btn {
	position : relative;
	top      : 2em;
  }
}