body {
  background  : @white;
  font-size   : @p;
  line-height : @pbig2-lh;
  color       : @grey;
  .AvenirLTStd;
}

.navbar-fixed-top + .body,
.search-line + .body {
  margin-top : 70px;
  @media @tablet-xl {
	margin-top : 80px;
  }
}

.interior .featured-line + .body {
  margin-top : 130px;
  @media @tablet-xl {
	margin-top : 80px;
  }
}

a,
a:hover,
a:focus {
  color : @orange;
}

a,
button,
input {
  outline : 0 !important;
}

.container {
  max-width : 1105px;
  &.main {
	margin-bottom : 100px;
  }
}

.btn {
  font-size     : @pbig2;
  font-weight   : 500;
  border-radius : 0;
  line-height   : 40px;
  border        : 1px solid @purple;
  height        : 40px;
  &.btn-sm {
	height      : 30px;
	line-height : 30px;
	font-size   : @p;
  }
  &.btn-primary {
	padding : 0 16px;
	color   : @white;
	.btn-color;
	&:hover {
	  border-color : @purpledark;
	  .btn-color-hover;
	}
  }
  &.btn-danger {
	padding : 0 16px;
	color   : @white;
	.btn-color-l;
	&:hover {
	  border-color : @purpledark;
	  .btn-color-hover;
	}
	img {
	  display  : inline-block;
	  margin   : 0 7px 0 -2px;
	  position : relative;
	  top      : -1px;
	}
  }
}

.form-control {
  margin        : 0;
  padding       : 0 12px;
  background    : @white;
  color         : @greymiddle;
  display       : block;
  border-radius : 0;
  line-height   : normal;
  width         : 100%;
  outline       : none;
  box-shadow    : none;
  height        : 30px;
  font-size     : @p;
  margin-bottom : 10px;
  border        : 0;
  position      : relative;
  &:focus {
	box-shadow : inset 0 0 0 1px #f2a38b;
  }
  &::-webkit-input-placeholder {
	color : @greymiddle;
  }
  &::-moz-placeholder {
	color   : @greymiddle;
	opacity : 1;
  }
}

textarea.form-control {
  padding-top : 7px;
  height      : 30px;
}

.has-error .form-control {
  box-shadow : inset 0 0 0 1px #00e6e6 !important;
  background : #ccfafa;
  color      : #6f8a8a;
  &::-webkit-input-placeholder {
	color : #6f8a8a;
  }
  &::-moz-placeholder {
	color   : #6f8a8a;
	opacity : 1;
  }
}

.crf-s {
  padding       : 0 12px;
  background    : @white;
  color         : @greymiddle;
  display       : block;
  cursor        : pointer;
  font-size     : @p;
  margin-bottom : 10px;
  text-align    : left;
  line-height   : 30px;
  position      : relative;
  &:before {
	position     : absolute;
	top          : 9px;
	right        : 12px;
	width        : 0;
	height       : 0;
	border-style : solid;
	border-width : 0 5px 5px 5px;
	border-color : transparent transparent @greymiddle transparent;
	content      : "";
  }
  &:after {
	position     : absolute;
	top          : 16px;
	right        : 12px;
	width        : 0;
	height       : 0;
	border-style : solid;
	border-width : 5px 5px 0 5px;
	border-color : @greymiddle transparent transparent transparent;
	content      : "";
  }
}

.crf-sm {
  position         : absolute;
  display          : none;
  overflow         : auto;
  max-height       : 500px;
  z-index          : 99999;
  border           : 1px solid @greylight;
  border-bottom    : 0;
  background       : @white;
  color            : @greymiddle;
  cursor           : pointer;
  font-size        : @p;
  text-align       : left;
  line-height      : 30px;
  background-color : @white;
  ul,
  li {
	list-style : none;
	padding    : 0;
	margin     : 0;
  }
  li {
	cursor        : pointer;
	padding       : 0 12px;
	border-bottom : 1px solid @greylight;
	&:hover {
	  background : @greylight;
	}
	&.label {
	  display : none;
	}
  }
}

.breadcrumb {
  color      : @purpledark;
  font-size  : 13px;
  background : none;
  padding    : 34px 0 14px;
  margin     : 0;
  .active {
	color : @purpledark;
  }
  li + li:before {
	content : ">";
        position: relative;
        top: -1px;
  }
  a {
	color : @greymiddle;
  }
}

/*
h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
*/

.sidebar {
  position : relative;
  top      : 60px;
  &.inset {
	/*
	margin-right  : 4px;
	margin-left   : -16px;
	*/
	padding-right : 0;
	top           : @p-lh;
  }
  .headline h4 {
	border-bottom  : 1px solid @greymiddle;
	font-size      : 1.25*@p;
	padding-bottom : 1em;
  }
  ul {
	li {
	  line-height   : 1em;
	  margin-bottom : .75em;
	  &.active {
		a {
		  color : @orange;
		}
	  }
	  a {
		color     : @grey;
		font-size : @p;
		&:hover {
		  color           : @orange;
		  text-decoration : none;
		}
	  }
	}
  }
  .module.article {
	margin : 1em 0 2em 0;
  }

}

.startek-details {
  .sidebar {
	top : 0;
  }
}

.orange-titles {
  [itemprop="name"] {
	color : @orange
  }
}

// JUI override
.page-header {
  margin-top     : 0;
  padding-bottom : 0;
  border-bottom  : 0;
  margin-bottom  : 0;
}

.social {
  ul, li {
	list-style : none;
	padding    : 0;
	margin     : 0;
	float      : left;
  }
  li a {
	float       : left;
	font-size   : 1.5 * @p;
	height      : 24px;
	margin-left : 5px;
	opacity     : .6;
	width       : 24px;

	&:hover {
	  opacity : 1;
	}
  }
  p {
	float   : left;
	padding : 0 2px 0 22px;
	.phone {
	  font-weight : bold;
	  line-height : 14px;
	  font-size   : @pbig2;
	}
  }
}

.underlined {
  border-bottom  : 1px solid @greymedium;
  padding-bottom : 30px;
  margin-bottom  : 35px;
}

.blog.underlined,
.item-page.underlined {
  border-bottom  : none;
  padding-bottom : 0;
  margin-bottom  : 0;
  .page-header {
	h1 {
	  .underlined;
	}
  }
}
.calc-price{
    width: 77% !important;
    float: left !important;
    margin-left: 6px !important;
}

.banner25-logo {
  margin-right: -58%;
  margin-top:-10%;
  img {
    width: 45%;
  }  
}
@media @tablet {
  .banner25-logo {
    margin-right: -22%;
    margin-top: -26%;
    img {
      width: 25%;
    }
  }
}
@media only screen and (max-width: 767px) {
  .banner25-logo {
    margin-right: 0;
    margin-top: -57%;
    img {
      width: 15%;
    }
  }
}
