.map-wrapper {
  border     : @map-border;
  min-height : 500px;
  width      : 100%;
}

#map_canvas {
  border : @map-border;
}

#lettings-map,
#sales-map {
  min-height : 250px;
}

.adp-placemark {
  td:first-of-type {
	display      : block;
	width        : 30px;
	padding-left : 5px;
  }
}

// Google map fix
.gm-style img {
  max-width : none;
}

.gm-style label {
  width   : auto;
  display : inline;
}