// Featured properties sliders
.ImageSliderOuter {
  &.featured {
	overflow : hidden;
	padding  : 27px 0 19px;
	.slider {
	  overflow : hidden;
	  width    : 20000px;
	}
	.slide {
	  background     : @greylight;
	  float          : left;
	  border-right   : 14px solid #fff;
	  margin-top     : 4px;
	  height         : 362px;
	  padding-left   : 20px;
	  padding-bottom : 31px;
	  position       : relative;
	  &:hover {
		background : @greylesslight;
	  }
	  &.swiper-slide-visible {
		&.slide-2 {
		  right : -5px;
		}
		&.slide-3 {
		  right : -9px;
		}
		&.slide-4 {
		  right : -14px;
		}
	  }
	}
	.ImageSliderPrevNext {
	  position        : absolute;
	  top             : 182px;
	  right           : -34px;
	  display         : block;
	  opacity         : .5;
	  text-decoration : none;
	  width           : 30px;
	  &:hover {
		opacity : 1;
	  }
	  i {
		font-size : 1.5em;
	  }
	}
	.ImageSliderPrev {
	  left : -34px;
	}

	h2 {
	  font-size     : 30px;
	  color         : @purpledark;
	  margin        : 0 0 16px;
	  padding       : 0 0 13px;
	  .nexa;
	  border-bottom : 1px solid #a18f96;
	  span {
		color : @orange;
	  }
	}
	.ribbon {
	  left : 30px;
	}

	a {
	  &:hover {
		text-decoration : none;
	  }
	}
	h3 {
	  color       : @purpledark;
	  font-size   : @pbigger;
	  font-weight : bold;
	  line-height : 18px;
	  margin      : 0;
	  padding     : 10px 10px 0 0;
	  + h3 {
		padding-top : 0;
	  }
	}
	.price,
	.price-text {
	  font-size      : @pbigger;
	  line-height    : 18px;
	  margin         : 8px 0 0;
	  padding-bottom : 4px;
	  font-weight    : bold;
	  color          : @orange;
	  + .price {
		margin-top : 0;
	  }
	}
	// Needed for consistent vertical alignment of buttons when there is text of varied length
	.btn {
	  padding  : 0 15px;
	  position : absolute;
	  top      : 295px;
	}
  }
}

@media @desktop {
  .ImageSliderOuter {
	&.featured {
	  .btn {
		top : 322px;
	  }
	  .ImageSliderPrevNext {
		margin : 0 30px;
		top    : 199px;
	  }
	  .ImageSliderPrev {
		left : -41px;
	  }
	  .ImageSliderNext {
		right : -41px
	  }
          .inner-pagination {
              bottom: 7%;
              right: 9%;
          }
          .inner-left-pagination {
              bottom: 26%;
              left: 17%;
          }
	  .slide {
		height : 366px;
		&.swiper-slide-visible {
		  &.slide-2 {
			right : -7px;
		  }
		  &.slide-3 {
			right : -14px;
		  }
		}
	  }
	}
  }
}
@media @tablet {
  .ImageSliderOuter {
        &.featured {
          padding       : 0;
          margin-bottom : 46px;
          h2 {
                font-size      : @pbig2;
                padding-bottom : 11px;
          }
          .slide {
                padding-bottom : 31px;
                height         : 650px;
                width: 1024px !important;
                img {
                  width : 100%;
                }
                .price {
                  font-size   : @h3;
                  line-height : @pbig2-lh;
                }
                h3 {
                  font-size   : @h3;
                  line-height : @pbig2-lh;
                }
          }
          .ImageSliderControls {
                display : none;
          }
          .ImageSliderPrevNext {
                top : 38.25%;
          }
          .ImageSliderPrev {
                left  : 0;
                right : 0;
          }
          .ImageSliderNext {
                right : 0;
          }
          .btn {
                top : 442px;
          }
        }
  }
}

@media @tablet-xl {
  .featured-right {
        .text-wrapper {
            float: left;
            width: 100%;
        }
  }
  .ImageSliderOuter {
	&.featured {
	  padding       : 0;
	  margin-bottom : 46px;
	  h2 {
		font-size      : @pbig2;
		padding-bottom : 11px;
	  }
	  .slide {
		padding-bottom : 31px;
		height         : 650px;
                .ribbon-image {
                    width: 100%;
                    position: absolute;
                    top: -4px;
                }
                .ribbon {
                    left: 100% !important;
                    position: initial;
                    float: right;
                    margin-right: 18px;
                }
		img {
		  width : 100%;
		}
		.price {
		  font-size   : @h3;
		  line-height : @pbig2-lh;
		}
		h3 {
		  font-size   : @h3;
		  line-height : @pbig2-lh;
		}
		&.swiper-slide-visible {
		  &.slide-2 {
			right : -14px;
		  }
		}
	  }
	  .ImageSliderControls {
		display : none;
	  }
	  .ImageSliderPrevNext {
		top : 38.25%;
	  }
	  .ImageSliderPrev {
		left  : 0;
		right : 0;
	  }
	  .ImageSliderNext {
		right : 0;
	  }
	  .btn {
		top : 442px;
	  }
	}
  }
}

@media @mobile {

  .ImageSliderOuter {
	&.featured {
	  padding : 0;
	  width   : 100%;
	  margin  : 0 auto 46px auto;
	  h2 {
		font-size      : @pbig2;
		padding-bottom : 11px;
	  }
	  .slide {
		// width          : 260px !important;
		padding-bottom : 30px;
		height         : 423px;
                .ribbon-image {
                    width: 100%;
                    position: absolute;
                    top: -4px;
                }
                .ribbon {
                    left: 100% !important;
                    position: initial;
                    float: right;
                    margin-right: 18px;
                }
                img {
                    height: auto !important;
                }
                .image-wrapper {
                    float: none;
                }
                .text-wrapper {
                    h3 {
                        font-size: 26px !important;
                        line-height: 32px !important;
                    }
                    .price {
                        font-size: 26px !important;
                        line-height: 32px !important;
                    }
                }
		.price {
		  font-size      : @pbigger;
		  line-height    : @pbig2-lh;
		  padding-bottom : 2px;
		}
		h3 {
		  font-size   : @pbigger;
		  line-height : 18px;
		}
	  }
	  .btn {
		top : 294px;
	  }
	  .ImageSliderPrevNext {
		top : 150.5px;
	  }
	}
  }
}

/* Smartphones (portrait) - Includes iPhone 6 Plus */
@media only screen and (max-width : 414px) {
  .ImageSliderOuter {
	&.featured {
	  .ImageSliderPrevNext {
		top : 178px;
	  }
	}
  }
}
