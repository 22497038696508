.page-header {
  position : relative;
}
.list-wrapper {
    .image {
        margin-right: -5px !important;
        padding-right: 0;
    }
}
.newbuild-wrapper {
    position: absolute;
    width: 100%;
    height: 28px;
    left: 0px;
    top: 0px;
    background: rgba(83, 42, 58,0.8);
    mix-blend-mode: normal;
    &:after {
        content: '';
        background-image: url(../../../images/new-home.svg);
	width: 54px;
	height: 23px;
	position: absolute;
	right: 10px;
	top: 3px;
	background-repeat: no-repeat;
    }
    @media (max-width: 767px) {
        height: 35px;
	&:after {
            width: 70px;
            height: 30px;
	}
    }
}

.startek-results .ribbon {
  color          : @white;
  font-weight    : bold;
  left           : 10px;
  line-height    : 1em;
  padding-top    : 1em;
  position       : absolute;
  text-align     : center;
  text-transform : uppercase;
  top            : -4px;
  width          : 50px;
  min-height     : 60px;
  white-space    : normal;
  z-index        : 99;
  &:before {
        .flag-down(@yellow, 25px);
        opacity     : .9;
        padding-top : 11px;
        z-index     : -1;
  }
  &.for-sale {
          background-image: url(../../../images/for-sale.svg);
          text-indent: -9999px;
          min-height: 60px;
        &:before {
          content: none;
        }
  }
  &.to-let {
          background-image: url(../../../images/to_let.svg);
          text-indent: -9999px;
          min-height: 60px;
        &:before {
          content: none;
        }
  }
  &.sold {
          background-image: url(../../../images/sold.svg);
          text-indent: -9999px;
          min-height: 60px;
        &:before {
          content: none;
        }
  }
  &.reserved {
    font-size:10px;
    line-height:1.3em;
  }
  &.under {
          background-image: url(../../../images/under_offer.svg);
          text-indent: -9999px;
          min-height: 60px;
        &:before {
          content: none;
        }
  }
  &.let {
        background-image: url(../../../images/let.svg);
        text-indent: -9999px;
        min-height: 60px;
      &:before {
        content: none;
      }
  }
}
.ribbon {
  color          : @white;
  font-weight    : bold;
  left           : 12px;
  line-height    : 1em;
  padding-top    : 1em;
  position       : absolute;
  text-align     : center;
  text-transform : uppercase;
  top            : -4px;
  width          : 75px;
  white-space    : normal;
  z-index        : 99;
  &:before {
	.flag-down(@yellow, 30px);
	opacity     : .9;
	padding-top : 11px;
	z-index     : -1;
  }
  &.for-sale {
	  background-image: url(../../../images/for-sale.svg);
	  text-indent: -9999px;
	  min-height: 90px;
	&:before {
	  content: none;
	}
  }
  &.to-let {
	  background-image: url(../../../images/to_let.svg);
	  text-indent: -9999px;
	  min-height: 90px;
	&:before {
	  content: none;
	}
  }
  &.sold {
          background-image: url(../../../images/sold.svg);
          text-indent: -9999px;
          min-height: 90px;
        &:before {
          content: none;
        }
  }
  &.reserved {
    font-size:10px;
    line-height:1.3em;
  }
  &.under {
          background-image: url(../../../images/under_offer.svg);
          text-indent: -9999px;
          min-height: 90px;
        &:before {
          content: none;
        }
  }
  &.let {
    background-image: url(../../../images/let.svg);
    text-indent: -9999px;
    min-height: 90px;
  &:before {
    content: none;
  }
  }
  &.horizontal {
	background-color : @orange;
	bottom           : 1em;
	display          : inline-block;
	font-weight      : normal;
	left             : -0.1em;
	line-height      : 2em;
	padding          : 0 0 0 .6em;
	top              : auto;
	text-align       : inherit;
	text-transform   : none;
	width            : auto;
	&.inline {
	  position : relative;
	  top      : auto;
	  bottom   : auto;
	}
	&:before {
	  .flag-right(@orange, 32px);
	  padding-top : 0;
	  opacity     : 1;
	}
	&.grey {
	  background-color : @greymedium90;
	  &:before {
		.flag-right(@greymedium90, 32px);
	  }
	  @media @tablet-xl {
		&:before {
		  .flag-right(@greymedium, 20px);
		  right : -40px;
		}
	  }
	}
	@media @tablet-xl {
	  &:before {
		.flag-right(@orange, 20px)
	  }
	}
	a {
	  color : @white;
	}
	&.below {
	  position     : relative;
	  top          : .5em;
	  bottom       : auto;
	  float        : left;
	  padding-left : .1em
	}
  }
}

.team {
  .ribbon {
	&.horizontal {
	  background-color : @purpledark;
	  color            : @orange;
	  &:before {
		.flag-right(@purpledark, 32px);
	  }
	}
  }
}

h2 {
  &.ribbon {
	&.horizontal {
	  font-size : 32px;
	}
  }
}
@media (min-width: 998px) and (max-width: 1200px){
h2 {
  &.ribbon {
        &.horizontal {
          font-size : 24px;
	  &:before {
              border-width: 24px;
	      right: -48px;
	  }
        }
  }
}
.guides .horizontal.ribbon.below {
    margin: 15px 0 15px !important;
}
.blog.guides.property-guides .guide {
    min-height: 320px !important;
}
}
