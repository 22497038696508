h1, h2, h3 {
  .nexa;
  color         : @purpledark;
  line-height   : 1em;
  margin-bottom : 1em;
}

h1 {
  font-size : @h1;
}

h2 {
  font-size : @h2;
}

h3 {
  font-size : @h3;
  p {
	color  : @grey;
	margin : 0 0 4px;
  }
}

.page-header {
  h1 {
	margin-top : 0;
  }
}

p {
  font-size : @p;
}

blockquote {
  .nexa;
  border      : none;
  color       : @orange;
  display     : block;
  font-size   : @q;
  line-height : 1.25em;
  margin      : 2.5em 0;
  padding     : 0;
  position    : relative;
  &:before,
  &:after {
	position  : absolute;
	left      : 0;
	font-size : 3*@q;
	opacity   : .5;
  }
  &:before {
	top     : -.2em;
	content : open-quote;
  }
  &:after {
	bottom  : -.7em;
	content : close-quote;
  }
  p {
	font-size : @q;
  }
  &.small {
	font-size : @qsmall;
	margin    : 2.5em 0;
	&:before {
	  font-size : 4em;
	}
	&:after {
	  font-size : 4em;
	}
	p {
	  font-size : @qsmall;
	}
  }
}

a {
  &.back {
	display : block;
	margin  : 28px 0 40px 0;
	i {
	  &:before {
		margin-left : 0;
	  }
	}
  }
}

.home {
  .welcome {
	h2 {
          width: 630px;
	  font-size      : 44px;
	  color          : #532A3A;
          line-height: 56px;
          text-align: center;
	  margin         : 0 auto;
	  padding-bottom : 42px;
	  .nexa;
	}
	p {
            width: 806px;
            color: #4B4B4B;
            margin: 0 auto;
            font-size: 22px;
            font-weight: 300;
            line-height: 38px;
            text-align: center;
	}
  }
}

.search,
.search-wrapper {
  h3 {
	font-family : 'AvenirLTStd';
  }
}
