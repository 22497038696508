.mobile-links {
      margin: 0;
  .contacts {
	background : @purple !important;
	width      : 100% !important;
	padding    : 13px 0 !important;
	a {
	  margin-left : 0;
	  width       : auto;
	  &:before {
		color     : @orange;
		font-size : 1em;
	  }
	}

	a, p, .social, .social ul, .social li {
	  display : inline-block;
	  float   : none;
	}
	p {
	  display       : block;
	  line-height   : 24px;
	  margin-bottom : 0;
	  .phone {
		line-height : inherit;
	  }
	  + p {
		margin-top : 8px;
	  }
	}
	.social {
	  display    : block;
	  margin-top : 13px;
	  p,
	  ul {
		display        : inline-block;
		line-height    : 21px;
		vertical-align : middle;
	  }
	  li {
		a {
		  padding : 0;
		}
	  }
	  [class^="icon-"]:before,
	  [class*=" icon-"]:before {
		margin-left  : 0;
		margin-right : 0;
	  }
	}
  }

  .dropdown {
	float : left;
  }

  .search {
	overflow   : hidden;
	text-align : center;
	h3 {
	  font-size   : @pbig2;
	  font-weight : normal;
	  text-align  : center;
	  color       : @white;
	  margin      : 0;
	  padding     : 0 0 15px;
	}
	form {
	  padding : 15px;
	  margin  : 0;
	  .clearfix;
	}
  }

  .buttons {
	overflow   : hidden;
	position   : relative;
	padding    : 10px 0;
	margin-top : 10px;
	.div {
	  position    : absolute;
	  left        : 50%;
	  width       : 1px;
	  margin-left : -1px;
	  background  : @orangelight;
	  top         : 0;
	  bottom      : 0;
	}
	.btn {
	  float       : left;
	  height      : 40px;
	  .news {
		margin         : -5px 0;
		padding-bottom : 83px;
		h3 {
		  height          : 40px;
		  background-size : auto 100%;
		  font-size       : @pbig2;
		  line-height     : 40px;
		}
		a.btn {
		  margin-top : 7px;
		}
		.tweet {
		  padding       : 40px 0 0;
		  margin-bottom : 22px;
		  line-height   : 18px;
		}
		.item {
		  padding-bottom : 5px;
		  min-height     : 355px;
		}
	  }
	  .orange-boxes {
		padding : 49px 0;
		.col-md-6:last-child {
		  .item {
			margin-left  : -8px;
			margin-right : 0;
		  }
		}
		.item {
		  background   : @orange90;
		  text-align   : center;
		  font-size    : @pbig2;
		  margin-right : -8px;
		  padding      : 20px 0 25px;
		  min-height   : 222px;
		  line-height  : @pbig2-lh;
		  color        : @white;
		  &:hover {
			background : @orange;
		  }
		  h3 {
			color     : @purpledark;
			margin    : 0 0 15px;
			font-size : 30px;
			.nexa;
		  }
		  p {
			margin : 0 0 17px;
		  }
		  .btn {
			padding    : 0 40px;
			min-width  : 218px;
			text-align : center;
		  }
		}
	  }
	  line-height : 38px;
	  width       : 44%;
	  &:last-child {
		float : right;
	  }
	}
  }

  .navbar-inverse & {
	.dropdown-menu {
	  width      : auto;
	  background : none;
	  padding    : 0;
	  left       : 0;
	  right      : 0;
	  margin     : 0;
	}
  }

  a {
	float           : left;
	text-decoration : none;
	position        : relative;
	height          : 24px;
	margin-left     : 17px;
	width           : 16px;
	&:before {
	  .tk-icon-font;
	  color     : @purple;
	  font-size : 1.5em;
	}
	&.ico-contacts {
	  &:before {
		content : '\e82e';
	  }
	}
	&.ico-search {
	  &:before {
		content : '\e831';
	  }
	}
	&.collapsed {
	  &:before {
		color : #fff;
	  }
	}
  }

  .navbar-toggle {
	display          : block;
	border-radius    : 0;
	box-shadow       : none;
	height           : 24px;
	width            : 30px;
	padding          : 0;
	margin           : 0 20px 0 7px;
	background-color : transparent !important;
	border           : 0;
	&:before {
	  .tk-icon-font;
	  color     : @purple;
	  content   : '\e82b';
	  font-size : 1.5em;
	}
	&.collapsing,
	&.collapsed {
	  background-position : 0 0;
	  &:before {
		color : #fff;
	  }
	}
  }

}
.navbar-inverse .navbar-brand {
  margin : -6px 0 0 0 !important;
  width: 160px;
}

.orange-boxes .item {
  padding : 20px;
  .btn {
	min-width : inherit;
	width     : 100%;
	margin    : 0 auto;
	padding   : 0 20px;
  }
  p {
	font-size   : @p;
	line-height : 18px;
	margin      : 0;
	min-height  : 60px;
	padding     : 0 30px 14px;
  }
}

// Reposition last menu dropdown so that it doesn't disappear of right edge of screen
.navbar-nav {
  li:last-of-type {
	.dropdown-menu {
	  left  : auto;
	  right : 0;
	}
  }
}

.bx-wrapper {
  max-width : 812px !important;
  margin    : 0 auto !important;
}

.featured-line {
  overflow : hidden;
}

.properties {
  &.similar-row {
	.item {
	  width : 32%;
	}
  }
}

.sales-search.grid-style {
  section {
	text-align   : justify;
	margin-left  : 0;
	margin-right : 0;
  }
  article {
	margin-left  : 0;
	margin-right : 0;
	width        : 48.85%;
	position     : relative;
	min-height   : 428px;
  }
  .buttons {
	bottom        : 47px;
	margin-bottom : 0;
	position      : absolute;
  }
  h2 {
	min-height : 1.5em;
  }
}

// Property search bar select elements
.search-wrapper .crf-s {
  width : 150px;
}

// Cal TK office module
.sidebar {
  .contacts-item {
	strong {
	  font-size : 1.5em;
	}
  }
}
.navbar-header {
    padding: 27px 0 0 0;
}

.home-people-intro {
    .about-content {
        padding: 97px 20px 109px;
        p {
            font-size: 18px;
            line-height: 30px;
            padding: 0 10% 38px;
        }
        h3 {
            font-size: 26px;
            line-height: 32px;
            padding: 0 10% 38px;
        }
    }
    .person-left {
        float: left;
        z-index: 2;
        width: 50%;
        .person-text {
            position: absolute;
            top: 110px;
            left: 0;
            width: 100%;
            h2 {
                padding-right: 140px !important;
                padding-top: 38px;
                font-size: 26px;
                line-height: 28px;
                margin-bottom: 8px;
            }
            .team_member_title {
                line-height: 14px;
            }
            p {
                width: 73%;
            }
            h2, a {
                padding-right: 0;
                float: none;
            }
        }
        .person-image {
            float: right;
            right: -60px;
            img {
                height: 262px;
            }
        }
    }
    .person-right {
        .person-text {
            position: absolute;
            top: 110px;
            right: 0;
            width: 65%;
            h2 {
                padding-top: 38px;
                font-size: 26px;
                line-height: 28px;
            }
        }
        .person-image {
            left: -5px;
            img {
                height: 262px;
            }
        }
    }
    .person-year {
        z-index: 3;
        position: relative;
        height: 134px;
    }
}

.navbar-collapse {
    .footer-button {
        width: 420px;
        &:hover {
            width: 460px;
        }
        .button-link {
            padding-right: 10px;
        }
    }
}
.home-slider {
    .owl-dots {
        right: 9%;
    }
}
.instagram-wrapper {
    .diamond {
        background: url(../../../images/social-icon-background.png) no-repeat top center;
        background-size: cover;
        margin-top: 50px;
        margin-bottom: 90px;
        min-height: 1024px;
    }
    .photo-wrapper {
        width: 100%;
        margin-bottom: 135px;
        float: left;
	position: relative;
        left: 18px;
        .image-item {
            height: 230px;
            width: 230px;
            padding-left: 7px;
            padding-bottom: 7px;
            &:nth-child(even) {
                text-align: left;
            }
            &:nth-child(odd) {
                text-align: right;
            }
        }
    }
}
.home-banner-search .search-button-type label {
    margin-right: -4px;
}
.top-search-wrapper .search {
    width: 78%;
}
.footer-bottom-nav .container {
    width: 100%;
}
.footer-bottom-nav .contact-links .footer-button {
    &:hover {
        width: 486px;
    }
    .button-link {
        p {
            line-height: 40px;
        }
    }
}
.home-slider-wrapper .owl-carousel .ribbon {
    left: 85%;
}
.home-slider-wrapper .owl-dots {
    bottom: 54px;
}
.featured-wrapper:not(.featured-right) .owl-dots {
    bottom: 10%;
}
.featured-right .owl-dots {
    bottom: 5%;
}
.home-people-intro {
    .person-left {
        .person-text {
            a {
                padding-left: 20px;
            }
        }
    }
    .person-right {
        .person-text {
            a {
                padding-left: 20px;
            }
        }
    }
}
