.row-no-padding {
  [class*="col-"] {
	padding-left  : 0 !important;
	padding-right : 0 !important;
  }
}

.tablet-and-larger{
  display: block;
}
.ImageSliderOuter {
    &.featured {
        .slide {
            width: 768px !important;
        }
    }
}
.home .featured-line {
    margin-top: 60px;
}
.home-slider-wrapper {
    top: 398px;
    .home-owl-slider {
         .slide-item {
             .image-wrapper {
                 width: 60%;
                 float: left;
                 .ribbon {
                     left: 78%;
                 }
             }
             .text-wrapper {
                 width: 40%;
                 float: left;
                 padding: 15px;
             }
         }
    }
    .owl-dots {
        bottom: 27px;
        left: 60%;
        margin-left: 17px;
    }
}
.home-top-slider {
    .ImageSliderOuter.featured .slide{
        height: 170px;
        p {
            font-size: 20px;
            line-height: 26px;
            &.title-text {
                padding-top: 63px;
            }
        }
        &:before {
            height: 230px;
        }
    }
}
.featured-wrapper.featured-right .image-wrapper {
    float: none;
}

