img {
  max-width : 100%;
  height    : auto;
}

.content-main {
  padding    : 21px 15px 30px;
  margin     : 0;
  background : @greylight;

  @media @tablet-xl {
	padding : 20px 20px 30px;
  }
  @media @mobile {
	padding : 20px 15px;
  }
}

.content-main + .content-main {
  margin-top : 1em;
}

.grey-block {
  display : inline-block;
  .content-main;
}

.row,
.row-fluid {
  .grey-block:first-of-type {
	padding-left : 0;
  }
  .grey-block:last-of-type {
	padding-right : 0;
  }
}

.flush {
  [class*="col-"]:first-of-type {
	padding-left : 0;
  }
  [class*="col-"]:last-of-type {
	padding-right : 0;
  }
}

// All but the first row
.row:nth-child(n+1),
.row-fluid:nth-child(n+1) {
  .grey-block {
	margin-top : @p*.5;
  }
}

// Fees Table
table.fees-table, table.fees-table tr, table.fees-table td{
    border: 1px solid black;
}
table.fees-table td {
    padding:10px;
}

// Two column layout
.sitemap {
	.links {
		column-count: 2;
		-moz-column-count: 2;
		-webkit-column-count: 2;
		list-style-position: inside;
	}
}	

.calculator input[name="surcharge"] {
  position: static !important;
  float: left;
}
.calculator .crf {
  position: static !important;
  float: left;
  width: 88%;
  margin-left: 4%;
}
.calculator .seconhomecheck {
  display: none;
}
@media (max-width: 480px) {
	.form-group-gdpr {
		.checkbox {
			margin-right: 16px;
			label {
				&:before {
					float: left;
					margin-bottom: 5px !important;
				}
			}
		}
	}
}
.form-group-gdpr {
  .checkbox {
	text-align : left;
	overflow   : hidden;
	margin-bottom: 13px;
        input:checked~label:before {
            background     : @white;
            box-shadow: none;
        }
        input:checked~label:after {
            .tk-icon-font;
            content  : '\e80d';
            color    : @orange;
            position : absolute;
            left     : 0;
            top      : 0;
            line-height: 17px;
        }
	label {
	  display     : inline-block;
	  color       : #6E6E6E;
	  font-size   : 12px;
	  position    : relative;
	  cursor      : pointer;
	  line-height : 18px;
	  //margin      : 0 10px 0 0;
          padding-left: 0;
	  &:before {
		margin         : 0 14px 0 0;
		vertical-align : top;
		position       : relative;
		content        : "";
		display        : inline-block;
		width          : 16px;
		height         : 16px;
		background     : @white;
	  }
	  &.checked:after {
		.tk-icon-font;
		content  : '\e80d';
		color    : @orange;
		position : absolute;
		left     : 0;
		top      : 0;
		line-height: 17px;
	  }
	}
	&.error {
		label {
			&:before {
				box-shadow: inset 0 0 0 1px #00e6e6;
				background: #ccfafa;
			}
		}
	}
        input[type=checkbox] {
            display: none;
        }
  }
}
/* cookies link and page css */
a.cookies {
float:left;
margin-top: -30px;
font-size: 11px;
font-weight: normal;
}
.cookies-table  tr{
   border:1px solid #dbd5d5;
   line-height: 30px;
 
}
.cookies-table {
 width:100%;
 margin-bottom:20px;
}
.cookies-table td,
.cookies-table th {
    word-break: break-word;
    vertical-align: baseline;
    padding: 0 5px;
    border:1px solid #dbd5d5;
    @-moz-document url-prefix() {
        word-break: break-all;
    }
}
table.table.cookie-table th, td {
     border: 1px solid #ddd;
}
/** Elfsight Google Module **/
.elfsight-google-reviews-wrapper {
    margin: 0px auto 35px;
}