.video-wrapper {
  height         : 0;
  margin         : 0 auto;
  padding-bottom : 56.25%;
  position       : relative;
  text-align     : center;
  top            : 10px;
  iframe {
	position : absolute;
	top      : 0;
	left     : 0;
	width    : 100%;
	height   : 100%;
  }
}