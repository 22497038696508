/* create an arrow that points up */
.arrow-up {
  width         : 0;
  height        : 0;
  border-left   : 15px solid transparent;
  border-right  : 15px solid transparent;
  border-bottom : 15px solid rgba(255, 255, 255, 0.7);
  font-size     : 0;
  line-height   : 0;
}

/* create an triangle that points down */
.arrow-down(@size) {
  width        : 0;
  height       : 0;
  border-left  : @size solid transparent;
  border-right : @size solid transparent;
  border-top   : @size solid @greylight;
  font-size    : 0;
  line-height  : 0;
}

.centered {
  text-align : center;
}

.clearfix() {
  &:before,
  &:after {
	content : " ";
	display : table;
  }
  &:after {
	clear : both;
  }
}

.hide-text {
  text-indent : 100%;
  white-space : nowrap;
  overflow    : hidden;
}

.unhide-text {
  text-indent : 0;
}

.box {
  margin  : 0;
  padding : 0;
  .item {
	margin-bottom  : 10px;
	padding-bottom : 29px;
	margin-right   : 0 !important;
  }
  .item h3 {
	font-size : 24px;
	padding   : 8px 0 10px;
  }
  &.grey {
	background : @greylight;
  }
  &.orange {
	background : @orange;
  }
  &.orange90 {
	background : @orange90;
  }
  .block {
	display : block;
  }
}

.padded {
  padding : 1.5 * @p 1.5 * @p 0 1.5 * @p;
  &.form-group {
	margin-bottom : 0;
  }
}

.flag(@size) {
  content      : ' ';
  position     : absolute;
  width        : 0;
  height       : 0;
  left         : auto;
  right        : 0;
  top          : 0;
  border-width : @size;
  border-style : solid;
}

.flag-right(@color,@size) {
  .flag(@size);
  border-color : @color transparent @color @color;
  right        : -@size*2;
}

.flag-down(@color,@size ) {
  .flag(@size);
  border-color : @color @color transparent @color;
}

.square {
  position : relative;
  &:before {
	content       : "";
	position      : absolute;
	right         : 0;
	bottom        : 0;
	width         : 32px;
	height        : 32px;
	border-top    : 0;
	border-right  : @thin;
	border-bottom : @thin;
	border-left   : 0;
	border-style  : solid;
	border-color  : @orange;
  }
  &:after {
	content       : "";
	position      : absolute;
	left          : 0;
	top           : 0;
	width         : 32px;
	height        : 32px;
	border-top    : @thin;
	border-right  : 0;
	border-bottom : 0;
	border-left   : @thin;
	border-style  : solid;
	border-color  : @orange;
  }
}

.clearfix {
  display : block;
  zoom    : 1;

  &:after {
	content    : " ";
	display    : block;
	font-size  : 0;
	height     : 0;
	clear      : both;
	visibility : hidden;
  }
}

.flush {
  &.row [class*="col-"]:first-of-type,
  &.row-fluid [class*="col-"]:first-of-type {
	padding-left : 0;
  }
  &.row [class*="col-"]:last-of-type,
  &.row-fluid [class*="col-"]:last-of-type {
	padding-right : 0;
  }
}

.no-pad {
  &.row [class*="col-"],
  &.row-fluid [class*="col-"] {
	padding-left  : 0;
	padding-right : 0;

  }
}

.nexa {
  font-family : 'nexa';
}

.AvenirLTStd {
  font-family : 'AvenirLTStd';
}

.btn-color () {
  background       : #2d0f19;
  background-image : url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDk3IDQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9ImhhdDAiIGdyYWRpZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiB4MT0iNTAlIiB5MT0iMTAwJSIgeDI9IjUwJSIgeTI9Ii0xLjQyMTA4NTQ3MTUyMDJlLTE0JSI+CjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMyZDBmMTkiIHN0b3Atb3BhY2l0eT0iMSIvPgo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM1NTM1NDEiIHN0b3Atb3BhY2l0eT0iMSIvPgogICA8L2xpbmVhckdyYWRpZW50PgoKPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk3IiBoZWlnaHQ9IjQwIiBmaWxsPSJ1cmwoI2hhdDApIiAvPgo8L3N2Zz4=);
  background-image : -moz-linear-gradient(bottom, #2d0f19 0%, #553541 100%);
  background-image : -o-linear-gradient(bottom, #2d0f19 0%, #553541 100%);
  background-image : -webkit-linear-gradient(bottom, #2d0f19 0%, #553541 100%);
  background-image : linear-gradient(bottom, #2d0f19 0%, #553541 100%);
}

.btn-color-l {
  background       : #492735;
  background-image : url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDI5IDQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9ImhhdDAiIGdyYWRpZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiB4MT0iNTAlIiB5MT0iMTAwJSIgeDI9IjUwJSIgeTI9Ii0xLjQyMTA4NTQ3MTUyMDJlLTE0JSI+CjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM0OTI3MzUiIHN0b3Atb3BhY2l0eT0iMSIvPgo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM3MTU3NjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogICA8L2xpbmVhckdyYWRpZW50PgoKPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjI5IiBoZWlnaHQ9IjQwIiBmaWxsPSJ1cmwoI2hhdDApIiAvPgo8L3N2Zz4=);
  background-image : -moz-linear-gradient(bottom, #492735 0%, #715761 100%);
  background-image : -o-linear-gradient(bottom, #492735 0%, #715761 100%);
  background-image : -webkit-linear-gradient(bottom, #492735 0%, #715761 100%);
  background-image : linear-gradient(bottom, #492735 0%, #715761 100%);
}

.btn-color-hover () {
  background-image : url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDIwIDQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9ImhhdDAiIGdyYWRpZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94IiB4MT0iNTAlIiB5MT0iMTAwJSIgeDI9IjUwJSIgeTI9Ii0xLjQyMTA4NTQ3MTUyMDJlLTE0JSI+CjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM3ZDE1MGQiIHN0b3Atb3BhY2l0eT0iMSIvPgo8c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjN2QxNTBkIiBzdG9wLW9wYWNpdHk9IjEiLz4KPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNDMxZjJiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgPC9saW5lYXJHcmFkaWVudD4KCjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIyMCIgaGVpZ2h0PSI0MCIgZmlsbD0idXJsKCNoYXQwKSIgLz4KPC9zdmc+);
  background-image : -moz-linear-gradient(bottom, #7d150d 0%, #7d150d 0.51%, #431f2b 100%);
  background-image : -o-linear-gradient(bottom, #7d150d 0%, #7d150d 0.51%, #431f2b 100%);
  background-image : -webkit-linear-gradient(bottom, #7d150d 0%, #7d150d 0.51%, #431f2b 100%);
  background-image : linear-gradient(bottom, #7d150d 0%, #7d150d 0.51%, #431f2b 100%);
}

.purplegradient {
  background       : #411f2c;
  background-image : url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDY4IDEzMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PGxpbmVhckdyYWRpZW50IGlkPSJoYXQwIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjUwJSIgeTE9IjEwMCUiIHgyPSI1MCUiIHkyPSItMS40MjEwODU0NzE1MjAyZS0xNCUiPgo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMzUxOTIzIiBzdG9wLW9wYWNpdHk9IjEiLz4KPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNDMxZjJkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgPC9saW5lYXJHcmFkaWVudD4KCjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSI2OCIgaGVpZ2h0PSIxMzAiIGZpbGw9InVybCgjaGF0MCkiIC8+Cjwvc3ZnPg==);
  background-image : -moz-linear-gradient(bottom, #351923 0%, #431f2d 100%);
  background-image : -o-linear-gradient(bottom, #351923 0%, #431f2d 100%);
  background-image : -webkit-linear-gradient(bottom, #351923 0%, #431f2d 100%);
  background-image : linear-gradient(bottom, #351923 0%, #431f2d 100%);
}

.share-btn () {
  background       : #42202d;
  background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgo8bGluZWFyR3JhZGllbnQgaWQ9ImcyMyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzQyMjAyRCIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzQzMjAyRCIgb2Zmc2V0PSIxIi8+CjwvbGluZWFyR3JhZGllbnQ+CjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZzIzKSIgLz4KPC9zdmc+);
  background-image : -moz-linear-gradient(bottom, #42202d 0%, #43202d 100%);
  background-image : -o-linear-gradient(bottom, #42202d 0%, #43202d 100%);
  background-image : -webkit-linear-gradient(bottom, #42202d 0%, #43202d 100%);
  background-image : linear-gradient(bottom, #42202d 0%, #43202d 100%);
}

/* From Sandfords custom-mixons.less */
.transition(@transition) {
  -webkit-transition : @transition;
  -moz-transition    : @transition;
  -o-transition      : @transition;
  -ms-transition     : @transition;
  transition         : @transition;
}

.transform(@transform) {
  transform         : @transform;
  -webkit-transform : @transform;
  -moz-transform    : @transform;
  -o-transform      : @transform;
  -ms-transform     : @transform;
}

.background-cover() {
  -webkit-background-size : cover;
  -moz-background-size    : cover;
  -o-background-size      : cover;
  background-size         : cover;
}

.rotate(@rotate) {
  -moz-transform    : rotate(@rotate);
  -webkit-transform : rotate(@rotate);
  -o-transform      : rotate(@rotate);
  -ms-transform     : rotate(@rotate);
  transform         : rotate(@rotate);
}

.box-shadow(@shadow) {
  // e.g. -moz-box-shadow: 3px 3px 5px 6px #ccc;
  -moz-box-shadow    : @shadow;
  -webkit-box-shadow : @shadow;
  box-shadow         : @shadow;
}

/* Swiper specific */
.swiper-wrapper-transition {
  -webkit-transition-property        : -webkit-transform, left, top;
  -webkit-transition-duration        : 0s;
  -webkit-transform                  : translate3d(0px, 0, 0);
  -webkit-transition-timing-function : ease;
  -moz-transition-property           : -moz-transform, left, top;
  -moz-transition-duration           : 0s;
  -moz-transform                     : translate3d(0px, 0, 0);
  -moz-transition-timing-function    : ease;
  -o-transition-property             : -o-transform, left, top;
  -o-transition-duration             : 0s;
  -o-transform                       : translate3d(0px, 0, 0);
  -o-transition-timing-function      : ease;
  -o-transform                       : translate(0px, 0px);
  -ms-transition-property            : -ms-transform, left, top;
  -ms-transition-duration            : 0s;
  -ms-transform                      : translate3d(0px, 0, 0);
  -ms-transition-timing-function     : ease;
  transition-property                : transform, left, top;
  transition-duration                : 0s;
  transform                          : translate3d(0px, 0, 0);
  transition-timing-function         : ease;
}