.home-people-intro {
    .person-right {
        .person-text {
            h2 {
              margin-bottom: 10px;
              padding-bottom: 0px;
            }
            .team_member_title {
              padding-left: 0px;
              padding-right: 20px;
            }
            p {
              margin: 0px;
            }
            a {
                padding-right: 22px;
            }
        }
    }
    .person-left {
        width: 50%;
        .person-image {
            float: right;
        }
        .person-text {
            h2 {
              margin-bottom: 10px;
              padding-bottom: 0px;
            }
            p {
                width: 100%;
            }
            a {
                padding-left: 8px;
            }
        }
    }
    .person-year {
        width: 106%;
        margin: 0 -23px;
    }
}
.instagram-wrapper {
    .photo-wrapper {
        .image-item {
            height: auto;
            width: 50%;
            padding-left: 7px;
            padding-bottom: 7px;
            &:nth-child(even) {
                text-align: left;
            }
            &:nth-child(odd) {
                text-align: right;
            }
        }
    }
}
.home-people-intro .about-content {
    padding: 97px 20px 138px;
}
.home .featured-line {
    min-height: 1173px;
}
.home-slider-wrapper .owl-carousel img {
    height: auto;
}
.home-slider .swiper-slide {
    height: 535px;
}
.home-slider-wrapper .owl-carousel {
    height: 500px !important;
    max-height: 500px !important;
}
.ImageSliderOuter.featured {
    .slide {
        height: 535px;
    }
}
.home-top-banner .home-banner-search .form-group.first:before, .home-top-banner .home-banner-search .form-group.last:before {
    width: 94%;
    left: 2%;
}
.home-top-banner .home-banner-search .form-group .crf-s:after {
    left: 92%;
}
.top-search-wrapper {
    .form-group {
       .form-control {
            padding-left: 24px;
        }
        &.first {
            padding-left: 24px;
        }
        &.last {
            padding-left: 24px;
        }

    }
}
body {
    &.search-module-collapse {
        overflow: hidden;
        .top-search-wrapper {
            height: 500px;
            overflow-y: auto;
            max-height: 700px;
            .form-group.buttons {
                margin-bottom: 256px;
            }
        }
    }
}
.welcome.featured {
    padding: 57px 15px 10px;
}
.featured-wrapper .owl-carousel img {
    height: auto !important;
}
