.module {
  &.area-guide {
	background : @purpledark;
	color      : @white;
	margin     : 40px 0;
	padding    : 20px 15px 40px;
	text-align : center;
	h3, h2 {
	  color : @white;
	}
	h2 {
	  font-size      : 20px;
	  text-transform : uppercase;
	  i {
		color          : @orange;
		font-size      : 1.5em;
		vertical-align : middle;
	  }
	}
	h3 {
	  line-height : 1.5em;
	  padding     : 0 15px;
	}
	.btn {
	  display : block;
	  &:hover {
		.btn-color-l;
	  }
	}
  }
}
.blog.guides.property-guides .guide {
	min-height:375px;
}
.guides {
  .guide {
	margin-bottom : @p;
	&.even {
	  padding-left  : 0;
	  padding-right : 0;
	  .grey.box {
		margin-right : @p*.5;
		@media @mobile {
		  margin-right  : 0;
		  margin-left   : 0;
		  margin-bottom : @p*.5;
		}
	  }
	}
	&.odd {
	  padding-left  : 0;
	  padding-right : 0;
	  .grey.box {
		margin-left : @p*.5;
		@media @mobile {
		  margin-right : 0;
		  margin-left  : 0;
		}
	  }
	}
  }

  .grey.box {
	padding-top : 0;
	img {
	  float       : right;
	  border-left : 20px solid transparent;
	}
	@media @mobile {
	  padding-bottom : 40px;
	  img {
		border    : none;
		float     : none;
		max-width : 100%;
	  }
	}
  }
  .horizontal {
	&.ribbon {
	  &.below {
		margin       : 15px 0 40px;
		padding-left : .65em;
		top          : 0;
		@media @mobile {
		  left           : -.1em;
		  padding-left   : 18px;
		  padding-bottom : 0;
		  margin-bottom  : 0;
		  img {
			max-width : 100%;
		  }
		}
		@media @tablet-xl {
		  &:before {
			.flag-right(@orange, 18px)
		  }
		}
	  }
	}
  }
}
.area-icons{
	margin: 10px 0px 20px 0px;
}
