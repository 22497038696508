.history {
  h1 {
	.underlined;
  }
  .items {
	.item {
	  background     : @greylight;
	  margin-bottom  : 80px;
	  padding-bottom : 80px;
	  position       : relative;
	  text-align     : center;
	  img {
		float        : right;
		margin-right : -15px;
	  }
	  .arrow-down {
		.arrow-down(20px);
		bottom      : -20px;
		left        : 50%;
		margin-left : -20px;
		position    : absolute;
	  }
	  i {
		bottom      : -65px;
		color       : @orange;
		font-size   : 2em;
		left        : 50%;
		margin-left : -20px;
		position    : absolute;
	  }
	  &:last-of-type {
		i {
		  display : none;
		}
	  }
	}
  }
}