.slide {
  background-repeat   : no-repeat;
  background-position : center center;
  background-size     : cover;
  float               : left;
}

.ImageSliderControls {
  text-align : justify;
}

.ImageSliderPrevNext {
  display : inline-block;
}

.ImageSliderControls .ImageSliderPrevNext span {
  display     : block;
  text-indent : -9999px;
}