// geo location icon on location textbox
.home-team-btn {
    margin-top: 30px;
    padding: 15px 0 50px 0;
}
.home {
  .featured-line {
        min-height : 910px;
  }
  .welcome {
    h2 {
        width: 80%;
        font-size: 26px;
        line-height: 32px;

    }
    p {
        width: 100%;
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
    }
    .btn {
        font-size: 14px;
        line-height: 30px;

    }
  }
  .area-guide {
        h2 {
          font-size : 3em;
        }
  }
}

.home-slider-wrapper {
  .wrapper {
	.container {
	  .content {
		font-size   : @p;
		margin-left : 107px;
	  }
	}
  }
}

.module {
  &.area-guide {
	float : left;
	width : 100%;
	.btn {
	  margin : 0 auto;
	  width  : 245px;
	}
  }
}

.navbar-inverse .navbar-nav .dropdown-menu > li > a {
  color : @white;
}

.price-wrapper {
  min-height : initial;
}

.search-form {
  display : none;
  &.refine-form {
	margin-bottom : 0;
  }
}

.sales-search {
  margin  : 0;
  padding : 0;
  h1 {
	padding-bottom : 0;
	margin-bottom  : 10px;
	font-size      : 24px;
  }
  section {
	padding-bottom : 45px;
  }
  article {
	.specs {
	  margin : -15px 0 1px;
	}
  }
  .currencies {
	margin-top : 0;
  }

  .tablet-only {
	&.row {
	  margin-left  : 0;
	  margin-right : 0;
	}
	&.refine {
	  margin        : 4px 0;
	  float         : right;
	  color         : #532939;
	  font-size     : @pbig2;
	  padding-right : 23px;
	  position      : relative;
	  line-height   : 29px;
	  &:after {
		.tk-icon-font;
		content         : '\e800';
		color           : @orange;
		position        : absolute;
		top             : 6px;
		right           : -.25*@p;
		text-decoration : none;
	  }
	  &.active {
		&:after {
		  .tk-icon-font;
		  content : '\e81f';
		}
	  }
	}
  }

  .refine-wrapper.mobile-only {
	background   : @greylight;
	color        : @greymiddle;
	text-align   : center;
	line-height  : 18px;
	margin-left  : -22px;
	margin-right : -22px;
	.refine {
	  margin        : 4px 0;
	  color         : #532939;
	  display       : inline-block;
	  font-size     : @pbig2;
	  margin-bottom : 15px;
	  padding-right : 23px;
	  position      : relative;
	  &:after {
		.tk-icon-font;
		content         : '\e800';
		color           : @orange;
		position        : absolute;
		right           : -.25*@p;
		text-decoration : none;
	  }
	  &.active {
		&:after {
		  .tk-icon-font;
		  content : '\e81f';
		}
	  }
	}
  }
  .toolbar {
	line-height   : 42px;
	margin-bottom : 20px;
	.view,
	.pagination {
	  display : none !important;
	}
	.order {
	  padding-left : 0;
	}
  }
}

.responsive-only,
.tablet-only {
  display : block;
}

.breadcrumb {
  display : none;
}

.sales-search.grid-style section {
  margin : 0;
}

.search {
  .box {
	&.orange90 {
	  background : @orange;
	}
  }
}

.search-wrapper {
  &.desktop {
	background : @orange;
	text-align : center;
	top        : 60px;
	h3 {
	  float : none;
	}
	.form-group {
	  float         : none !important;
	  margin-bottom : 10px;
	  &.block {
		float : left !important;
	  }
	  &.buttons {
		padding-top : 10px !important;
	  }
	  &:last-of-type {
		margin-bottom : 0;
	  }
	}
	.form-control,
	.crf-s {
	  width : 100%;
	}
	.area {
	  display : block;
	}
	.buttons {
	  margin-left : 0;
	  .btn,
	  .btn:last-child {
		display     : block;
		float       : none;
		font-size   : 18px;
		height      : 40px;
		line-height : 38px;
		margin      : 0 auto;
		width       : auto;
	  }
	}
  }
}

.form-search,
.form-search-inner {
  .block {
	float : left;
	width : 50%;
	&.first {
	  padding-right : 7.5px;
	}
	&.last {
	  padding-left : 14px;
	}
  }
  .buttons {
	clear : left;
  }
}

.search-wrapper {
  &.desktop {
	.form-search {
	  .block {
		float : left !important;
		width : 50%;
		&.first {
		  padding-right : 7.5px;
		}
		&.last {
		  padding-left : 7.5px;
		}
	  }
	}
  }
}

.specs {
  text-align : left;
  float      : none;
  margin     : 0;
  padding    : 0 0 13px;
  .ico-bed,
  .ico-bath,
  .ico-living {
	background-size : 108px auto;
	width           : 36px;
	margin          : 10px 0 0 1px;
	height          : 18px;
  }
  .count {
	font-size   : @p;
	line-height : 18px;
	padding     : 0;
	margin      : 0 8px 0 2px;
  }
}

.team {
  .ribbon {
	&.horizontal {
	  &:before {
		.flag-right(@purpledark, 18px);
	  }
	}
  }
}

h2 {
  &.ribbon {
	&.horizontal {
	  font-size : 18px;
	}
  }
}

.property {
  margin-top  : 0;
  padding-top : 7px;
  h1 {
	font-size   : 24px;
	font-weight : bold;
	line-height : normal;
  }
  .price {
	font-size : 24px;
  }
  .swipe-line {
	text-align  : center;
	color       : @orange;
	font-size   : @p;
	background  : @greylight;
	line-height : 50px;
  }
  .description {
	position : relative;
	z-index  : 9;
  }
  h3 {
	font-size      : @h3;
	padding-bottom : 17px;
  }
  p {
	font-size      : 13px;
	line-height    : 18px;
	padding-bottom : 17px;
  }
  /* Why is this hidden?
.plans,
.map {
  display : none;
}
*/
  .gallery {
	margin-bottom : -21px;
	top           : 0;
  }
  .gallery .bx-wrapper {
	max-width : none !important;
  }
  .gallery .slides .bx-controls {
	display : block;
  }
  .gallery .thumbs {
	display : none;
  }
  .gallery .thumbs .bx-viewport {
	display : none;
  }
  .gallery .bx-prev,
  .gallery .bx-next {
	position        : absolute;
	bottom          : 57px;
	right           : 20px;
	background      : url(../images/ico_arrow.png) no-repeat 100% 0;
	width           : 15px;
	height          : 28px;
	display         : block;
	opacity         : .5;
	background-size : 30px auto;
	text-indent     : -999em;
	&:before {
	  display : none;
	}
	&:hover {
	  opacity : 1;
	}
  }
  .gallery .bx-prev {
	background-position : 0 0;
	left                : 20px;
  }
  .gallery .bx-prev {
	margin : 0 -125px;
	left   : 50%;
  }
  .gallery .bx-next {
	margin : 0 108px;
	left   : 50%;
  }

  .nav-text {
	background : @greylesslight;
	color      : @orange;
	display    : block;
	margin-top : -5em;
	padding    : 2.75em 0 1.5em;
	text-align : center;
	width      : 100%;
  }

  .nav-tabs {
	margin : 0 -20px 22px;
	li {
	  float : left;
	  width : 14.28%;
	  a {
		z-index     : 99;
		text-indent : -999em;
		position    : relative;
		height      : 50px;
		.ico {
		  position    : absolute;
		  display     : block;
		  left        : 50%;
		  top         : 12px;
		  margin-left : -20px;
		  width       : 40px;
		  height      : 28px;
		  background  : url(../images/ico_tabs.png) no-repeat;
		}
		.ico-2 {
		  background-position : 0 -28px;
		}
		.ico-3 {
		  background-position : 0 -56px;
		}
		.ico-4 {
		  background-position : 0 -84px;
		}
		.ico-5 {
		  background-position : 0 -112px;
		}
		.ico-8 {
		  background-position: 0 -139px;
		}
	  }
	}
  }
}

.btn.btn-danger img {
  max-height : 20px;
}

.seo-links {
  margin-top : 80px;
  padding    : 20px 0 40px;
  .content ul {
	width : 50%;
  }
}

.seo-links .nav-tabs li {
  width : 33.3%;
  &:last-child {
	display : none;
  }
}

.sidebar {
  margin-left : 0;
  top         : 0;
  margin-top: 40px;
  .orange-boxes {
	.item {
	  width          : 49%;
	  padding-bottom : 28px;
	  margin-bottom  : 0;
	}
	&:nth-of-type(odd) {
	  .item {
		float : left;
	  }
	}
	&:nth-of-type(even) {
	  .item {
		float : right;
	  }
	}
  }
  .calculator {
	display : none;
  }
  .contacts-item {
	padding       : 0 0 8px;
	margin-bottom : 60px;
	.set {
	  max-width : 250px;
	  padding   : 3px 0 2px;
	  margin    : auto;
	}
	p {
	  margin    : 0;
	  font-size : @p;
	  display   : inline-block;
	  padding   : 0;
	  strong {
		font-size : @pbig2;
	  }
	  br {
		display : none;
	  }
	  + p {
		display : block;
	  }
	}
  }
}

.interest-line {
  background    : @greylight;
  border-top    : 1px solid @white;
  margin        : 0 -20px;
  line-height   : 18px;
  padding       : 15px 20px 15px;
  border-bottom : 2px solid @white;
  overflow      : hidden;
  .btn {
	float       : right;
	margin-left : .25em;
	margin-top  : 0;
  }

  p {
	color   : @purpledark;
	margin  : 0;
	padding : 0;
  }
  .phone {
	color       : @orange;
	font-size   : @pbig2;
	font-weight : bold;
	margin-left : .25em;
  }
  p,
  .phone {
	float       : left;
	padding-top : 12px;
  }
}

.container {
  width     : auto;
  max-width : none;
  &.main {
    margin-bottom: 0;
  }
}

.content-box {
  padding-bottom : 17px;
  h2 {
	height          : 40px;
	background-size : auto 100%;
	font-size       : @pbig2;
	padding-right   : 45px;
	line-height     : 40px;
  }
}

.form {
  padding : 28px 20px;
  p {
	font-size      : 13px;
	line-height    : 18px;
	padding-bottom : 14px;
	br {
	  display : none;
	}
  }
  .btn {
	font-size     : @pbig2;
	height        : 40px;
	margin-bottom : 15px;
  }
  textarea.form-control {
	height        : 90px;
	margin-bottom : 0;
  }
  .full {
	padding-top : 10px;
  }
  .center {
	width     : auto;
	max-width : none;
  }
  fieldset {
	padding-bottom : 0;
  }
  .set {
	padding-bottom : 35px;
	margin-bottom  : 30px;
	h6 {
	  padding-left : 5px;
	  font-size    : @p;
	  margin-right : 7px;
	}
	label {
	  margin-left : 17px;
	}
  }
}

.navbar-inverse .navbar-brand {
  width           : 145px;
  height          : 25px;
  margin          : 18px 15px 0 0 !important;
  background-size : contain;
}

.mobile-links {
  float        : left;
  margin: 0;
  padding      : 19px 0 0 0;

  .contacts {
	background : @purple !important;
	width      : 100% !important;
	padding    : 13px 0 !important;
	a {
	  margin-left : 0;
	  width       : auto;
	  &:before {
		color     : @orange;
		font-size : 1em;
	  }
	}

	a, p, .social, .social ul, .social li {
	  display : inline-block;
	  float   : none;
	}
	p {
	  display       : block;
	  line-height   : 24px;
	  margin-bottom : 0;
	  .phone {
		line-height : inherit;
	  }
	  + p {
		margin-top : 8px;
	  }
	}
	.social {
	  display    : block;
	  margin-top : 13px;
	  p,
	  ul {
		display        : inline-block;
		line-height    : 21px;
		vertical-align : middle;
	  }
	  li {
		a {
		  padding : 0;
		}
	  }
	  [class^="icon-"]:before,
	  [class*=" icon-"]:before {
		margin-left  : 0;
		margin-right : 0;
	  }
	}
  }

  .dropdown {
	float : left;
  }

  .search {
	overflow   : hidden;
	text-align : center;
	h3 {
	  font-size   : @pbig2;
	  font-weight : normal;
	  text-align  : center;
	  color       : @white;
	  margin      : 0;
	  padding     : 0 0 15px;
	}
	form {
	  padding : 15px;
	  margin  : 0;
	  .clearfix;
	}
  }

  .buttons {
	overflow   : hidden;
	position   : relative;
	padding    : 10px 0;
	margin-top : 10px;
	.div {
	  position    : absolute;
	  left        : 50%;
	  width       : 1px;
	  margin-left : -1px;
	  background  : @orangelight;
	  top         : 0;
	  bottom      : 0;
	}
	.btn {
	  float       : left;
	  height      : 40px;
	  .news {
		margin         : -5px 0;
		padding-bottom : 83px;
		h3 {
		  height          : 40px;
		  background-size : auto 100%;
		  font-size       : @pbig2;
		  line-height     : 40px;
		}
		a.btn {
		  margin-top : 7px;
		}
		.tweet {
		  padding       : 40px 0 0;
		  margin-bottom : 22px;
		  line-height   : 18px;
		}
		.item {
		  padding-bottom : 5px;
		  min-height     : 355px;
		}
	  }
	  .orange-boxes {
		padding : 49px 0;
		.col-md-6:last-child {
		  .item {
			margin-left  : -8px;
			margin-right : 0;
		  }
		}
		.item {
		  background   : @orange90;
		  text-align   : center;
		  font-size    : @pbig2;
		  margin-right : -8px;
		  padding      : 20px 0 25px;
		  min-height   : 222px;
		  line-height  : @pbig2-lh;
		  color        : @white;
		  &:hover {
			background : @orange;
		  }
		  h3 {
			color     : @purpledark;
			margin    : 0 0 15px;
			font-size : 30px;
			.nexa;
		  }
		  p {
			margin : 0 0 17px;
		  }
		  .btn {
			padding    : 0 40px;
			min-width  : 218px;
			text-align : center;
		  }
		}
	  }
	  line-height : 38px;
	  width       : 44%;
	  &:last-child {
		float : right;
	  }
	}
  }

  .navbar-inverse & {
	.dropdown-menu {
	  width      : auto;
	  background : none;
	  padding    : 0;
	  left       : 0;
	  right      : 0;
	  margin     : 0;
	}
  }

  a {
	float           : left;
	text-decoration : none;
	position        : relative;
	height          : 24px;
	margin-left     : 17px;
	width           : 16px;
	&:before {
	  .tk-icon-font;
	  color     : @purple;
	  font-size : 1.5em;
	}
	&.ico-contacts {
	  &:before {
		content : '\e82e';
                color : #f05323;
	  }
	}
	&.ico-search {
	  &:before {
		content : '\e831';
                color : #ffffff;
	  }
          &.collapsed {
            &:before {
              color : #ffffff;
            }
          }
	}
	&.collapsed {
	  &:before {
		color : #f05323;
	  }
	}
  }

  .navbar-toggle {
	display          : block;
	border-radius    : 0;
	box-shadow       : none;
	height           : 24px;
	width            : 16px;
	padding          : 0;
	margin           : 0 12px 0 4px;
	background-color : transparent !important;
	border           : 0;
	&:before {
	  .tk-icon-font;
	  color     : @purple;
	  content   : '\e82b';
	  font-size : 1.5em;
	}
	&.collapsing,
	&.collapsed {
	  background-position : 0 0;
	  &:before {
		color : #fff;
	  }
	}
  }

}

.featured-line h2 {
  padding     : 25px 25px;
  margin      : 10px 0 80px -20px;
  font-size   : 34px;
  line-height : 42px;
}
.welcome.featured {
    padding: 21px 15px 100px;
}
.welcome {
  padding : 20px 15px 0;
  margin  : 0;
  h2 {
	font-size : 24px;
	/* Conflicts with ribbon styles
  padding   : 4px 0 10px;
  */
  }
  p {
	font-size      : @p;
	line-height    : 18px;
	padding-bottom : 0;
  }
  .col-md-4 {
	float : left;
	width : 38%;
  }
  .col-md-8 {
	clear : none;
	float : right;
	width : 58%;
	img {
	  width  : 100%;
	  height : auto;
	}
  }
  // Align main columns to grid boundaries
  .col-main {
	padding-right : 0;
	padding-left  : 0;
  }
}

// Align main columns to grid boundaries
.col-md-9 .item-page,
.col-md-9 .content-main,
.startek-details .property {
  margin-right : 0;
}

.news {
  margin         : -5px 0;
  padding-bottom : 83px;
  h3 {
	height          : 40px;
	background-size : auto 100%;
	font-size       : @pbig2;
	line-height     : 40px;
  }
  a.btn {
	margin-top : 7px;
  }
  .tweet {
	padding       : 40px 0 0;
	margin-bottom : 22px;
	line-height   : 18px;
  }
  .item {
	padding-bottom : 5px;
	min-height     : 355px;
  }
}

.orange-boxes {
  padding : 40px 0 38px;
  .item {
	min-height : 200px;
	h3 {
	  padding-top : 5px;
	  font-size   : 24px;
	}
	p {
	  font-size   : @p;
	  line-height : 18px;
	  margin      : 0;
	  min-height  : 60px;
	  padding     : 0 30px 14px;
	  br {
		display : none;
	  }
	}
	.btn {
	  min-width : 140px;
	  padding   : 0 8px;
	}
  }
}

body {
  min-width : 320px;
}

.bx-wrapper {
  max-width : 690px !important;
  margin    : 0 auto !important;
}

.ico-down {
  margin : 110px auto 30px;
}

.contacts,
.footer-bar,
.featured-line .search {
  display : none;
}

.split-module-group {
    display: table;
}
.properties {
  &.similar-row {
	.item {
	  width      : 32%;
	  min-height : 387px;
	  img {
		width : 100%;
	  }
	}
  }
}

.navbar-inverse {
  height : 60px;
  .navbar-nav {
	width : auto;
	float : none;
  }
}

.featured-line {
  margin-top : 70px;
}

// Override Bootstrap navbar-collapse.collapse styles as it is displayed by default.
.navbar-inverse .navbar-collapse.collapsing,
.navbar-inverse .navbar-collapse.collapse.in {
  display : block !important;
  min-height: 1045px;
  max-height: 1500px;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-collapse.collapse {
  background : @purple;
  box-shadow : none;
  border     : 0;
  clear      : both;
  display    : none !important;
  float      : left !important;
  height     : auto;
  margin     : 0;
  max-height : 400px;
  overflow   : hidden;
  position   : absolute;
  top        : 0;
  z-index: 2;
  width      : 100% !important;
}

.navbar-nav > li > .dropdown-menu {
  position : static;
  float    : none;
  overflow : hidden;
  clear    : both;
  width    : auto;
}


.col-md-3,
.col-md-9 {
  width : 100% !important;
}

.rooms {
  float   : none !important;
  margin  : 0;
  padding : 0 0 13px;
  li {
	margin       : 10px 0 0 1px;
	padding-left : 0;
  }
}

.sales-search.grid-style {
  section {
	text-align   : justify;
	margin-left  : 0;
	margin-right : 0;
  }
  article {
	margin-left  : 0;
	margin-right : 0;
	width        : 48.85%;
	position     : relative;
	min-height   : 503px;
  }
  .buttons {
	bottom        : 47px;
	margin-bottom : 0;
	position      : absolute;
  }
  h2 {
	min-height : 1.5em;
  }
}

.home-slider {
  .text {
	span {
	  padding : 0 !important;
	}
  }
}

.ImageSliderControls {
  font-size  : 1.5em;
  margin-top : 15px !important;
  .ImageSliderPrev,
  .ImageSliderNext {
	line-height : 0;
	position    : relative;
  }
  .ImageSliderPrev {
	left : 15px;
        top: 10px;
  }
  .ImageSliderNext {
	right : 15px;
        top: 10px;
  }
}
.header-center {
  display : none;
}
.header-right {
    .contact-wraper {
        display : none;
    }
}
.home-top-banner {
    height: 160px;
    p {
        font-size: 20px;
        line-height: 26px;
        &.title-text {
            padding-top: 63px;
        }
    }
    &:before {
        height: 230px;
    }
    .home-banner-search {
        background-color: #532A3A;
        height: 445px;
        .search {
            display: block;
            padding: 50px 0;
        }
        i {
            position: inherit;
            font-size: 1em;
        }
        .links {
            margin-top: 41px;
        }
        .form-group {
            height: 60px;
            width: 100%;
            margin-bottom: 0;
            &.first {
                &:before {
                    width: 94%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 2%;
                    top: 0;
                }
            }
            &.last {
                &:before {
                    width: 94%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 2%;
                    top: 0;
                }
                .crf-s:after {
                    left: 92%;
                }
            }
            .ico-search:before {
                top: -5px;
                position: relative;
            }
            .crf-s {
                line-height: 63px;
                vertical-align: top;
             }
             .form-control {
                height: 60px;
                width: 100%;
                padding-left: 14px;
            }
            .crf-s {
                padding: 0;
                height: 60px;
            }
            .crf-s:before {
                left: 83.5%;
            }
           .crf-s:after {
                left: 93%;
            }
            &.buttons {
                .btn {
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.home-orange-boxes {
    .item {
        height: 387px;
        h3 {
            font-size: 40px;
            line-height: 38px;
            margin: 0;
            padding: 83px 0 22px;
        }
        p {
            padding: 0 20px 22px;
        }
    }
}
.home-people-intro {
    .about-content {
        padding: 97px 20px 120px;
        p {
            font-size: 18px;
            line-height: 30px;
            padding: 0 0 38px;
        }
        h3 {
            font-size: 26px;
            line-height: 32px;
            padding: 0 0 38px;
        }
    }
    .person-left {
        float: left;
        z-index: 2;
        width: 50%;
        .person-text {
            position: absolute;
            top: 250px;
            left: 0;
            width: 100%;
            h2 {
                width: 90%;
                padding-top: 38px;
                font-size: 26px;
                line-height: 28px;
                margin-bottom: 14px;
            }
            p {
                width: 93%; 
            }
	    h2, a {
		padding-right: 0;
	        float: none;
	    }
            a {
                padding-left: 18px;
            }
        }
        .person-image {
            float: right;
            right: -6px;
            img {
                height: 262px;
            }
        }
    }
    .person-right {
        .person-text {
            position: absolute;
            top: 250px;
            right: 0;
            width: 40%;
            h2 {
                padding-top: 38px;
                font-size: 26px;
                line-height: 28px;
            }
            a {
                padding-left: 18px;
            }
        }
        .person-image {
            img {
                height: 262px;
            }
        }
    }
    .person-year {
        z-index: 3;
        position: relative;
        height: 176px;
    }
}
.featured-wrapper {
    .properties {
        padding: 65px 0 19px;
        h2 {
            font-size: 46px;
            line-height: 38px;
            margin: 0 0 65px;
        }
    }
    &.featured-right {
        .properties {
            padding: 65px 0 19px;
        }
        .featured-container {
            padding-bottom: 0;
        }
    }
}
.instagram-wrapper {
    h2 {
        padding-top: 131px;
        font-size: 16px;
        line-height: 30px;
    }
    ul {
        margin-bottom: 49px;
    }
    #diamond-bg {
        width: 280px;
        height: 280px;
        -webkit-transform-origin: -3% 4%;
        -moz-transform-origin: -3% 4%;
        -ms-transform-origin: -3% 4%;
        -o-transform-origin: -3% 4%;
        transform-origin: -3% 4%;
    }
    .photo-wrapper {
        width: 100%;
        margin-bottom: 90px;
        float: left;
        .image-item {
            height: 180px;
            width: 173px;
            padding-left: 14px;
            margin-left: 6px;
        }
    }
}
.footer-heart {
    padding: 60px 0 44px;
    i {
        font-size: 90px;
    }
    p {
        font-size: 16px;
        line-height: 30px;
    }
}
.footer-bottom-nav {
    .container {
        width: 100%;
    }
    padding: 97px 0 91px !important;
    .contact-links {
        margin-bottom: 65px;
        h3 {
            font-size: 20px;
            line-height: 24px;

        }
        p {
            font-size: 18px;
            &.get-phone {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .contact-social {
            padding-top: 50px;
            ul {
                margin-bottom: 150px;
            }
        }
        .footer-button {
            width: 100%;
            float: left;
            padding: 23px 22px 19px;
            &:hover {
                width: 100%;
            }
            .button-title {
                font-size: 24px;
                line-height: 38px;
            }
            .button-link {
                padding-right: 0;
                p {
                    font-size: 14px;
                    line-height: 42px;
                }
            }
        }
    }
    .row {
        .bottom-nav-menu {
            padding-bottom: 40px;
            &:nth-child(1) {
                width: 100%;
            }
            &:nth-child(2) {
                width: 100%;
            }
            &:nth-child(3) {
                width: 100%;
            }
            &:nth-child(4) {
                width: 100%;
            }
            &:nth-child(5) {
                width: 100%;
            }
            &:nth-child(6) {
                width: 100%;
            }
        }
        ul {
            margin-top: 0 !important;
        }

    }
}
.footer-seo-links {
    padding: 50px 0;
    h3 {
        font-size: 26px;
        letter-spacing: 1px;
        line-height: 37px;

    }
    .seo-links {
        .content {
            padding-top: 15px;
            ul {
                text-align: center !important;
                padding-top: 15px;
                &:first-child {
                    padding-left: 0 !important;
                }
            }
        }
    }
}
.desktop-logo-text {
    display: none;
}
.mobile-logo-text {
    display: block;
    .footer-logo-text {
        padding-top: 0;
        padding-bottom: 50px;
        .footer-logo-image {
            width: 100%;
            text-align: left;
            a {
                margin-left: 15px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
.navbar-collapse {
    .footer-button {
        width: 100%;
    }
    .contact-social {
        margin-top: 35px;
        float: left;
        padding-bottom: 80px !important;
        left: -9px;
    }

}
.navbar-collapse {
     [class*="col-"]{
        padding : 0 20px;
    }

    .burger-close {
        margin: 25px 25px 43px;
    }
    .navbar-nav {
        padding: 0;
        margin-bottom: 40px;
        li {
            margin-left: 8px;
            .nav-child {
                li {
                    a {
                        padding: 5px 0 5px;
                    }
                }
            }
        }
    }
    .footer-button {
        width: 100%;
        margin: 10px 9px;
        padding: 22px 22px 20px;
        &:hover {
            width: 100%;
        }
        .button-link {
            padding-right: 0px;
        }
    }
}
.mobile-links {
    margin: 0 17px 0 0;
    padding: 21.5px 0 0 0;
  .ico-contacts {
      display: block;
      margin-left: 50px;
      text-align: right;
      width: 30%;
      margin-top: 0;
      position: absolute;
      right: 3%;
      &:before {
          font-size: 2em;
      }
  }

}

.featured-wrapper {
    .properties {
        padding: 65px 0 19px;
        h2 {
            font-size: 46px;
            line-height: 38px;
            margin: 0 0 65px;
        }
        &:hover {
           background-color: #43202d;
           .text-wrapper {
                background-color: #43202d;
            }
        }
    }
    .text-wrapper {
        padding: 18px;
        margin-top: 0;
        height: 315px;
    }
    .owl-dots {
        bottom: -7px !important;
        right: 0;
        left: 18px !important;
        margin-left: 0 !important;
    }
    &.featured-right {
        .featured-container {
            padding-bottom: 0;
        }
        .price,.propertytype, h3 {
            text-align: left !important;
        }
        .owl-dots {
            bottom: -7px !important;
            right: 0;
            left: 18px;
        }
    }
}
.mobile-links.contact-mobile {
    padding: 19px 0 0 0;
}
.mobile-links.contact-mobile:before {
    color: #f05323;
}
.top-search-wrapper {
        height: 488px;
        .search {
            width: 80%;
            padding-top: 30px;
        }
        i {
            position: inherit;
            font-size: 1em;
            margin-left: -3px !important;
        }
        .links {
            margin-top: 41px;
        }
        .form-group {
            height: 60px;
            width: 100% !important;
            margin-bottom: 0;
            &.first {
                &:before {
                    width: 94%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 2%;
                    top: 0;
                }
            }
            &.last {
                &:before {
                    width: 94%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 2%;
                    top: 0;
                }
                .crf-s:after {
                    left: 92%;
                }
            }
             .form-control {
                height: 60px;
                width: 100%;
            }
            .crf-s {
                padding: 0;
                height: 60px;
            }
            .crf-s:before {
                left: 83.5%;
            }
           .crf-s:after {
                left: 93%;
            }
            &.buttons {
                .btn {
                    width: 100% !important;
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
}
.navbar-collapse .contact-links a {
    font-size: 18px;
    line-height: 22px;
}

.valuation-form-main {
    .full {
            padding-top: 0px;
            padding-bottom: 10px;
    }
}
.sales-search .refine {
    text-decoration: none !important;
}
.form .set.property [class*=col-]:last-of-type img {
    margin-top: 12px;
}
.blog.guides.property-guides .guide {
    min-height: 255px;
}
.navbar-collapse .contact-links {
    left: -9px;
}
.navbar-collapse .nav-child li {
    margin-left: 0;
}
.home-slider-wrapper .owl-carousel {
    max-height: 390px;
}
.home-people-intro .person-right .person-text .team_member_title {
    padding-top: 14px;
    padding-bottom: 8px;
}
