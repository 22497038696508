// Similar properties module
// TODO: Reuse httpdocs/templates/starberry/less/slider.less:350
.properties {
  padding : 27px 0 19px;

  .slider {
	position : relative;
  }

  h2 {
	font-size     : 30px;
	color         : @purpledark;
	margin        : 0 0 16px;
	padding       : 0 0 13px;
	.nexa;
	border-bottom : 1px solid #a18f96;
	span {
	  color : @orange;
	}
  }
  .ribbon {
	left : 30px;
  }
  .slides {
	.clearfix;
  }
  .item {
	float          : left;
	width          : 258px;
	margin-right   : 14px;
	background     : @greylight;
	margin-top     : 4px;
	min-height     : 300px;
	padding-left   : 20px;
	padding-bottom : 31px;
	position       : relative;
	&:hover {
	  background : @greylesslight;
	}
	a {
	  &:hover {
		text-decoration : none;
	  }
	}
	h3 {
	  color       : @purpledark;
	  font-size   : @pbigger;
	  font-weight : bold;
	  line-height : 18px;
	  margin      : 0;
	  padding     : 10px 10px 7px 0;
	}
	.price,
	.price-text {
	  font-size      : @pbigger;
	  line-height    : 18px;
	  padding-bottom : 4px;
	  font-weight    : bold;
	  color          : @orange;
	}
	.btn {
	  bottom   : 34px;
	  padding  : 0 15px;
	  position : absolute;
	}
  }
}

.results-total {
  display : block;
}

.startek-results {
  .rooms {
	display : block;
  }
}