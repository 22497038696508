.currencies {
  display : inline-block;
}

a.currency {
  .tk-icon-font;
  color          : @greymedium;
  font-size      : 1.5em;
  margin         : 0;
  vertical-align : middle;
  &.active {
	color : @orange;
  }
  i {
	&:before {
	  margin-left : 0;
	}
  }
}

.sales-search {
  .currencies {
	margin-top  : 4px;
	margin-left : @p;
  }
}