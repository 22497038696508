.pagination {
  ul {
	padding-left : 0;
	li {
	  float           : left;
	  list-style-type : none;
	  padding-right   : 1em;
	  &.pagination-next {
		padding-right : 3px;
	  }
	}
  }
  .pagination-start,
  .pagination-end {
	display : none;
  }
}

.sales-search {
  h1 {
	margin      : 0 0 32px;
	color       : @purpledark;
	.nexa;
	font-size   : 30px;
	line-height : normal;
  }
  .toolbar {
	background    : @greylight;
	color         : @greymiddle;
	font-size     : 13px;
	margin-bottom : 30px;
	margin-left   : 0;
	margin-right  : 0;
	line-height   : 33px;
	padding       : 0 22px;
	.view {
	  float : left;
	  p {
		margin-top    : 5px;
		margin-bottom : 0;
	  }
	  .ico-list {
		margin     : 9px 0 0 13px;
		float      : left;
		width      : 23px;
		height     : 23px;
		background : url(../images/ico_filter.png) no-repeat;
		&.active {
		  background-position : 0 100%;
		}
	  }
	  .ico-grid {
		margin     : 9px 0 0 10px;
		float      : left;
		width      : 23px;
		height     : 23px;
		background : url(../images/ico_filter.png) no-repeat -34px 0;
		&.active {
		  background-position : -34px 100%;
		}
	  }
	  .ico-map {
		margin     : 9px 0 0 10px;
		float      : left;
		width      : 15px;
		height     : 23px;
		background : url(../images/ico_filter.png) no-repeat 100% 0;
		&.active {
		  background-position : 100% 100%;
		}
	  }
	}
	.order {
	  float   : left;
	  padding : 10px 0 0 40px;
	  .crf-s {
		height      : 20px;
		line-height : 21px;
		font-size   : 13px;
		width       : 147px;
		padding     : 0 5px;
		&:before {
		  position     : absolute;
		  top          : 6px;
		  right        : 6px;
		  width        : 0;
		  height       : 0;
		  border-style : solid;
		  border-width : 0 3px 3px 3px;
		  border-color : transparent transparent @greymiddle transparent;
		  content      : "";
		}
		&:after {
		  position     : absolute;
		  top          : 11px;
		  right        : 6px;
		  width        : 0;
		  height       : 0;
		  border-style : solid;
		  border-width : 3px 3px 0 3px;
		  border-color : @greymiddle transparent transparent transparent;
		  content      : "";
		}
	  }
	}
	.pagination {
	  float   : right;
	  padding : 13px 0;
	  margin  : 0 -6px 0 0;
	}
	p {
	  float : left;
	}
  }
  section {
	margin-bottom : 26px;
  }
  article {
	background    : @greylight;
	margin-bottom : 15px;
	margin-right  : -6px;
	padding       : 20px 20px 39px;
	.clearfix;
	&:hover {
	  background : @greylesslight;
	}
	.specs {
	  text-align : left;
	  margin     : 15px 0 40px;
	}
	.btn a {
	  padding      : 0 14px;
	  margin-right : 10px;
	  float        : left;
	}
	.price,
	.price-text {
	  color       : @orange;
	  font-size   : 24px;
	  font-weight : bold;
	  padding     : 7px 0 20px;
	}
	h2 {
	  color       : @purpledark;
	  font-size   : @h3;
	  margin      : -7px 0 5px;
	  line-height : normal;
	  font-weight : bold;
	  a {
		color : @purpledark;
	  }
	}
	.image {
	  position     : relative;
	  float        : right;
	  margin       : -20px;
	  padding-left : 0;
	}
  }
  .sidebar.inset {
	top : 0;
  }
}

.search-form {
  background    : @greylight;
  text-align    : center;
  padding       : 20px 20px 40px;
  margin-bottom : 30px;
  h3 {
	text-align     : center;
	color          : @purpledark;
	font-size      : @pbig2;
	font-weight    : normal;
	margin         : 0;
	padding-bottom : 13px;
  }
  fieldset {
	margin     : 0 0 3px;
	text-align : left;
  }
  .btn {
	margin-top : 25px;
	padding    : 0 22px;
  }
  .include {
	text-align : left;
	padding    : 15px 0 0 0;
	overflow   : hidden;
	h6 {
	  display       : inline-block;
	  color         : @greymiddle;
	  font-size     : 12px;
	  padding-right : 8px;
	}
	label {
	  display     : inline-block;
	  color       : @greymiddle;
	  font-size   : 12px;
	  position    : relative;
	  cursor      : pointer;
	  line-height : 15px;
	  margin      : 0 10px 0 0;
	  &:before {
		margin         : -3px 8px 0 0;
		vertical-align : top;
		position       : relative;
		content        : "";
		display        : inline-block;
		width          : 18px;
		height         : 18px;
		background     : @white;
	  }
	  &.checked:after {
		.tk-icon-font;
		content  : '\e80d';
		color    : @orange;
		position : absolute;
		left     : 1px;
		top      : 0;
	  }
	}
  }

  .crf-s {
	height        : 24px;
	line-height   : @pbig2-lh;
	margin-bottom : 0;
	display       : block;
	&.half {
	  float : left;
	  width : 47.5%;
	  &:last-child {
		float : right;
	  }
	}
	&:before {
	  position     : absolute;
	  top          : 7px;
	  right        : 10px;
	  width        : 0;
	  height       : 0;
	  border-style : solid;
	  border-width : 0 4px 4px 4px;
	  border-color : transparent transparent @greymiddle transparent;
	  content      : "";
	}
	&:after {
	  position     : absolute;
	  top          : 14px;
	  right        : 10px;
	  width        : 0;
	  height       : 0;
	  border-style : solid;
	  border-width : 4px 4px 0 4px;
	  border-color : @greymiddle transparent transparent transparent;
	  content      : "";
	}
  }
  label {
	font-weight : normal;
	display     : block;
	margin      : 0;
	padding     : 0;
	color       : @greymiddle;
	font-size   : 12px;
  }
}

.pagination {
  color       : @greymiddle;
  line-height : 17px;
  margin      : 11px 0 40px 0;
  overflow    : hidden;
  a {
	float     : left;
	color     : @orange;
	font-size : 13px;
	&.disabled {
	  color : @greymiddle;
	}
  }
  p {
	margin      : 0;
	padding     : 0 16px;
	color       : @greymiddle;
	font-size   : 13px;
	float       : left;
	line-height : 17px;
	&.disabled:first-of-type {
	  padding-left : 0;
	}
  }
  p + p {
	padding-left : 0;
  }
  .icon-tk-lrg_arrow_left {
	&:before {
	  margin-left : -2px;
	}
  }
}

.sales-search {
  &.grid-style {
	section {
	  margin-left   : -15px;
	  margin-right  : -15px;
	  margin-bottom : 11px;
	}
	.address {
	  span {
		display : block;
	  }
	}
	.image {
	  float : none;
	}
	img {
	  max-width : 100%;
	}
	.specs {
	  margin : -15px 0 1px;
	}
	h2 {
	  font-size   : @pbigger;
	  line-height : 18px;
	  margin-top  : 0;
	  min-height  : 45px;
	  text-align  : left;
	}
	.price,
	.price-text {
	  font-size     : @pbigger;
	  line-height   : normal;
	  margin-top    : -7px;
	  margin-bottom : 0px;
	}
	.price-text {
	  display : block;
	  padding : 7px 0 0;
	  &:last-of-type {
		padding-bottom : 20px;
	  }
	}
	.btn span {
	  display : none;
	}
	article .image {
	  margin-left   : 0;
	  margin-bottom : 10px;
	}
	article {
	  display        : inline-block;
	  vertical-align : top;
	  width          : 259px;
	  margin-bottom  : 15px;
	  padding-left   : 20px;
	  padding-bottom : 37px;
	  position       : relative;
	  min-height     : 398px;
	  margin-left    : 15px;
	}
	.buttons {
	  bottom        : 47px;
	  margin-bottom : 0;
	  position      : absolute;
	}
	.specs {
	  text-align : left;
	  float      : none;
	  margin     : 0;
	  padding    : 0 0 13px;
	  .ico-bed,
	  .ico-bath,
	  .ico-living {
		background-size : 108px auto;
		width           : 36px;
		margin          : 10px 0 0 1px;
		height          : 18px;
	  }
	  .count {
		font-size   : @p;
		line-height : 18px;
		padding     : 0;
		margin      : 0 8px 0 2px;
	  }
	}
  }
}

.search-wrapper {
  background : @orange;
  display    : none;
  padding    : 0;
  position   : absolute;
  top        : 130px;
  width      : 100%;
  z-index    : 2;
  .search {
	max-width : none;
  }
  form {
	padding : 15px 0 5px;
  }
  .refine {
	padding : 15px 0 0 0;
	top     : 0;
	fieldset {
	  position : relative;
	  top      : 4px;
	}
  }
  h3 {
	float       : left;
	padding     : 0 @p @p 0;
	line-height : 30px;
	margin      : 0;
	font-size   : @pbig2;
	font-weight : normal;
	text-align  : center;
	color       : @white;
  }
  .form-group {
	// This really should be display: inline-block, but that change could break things elsewhere. Need to investigate
	float    : left;
	margin   : 0;
	position : relative;
  }
  .form-control,
  .crf-s {
	margin-right : 10px;
	width        : 213px;
  }
  .buttons {
	float    : right;
	padding  : 0;
	margin   : 0 0 0 -20px;
	position : relative;
	.div {
	  position    : absolute;
	  left        : 50%;
	  width       : 1px;
	  margin-left : 11px;
	  background  : @orangelight;
	  top         : 1px;
	  bottom      : 1px;
	}
	.btn {
	  float       : left;
	  margin-left : 24px;
	  height      : 30px;
	  line-height : 28px;
	  font-size   : @p;
	  &:last-child {
		float : right;
	  }
	}
  }
}

.no-results {
  background    : @greymedium;
  margin-bottom : 2em;
  padding       : 1.5em 2.5em;
  @media @tablet-xl{
	padding-bottom : 2.5em;
  }
  h3 {
	color         : @greymiddle;
	font-size     : 1.25em;
	margin-bottom : .25em;
  }
  &.square {
	&:before {
	  right        : 1.5em;
	  bottom       : 1em;
	  border-color : @white;
	}
	&:after {
	  left         : 1.5em;
	  top          : 1em;
	  border-color : @white;
	}
  }
}

// Refined refine search radio button styles
.refine {
  position : relative;
  top      : 4px;

  input[type="radio"] + label {
	color       : @offwhite;
	cursor      : pointer;
	display     : inline-block;
	font-size   : @p;
	font-weight : normal;
	line-height : 1em;
	margin      : 0 10px 0 0;
	position    : relative;
	&.checked {
	  color : @white;
	}
	&:before {
	  background     : @orange;
	  border         : 1px solid @white;
	  border-radius  : 8px;
	  content        : "";
	  display        : inline-block;
	  height         : 15px;
	  margin         : -3px 4px 0 0;
	  position       : relative;
	  top            : 2px;
	  vertical-align : top;
	  width          : 15px;
	}
	&.checked:before {
	  background : @white;
	}
	&.checked:after {
	  content       : "";
	  position      : absolute;
	  left          : 4px;
	  top           : 3px;
	  width         : 7px;
	  height        : 7px;
	  background    : @orange;
	  border-radius : 50%;
	}
  }
}

.home {
  .search {
	overflow   : visible;
	text-align : center;
	max-width  : 747px;
	margin : 0 auto;
	form {
	  padding : 20px;
	  margin  : 0;
	  .clearfix;
	  &[data-form="rent"] {
		display : none;
	  }
	}
	.refine {
	  text-align : left;
	}
	.buttons {
	  margin  : 0;
	  padding : 0;
	  .btn,
	  .btn:last-child {
		display : block;
		float   : none;
		width   : 100%;
	  }
	}
  }
}

form[data-form="rent"] {
  display : none;
}

.sidebar {
  .search {
	form {
	  margin : 0 15px;
	}
	.btn {
	  margin : 15px 0 30px;
	  width  : 100%
	}
	.refine {
	  color         : @white;
	  margin-bottom : @p;
	  text-align    : center;
	  h3 {
		color       : @white;
		margin      : 0 0 8px;
		padding-top : 0;
	  }
	}
  }
}

.search {
  .form-group {
	position : relative;
  }
}

@media @tablet-xl {
  .home {
	.search {
	  margin-top : 0;
	  max-width  : none;
	  .refine {
		text-align : center;
	  }
	  .buttons {
		.btn,
		.btn:last-child {
		  display : block;
		  float   : none;
		  margin  : 0 auto;
		  width   : auto;
		}
	  }
	}
  }

  .search {
	margin-top : 0;
	max-width  : none;

	.refine {
	  text-align : center;
	}
	.buttons {
	  .btn,
	  .btn:last-child {
		display : block;
		float   : none !important;
		margin  : 0 auto;
		width   : auto;
	  }
	}
  }
}

@media @mobile{
  .sales-search {
	.address {
	  span {
		display : block;
	  }
	  .price-text {
		display : block;
		padding : 7px 0 0;
		&:last-of-type {
		  padding-bottom : 20px;
		}
	  }
	}
  }
}

.price-wrapper {
  min-height : 60px;
}
