@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(0); 
  }
  50% {
    -webkit-transform: translateY(-50%); 
  }
}
.home-team-btn {
    padding: 0 0 65px 0;
   .btn-primary {
    background-color: #F05323 !important;
    color:#fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 31px 148px;
    transition: all .4s ease;
    font-family: NexaBold;
    text-decoration: none;
    &:hover {
      transition: all .4s ease;
      padding: 31px 168px;
      text-decoration: none;
     }
    }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0); 
  }
  50% {
    transform: translateY(-50%); 
  }
}
.home {
  .container {
     padding-right: 18px;
     padding-left: 18px;
  }
  .mobile-links {
      .ico-search {
          display: none;
      }
  }
  .ico-down {
      font-size: 30px;
      animation: bounce 1.3s infinite;
      color: #fff;
  }
  .area-guide {
	background      : url('../../../images/TK_Historical_Image_Montage.jpg') no-repeat center center;
	background-size : cover;
	margin-top      : @h3*2;
	text-align      : center;
	h2, h3 {
	  color : @white;
	}
	h2 {
	  font-size      : 4em;
	  text-transform : uppercase;
	  margin         : 0;
	  padding        : 1em 0 .75em;
	}
	h3 {
	  font-family : 'AvenirLTStd';
	  font-weight : bold;
	  max-width   : 70%;
	  margin      : 0 auto @h3*3 auto;
	  line-height : 1.5em;
	}
	i {
	  color      : @orange;
	  font-size  : 1.25em;
	  vert-align : middle;
	}
	.btn {
	  margin-bottom : @h3*4;
	  width         : 245px;
	}
  }
  .news {
	.ribbon {
	  &.horizontal {
		&.grey {
		  &:before {
			border-width : 30px;
			right        : -60px;
		  }
		  @media @tablet-xl {
			&:before {
			  border-width : 20px;
			  right        : -40px;
			}
		  }
		}
	  }
	}
  }
}

@media @tablet-xl {
  .area-guide {
	margin-bottom : @h3*2;
  }
}

.ico-down {
  display    : block;
  font-size  : 2em;
  margin     : 30px auto 108px;
  text-align : center;
}
.welcome {
  &.featured {
	padding    : 21px 15px 10px;
	margin     : 0 15px;
	background : none;
	.sidebar {
	  top : 0;
	}
  }
  .clearfix;
  .play {
	margin-top      : 10px;
	float           : right;
	position        : relative;
	text-decoration : none;
	&:before {
	  .tk-icon-font;
	  color     : @orange;
	  content   : '\e810';
	  position  : absolute;
	  left      : 50%;
	  top       : 50%;
	  width     : 78px;
	  height    : 78px;
	  margin    : -39px;
	  font-size : 4.35em;
	}
	&:hover {
	  &:before {
		opacity : .5;
	  }
	}
  }
  &.featured .btn {
      margin: 85px 0 0;
      color: #F05323;
      font-size: 14px;
      line-height: 30px;
      font-family: "NexaBold";
      background: none;
      border: none;
      height: 30px;
      padding: 0;
      text-transform: uppercase;
      &:hover {
          background-image: none;
      }
      .icon-tk-lrg_arrow_right {
          &:before {
              font-weight: 600;
              font-size: 16px;
              margin-left: 10px;
              top: 1px;
              position: relative;
          }
      }
  }
}
.split-module-group {
   [class*="col-"]{
        padding : 0;
  }
}
.home-video {
   [class*="col-"]{
        padding : 0;
  }
  .video-wrapper {
      top: 0;
  }
}
.home-orange-boxes {
    .item {
        height: 500px;
        background  : @orange;
        text-align  : center;
        font-size   : @pbig2;
        line-height : @pbig2-lh;
        color       : @white;
        &:hover {
          background : #E04E25;
        }
        &.first {
            p {
                padding: 0 23% 57px;
            }
        }
        &.last {
            p {
                padding: 0 14% 57px;
            }
        }
        h3 {
            color: #FFFFFF;
            font-size: 44px;
            line-height: 38px;
            text-align: center;
            padding: 122px 0 22px;
            margin-bottom: 0;
            font-family: "NexaBold";
        }
        
        p {
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            text-align: center;
            padding: 0 0 57px;
            margin-bottom: 0;
        }
        .btn {
            height: 80px;
            width: 280px;
	    text-transform: uppercase;
            color: #F05323;
            background-color: #532A3A;
            background-image: none;
            padding: 25px 80px;
            line-height: 26px;
	    font-size: 14px;
            font-family: "NexaBold";
            transition: all .4s ease;
            &:hover {
                width: 320px;
                transition: all .4s ease;
            }
        }
    }
}

.orange-boxes {
  padding : 49px 0;
  .col-md-6:last-child {
	.item {
	  margin-right : 0;
	}
  }
  .item {
	background  : @orange;
	text-align  : center;
	font-size   : @pbig2;
	padding     : 20px 0 25px;
	line-height : @pbig2-lh;
	color       : @white;
	&:hover {
	  background : @orange90;
	}
	h3 {
          color     : @purpledark;
          margin    : 0 0 15px;
          font-size : 30px;
	  .nexa;
	}
	p {
	  font-size : 1em;
	  margin    : 0 0 17px;
	}
	.btn {
	  padding    : 0 40px;
	  min-width  : 218px;
	  text-align : center;
	}
	&.first {
	  margin-right : -8px;
	}
	&.last {
	  margin-left : -8px;
	}
  }
}
.home-people-intro {
   [class*="col-"]{
        padding : 0;
    }
    .about-content {
      background-color: #532A3A;
      padding: 171px 0 0;
      padding-bottom:5% !important;
      p{
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 300;
        line-height: 38px;
        text-align: center;
        padding: 0 26% 35px;
        margin: 0;
      }
      h3 {
        color: #F05323;
        font-family: nexa;
        font-size: 44px;
        line-height: 56px;
        text-align: center;
        padding: 0 24% 41px;
        margin: 0;
      }
    }
    @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait) {
	.person-wrap {
	    text-align: center;
	    .person-left .person-image {
		right: -21px;
            }
        }
    }
    .person-left {
        .person-text {
	    @media (min-width: 1200px) {
	        position: relative;
	        top: -25px;
	        left: 25px;
	    }
            text-align: center;
            h2 {
	        color: #FFFFFF;
	        font-family: "NexaBold";
                font-size: 28px;
                opacity: 0.5;
                line-height: 30px;
                text-align: center;
                padding-top: 358px;
                margin-bottom: 0px;
            }
            p {
                padding: 0;
            }
            .team_member_title {
                color: #fff;
                font-family: "NexaBold";
                font-size: 14px;
                opacity: 0.5;
                text-align: center;
                   @media screen and (max-width: 767px) {
                      width: 100% !important;
                      padding-left: 0px !important;
                      padding-right: 10px !important;
                      opacity: 1;
                      line-height: 26px;
		      span {
			    margin-left: 50px;
		      }
                   }
                span {
                   color: #fff;
                   text-align: center;
                }
            }
            a {
                color: #F05323;
                font-family: "NexaBold";
                font-size: 14px;
                opacity: 0.5;
                line-height: 30px;
                text-transform: uppercase;
                @media screen and (max-width: 767px) {
                      
                        position: relative;
                        left: 23px;
                     
		}
                .icon-tk-lrg_arrow_right {
                    top: 1px;
                    position: relative;
                    &:before{
                        font-size: 1.2em;
                    }
                }
            }
	    a {
		padding-right: 45px;
     	        float: right;
                text-align: center;
                width: 83%;
	    }
        }
        .person-image {
            position: relative;
            right: -21px;
            img {
                width: auto;
                height: 484px;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
        }
        &:hover {
            .person-text {
                h2 {
                    opacity: 1;
                }
                a {
                    opacity: 1;
                }
                .team_member_title {
                    opacity: 1;
                }
            }
            .person-image {
                img {
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                }
            }
        }
    }
    .person-right {
        .person-text {
            text-align: center;
            padding-right: 70px;
            @media (min-width: 1200px) {
		top: -25px;
		a {
   		    margin-left: 14px;
		}
	    }
            h2 {
                color: #FFFFFF;
                font-family: "NexaBold";
                font-size: 28px;
                opacity: 0.5;
                line-height: 30px;
                text-align: center;
                padding-top: 358px;
                margin-bottom: 0px;
            }
            p {
                padding: 0;
            }
            .team_member_title {
                color: #fff;
                font-family: "NexaBold";
                font-size: 14px;
                opacity: 0.5;
                text-align: center;
                   @media screen and (max-width: 767px) {
                      width: 100% !important;
                      padding-left: 10px !important;
                      opacity: 1;
                   }
                span {
                   @media screen and (max-width: 767px) {
                       top:0px;
		       margin-left: -8px;
                   }
                   color: #fff;
                   position: relative;
                   top:0px;
                }
            }
            .team_member_chat_content {
              a {
                position: relative;
                bottom:8px;
              }
            }
            a {
                @media screen and (max-width: 767px) {
                            position: relative;
                            left: 9px;
                }

                color: #F05323;
                font-family: "NexaBold";
                opacity: 0.5;
                font-size: 14px;
                line-height: 30px;
                text-transform: uppercase;
                .icon-tk-lrg_arrow_right {
                    top: 1px;
                    position: relative;
                    &:before{
                        font-size: 1.2em;
                    }
                }
            }
        }
        .person-image {
            position: relative;
            left: -1px;
            img {
                width: auto;
                height: 484px;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
            }
        }
        &:hover {
            .person-text {
                h2 {
                    opacity: 1;
                }
                a {
                    opacity: 1;
                }
                .team_member_title {
                    opacity: 1;
                }
            }
            .person-image {
                img {
                    -webkit-filter: grayscale(0%);
                    filter: grayscale(0%);
                }
            }
        }
    }
    .person-year {
        width: 86%;
        margin: 0 auto;
        border-top: 2px solid #F05323;
        text-align: center;
        img {
            position: relative;
            top: -90px;
            width: 178px;
        }
    }
}
.instagram-wrapper {
    .diamond {
        margin-bottom: 120px;
        background      : url('../../../images/social-icon-background.svg') no-repeat center center;
        background-size : contain;
        float: left;
        margin-top: 89px;
	@media (min-width: 1025px) {
	    height: 911px;
	}
    }
    #diamond-bg {
        background      : url('../../../images/social-icon-background.png') no-repeat center center;
        background-size : cover;
    }
    h2 {
	color: #532A3A;
	font-family: "NexaBold";
	font-size: 16px;
	line-height: 30px;
	text-align: center;
        font-weight: 700;
        padding-top: 123px;
        margin: 0;
        margin-bottom: 32px;
        position: relative;
        z-index: 2;
    }
    ul {
        list-style: none;
        text-align: center;
        padding: 0;
        margin-bottom: 88px;
        position: relative;
        z-index: 2;
        li {
            display: inline-block;
            margin-left: 26px;
            &:first-child {
                margin-left: 0;
            }
            a {
                line-height: 0;
                font-size: 28px;
                color: #fff;
                transition: all .4s ease;
                &:hover {
                    opacity: 0.5;
                    transition: all .4s ease;
		    text-decoration: none;
                }
            }
        }
    }
    .photo-wrapper {
        width: 1094px;
        margin: 0 auto;
        .image-item {
            display: inline-block;
            vertical-align: top;
            width: 263px;
            height: 263px;
            position: relative;
            float: left;
            padding-left: 10px;
            background-size: cover;
            margin: 5px 5px;
	    img {
	        display: none;
	    }
	    @media only screen and (min-device-width : 414px) and (max-device-width : 780px) and (orientation : landscape)  {
	        width: 340px !important;
            height: 340px !important;
	    }
	    @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait) {
	        width: 182px !important;
            height: 182px !important;	       
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation : landscape) { 
            width: 307px !important;
            height: 307px !important;   
        }        

        }
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
    .instagram-wrapper .photo-wrapper .image-item {
        width: 135px !important;
        height: 135px !important;
    }
}
@media only screen and (min-device-width : 360px) and (max-device-width : 640px){
    .instagram-wrapper .photo-wrapper .image-item {
	width: 155px;
        height: 155px;
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape) {
    .instagram-wrapper .photo-wrapper .image-item {
        width: 258px !important;
        height: 258px !important;
    }
}
.featured-wrapper {
     margin: 0;
    .featured-container {
        background-color: #532A3A;
    }
    .owl-carousel:hover {
        background-color: transparent;
    }
    .image-wrapper {
        margin-top: 4px;
    }
    &.featured-right {
        .featured-container {
            padding-bottom: 142px;
        }
        .properties {
            padding: 60px 0 19px;
        }
        .image-wrapper {
            float: right;
        }
	.text-wrapper {
	    .propertytype, h3, .price {
		text-align: right;
                padding-right: 0 !important;
	    }
	}
	.ImageSliderOuter.featured {
	    .ribbon {
		left: 30px;	
	    }
	}
    }
    [class*=col-] {
        padding: 0;
    }
    .text-wrapper {
        padding: 38px;
        height: 442px;
        margin-top: 4px;
    }
    .properties {
        padding: 90px 0 19px;
    }
    h2 {
	opacity: 0.25;
	color: #FFFFFF;
	font-family: "nexa";
	font-size: 64px;
	line-height: 56px;
	text-align: center;
        border-bottom: none;
        padding: 0;
        margin: 0 0 63px;
        span {
            color: #FFFFFF;
        }
    }
    .ImageSliderOuter {
        &.featured {
            .slide-item {
                background: #532A3A;
                padding: 0;
                border-right: none;
                img {
                    height: 362px;
                    width: 100%;
                }
                .propertytype {
                    color: #F05323;
                    font-family: "NexaBold";
                    font-size: 14px;
                    line-height: 30px;

                }
                h3 {
	            color: #FFFFFF;
                    font-family: "nexa";
                    font-size: 32px;
                    line-height: 32px;
                    padding: 5px 10px 0 0;
                    font-weight: 400;
                }
                .price{
                    color: #F05323;
                    font-family: "NexaBold";
                    font-size: 32px;
                    line-height: 44px;
                }
                .price-text{
                    color: #F05323;
                    font-family: "NexaBold";
                    font-size: 32px;
                    line-height: 44px;
                }
                &:hover {
                    .text-wrapper {
                        background-color: #43202d;
                    }
                }


            }
            .ribbon-image {
                width: 100%;
                position: absolute;
                top: -4px;
            }
            .ribbon {
                left: 100%;
                position: initial;
                float: right;
                margin-right: 18px;
                text-indent: -99999px;
            }
        }
    }
    &.featured-right { 
         .ribbon {
             float: left !important;
             margin-left: 18px;
        }
    }
}
.home-top-banner {
    position: absolute;
    width: 100%;
    top: 230px;
    p {
        color: #F05323;
        font-family: "nexa";
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        position: relative;
        z-index: 3;
        &.title-text {
            color: #FFFFFF;
            padding-top: 56px;
	    margin-bottom: 0px;
        }
    }
    .title-text + p {
	margin-bottom: 28px;
    }
}
.home-banner-search {
    position: relative;
    z-index: 9;
    form {
        padding: 0 !important;
    }
    .search-button-type {
        label {
            width: 107px;
            height: 40px;
            color: #ffffff;
            background-color: #532A3A;
            font-family: "NexaBold";
            text-transform: uppercase;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            margin-bottom: 0;
            margin-right: -3px;
            top: 5px;
            opacity: 0.3;
            position: relative;
            transition: all .4s ease;
            &.checked {
                background-color: #F05323;
                height: 40px;
                line-height: 40px;
                top: 0px;
                opacity: 1;
                transition: all .4s ease;
            }
        }
        label.crf:hover {
            height: 40px;
            line-height: 40px;
            top: 0px;
            opacity: 1;
            transition: all .4s ease;
        }
    }
    .search {
	.links {
	    .btn-primary {
		font-size: 14px;
                text-transform: uppercase;
	    }
	}
	.wrapper {
	    margin-bottom: 10px;
	}
    }
    .form-group {
        float: left;
        width: 268px;
        height: 70px;
        background-color: #fff;
        margin-bottom: 0;
        &.first {
            width: 203px;
            padding-left: 14px;
            &:before {
                content: " ";
                box-sizing: border-box;
                height: 45px;
                width: 3px;
                border-left: 1px solid #532A3A;
                opacity: 0.2;
                position: absolute;
                z-index: 9;
                left: 0;
                top: 13px;
            }
        }
        &.last {
            width: 205px;
            padding-left: 14px;
            &:before {
                content: " ";
                box-sizing: border-box;
                height: 45px;
                width: 3px;
                border-left: 1px solid #532A3A;
                opacity: 0.2;
                position: absolute;
                z-index: 9;
                left: 0;
                top: 13px;
            }
        }
        &.buttons {
            width: 70px;
            height: 70px;
            padding-top: 0!important;
            .btn {
                background: #F05323;
                height: 70px;
                border: none;
            }
        }
        .form-control {
            height: 70px;
	    font-size: 16px;
	    color: #9B9B9B;
	    font-weight: 300;
        }
        .crf-s {
            height: 70px;
            line-height: 73px;
            margin-bottom: 0;
            padding: 0 0 0 25px;
            font-size: 16px;
            color: #9B9B9B;
            font-weight: 300;
            &:before {
                top: 26px;
                left: -31px;
	        transform: rotate(270deg);
            }
            &:after {
                top: -2px;
                left: 0;
            }
            &:before{
                display: none;
                height: 0;
                width: 0;
                border: none;
                font-style: normal;
                font-family: tk-icon-font!important;
                content: '\e832';
            }
	    &:after {
		height: 6.04px;
		width: 11.09px;
		border: none;
		font-style: normal;
		font-family: tk-icon-font!important;
		content: '\e832';
	    }
        }
        .ico-search {
          &:before {
              content : '\e831';
              font-family: tk-icon-font!important;
              font-style: normal;
              font-weight: 400;
              speak: none;
              display: inline-block;
              text-decoration: inherit;
              width: 1em;
              margin-right: .2em;
              text-align: center;
              font-variant: normal;
              text-transform: none;
              line-height: 2em;
              margin-left: .2em;
              color: #FFFFFF;
              font-size: 1.3em;
          }
        }
    }
}
.home-top-slider {
    .top-pagination {
        display: none;
    }
    .ImageSliderOuter.featured {
        padding: 0;
        .slide {
            height: 388px;
            background-position: center;
            background-size: cover;
            margin-top: 0;
            border-right: none;
            padding: 0;
            &:before {
                content: '';
                width: 100%;
                height: 458px;
                transform: rotate(180deg);
                opacity: 0.85;
	        background: linear-gradient(180deg,rgb(83, 42, 58) 0,transparent 100%);
                top: 0;
                right: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
            }
            p {
            color: #F05323;
            font-family: "nexa";
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            position: relative;
            z-index: 3;
            &.title-text {
                color: #FFFFFF;
                padding-top: 56px;
                margin-bottom: 0px;
            }
        }
        .title-text + p {
            margin-bottom: 28px;
        }

        }
    }
}
.navbar-collapse {
    .contact-links {
	position: relative;
        left: 5px;
	a {
	    line-height: 20px;
	}
    }
}
.img-responsive.prop-bg {
    background-size: cover !important;
    height: 442px;
    width: 100%;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    @media screen and (max-width: 767px) {
	height: 184px;
    }
}
.home-top-slider .ImageSliderOuter.featured .slider {
    width: auto;
    .slide {
	width: 0;
    }
    .slide.firstslide-0 {
	width: 100%;
    }
}
.home-banner-search {
    .second-load-search {
	display: none;
    }
}
#area-guide-enclose {
    position: relative;
}
.home-area-guide-orange {
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-image: url(/images/areaguide/TK_Area_Guides_Map_Image_Full.png),linear-gradient(#532A3A 10.33%,#f05323 9.33%,#f05323 89.66%,#fff 89.66%);
    background-position: bottom;
    &:before {
        content: "";
        background: #532A3A;
        width: 80px;
        height: 87px;
        display: block;
        @media (max-width: 767px) {
            width: 25px;
        }
	@media (min-width: 1600px) {
           height: 87.5px;
	}
	@media (min-width: 2400px) {
    	    height: 87px;
	}
        @media screen and (max-width: 1980px) and (min-width: 1000px) {
           height: 87px;
        }


    }
    &:after {
	content: "";
        background: #fff;
        display: block;
        width: 80px;
        height: 87px;
	@media (max-width: 767px) {
            width: 25px;
        }
	@media (min-width: 2400px) {
           height: 87px;
	}
	@media screen and (max-width: 1980px) and (min-width: 1000px) {
           height: 87px;
        }

    }
    @media (max-width: 900px) {
        background-size: inherit;
    }
    .icon-tk-lrg_arrow_right {
        &:before {
            font-size: 20px;
	    vertical-align: text-bottom;
	}
    }
    .area-guide-logo {
	        text-align: center;
       	        margin-top: 10px;
    }
    .top-blendid {
	height: 80px;
        background-color: #532a3a;
        background-blend-mode: multiply;
	background-size: cover;
        background-position: 77px -79px;
        background-repeat: no-repeat;
    }
    .bottom-blendid {
	height: 80px;
        background-color: #fff;
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-position: 80px bottom;;
	background-size: cover;
    }
    .item {
        height: 668px;
	position: relative;
        font-size   : @pbig2;
        line-height : @pbig2-lh;
        color       : @white;
	padding: 150px 80px;
	&:before {
	    content: "";
            background: #f05323;
            width: 80px;
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
	    left: 0;
	    @media (max-width: 767px) {
		width: 25px;
	    }
	}
	@media (max-width: 767px) {
	    padding: 50px 0 100px;
	}
	@media only screen and (min-device-width: 300px) and (max-device-width: 767px) and (orientation : landscape) {
	    padding: 80px 0 100px;
	}
	@media (min-width: 768px) and (max-width: 998px){
            padding: 100px 0 100px;
        }
        .area-guide-logo {
	    img {
	        width: 350px;
		@media (max-width: 998px) {
                    width: 200px;
		}
            }
        }
	.item-image {
            padding-right: 145px;
	    text-align: right;
	    @media (max-width: 998px) {
                text-align: center;
                padding: 0;
	    }
	    @media (min-width: 1024px) and (max-width: 1200px){
                padding-right: 100px;
                padding-top: 36px;
	    }
        }
	.item-content {
            padding: 40px 165px 0px 0;
	    @media (max-width: 998px) {
	        padding: 40px 0 0;
		div {
                    text-align: center;
		}
	    }
	    @media (min-width: 1024px) and (max-width: 1200px){
	        padding: 60px 75px 0 0;
	    }
	}
        p {
            color: #FFFFFF;
            font-size: 22px;
            font-weight: 300;
            line-height: 38px;
            text-align: left;
            margin-bottom: 0;
	    @media (max-width: 767px) {
	        font-size: 18px;
                line-height: 30px;
                padding: 0 12%;
            }
	    @media only screen and (min-device-width: 300px) and (max-device-width: 767px) and (orientation : landscape) {
                width: 575px;
	        text-align: center;
	        margin: 0 auto;	 
	    }

	    @media (min-width: 768px) and (max-width: 998px){
                font-size: 18px;
                line-height: 30px;
		width: 575px;
		text-align: center;
                margin: 0 auto;
            }
        }
        .btn {
            height: 80px;
            width: 296px;
            text-transform: uppercase;
            color: #F05323;
            background-color: #532A3A;
            background-image: none;
            padding: 25px 80px;
	    margin: 55px 0 0;
            line-height: 26px;
            font-size: 14px;
            font-family: "NexaBold";
            transition: all .4s ease;
            &:hover {
                width: 320px;
                transition: all .4s ease;
            }
	    @media (max-width: 998px) {
                width: 280px;
		margin: 40px 0 0;
	    }
        }
    }
}

#charityModal {
    z-index: 111111;
    background-color: rgba(83,42,58,0.65);
        border: none !important;
       border-radius: none !important;
    .close-btn-modal {
       width: 16px !important;
       height: 16px !important;
       opacity: unset !important;
        border: none !important;
       border-radius: none !important;
    }
    .charity-body-content {
       padding: 0 11%;
    }
    .modal-dialog {
        background: url('/images/home-paint-bg.png');
        background-color: #fff;
        background-repeat: no-repeat;
        width: 50%; //87%;
        margin:5% auto 5% auto;
        border: none !important;
       border-radius: none !important;
        @media screen and (max-width: 767px) {
               background: #fff;
               margin:17% auto 5% auto;
               width: 100%;
       }
        @media screen and (max-width: 767px) and (orientation:landscape){
               background: #fff;
               margin:11% auto 5% auto;
       }
       @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
               margin:9% auto 5% auto;
       }
       @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
               margin:7% auto 5% auto;
       }
    }

   .modal-content {
        background-color: transparent;
        border: none !important;
       border-radius: 0px !important;
       box-shadow: none !important;
    }
    .modal-header {
        border-bottom: none;
    }
    .modal-body {
        background-color: none;
    }
    .charity-content-section {
        margin-bottom:2%;
    }
    .title-charity {
        font-family: Nexa;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #f05323;
        @media screen and (min-width: 1000px) {
               width: 70%;
       }
    }
    .charity-para-sub {
        font-family: AvenirLTStd;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #4b4b4b;
    }
 .charity-para-last {
        font-family: AvenirLTStd;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #4b4b4b;
    }
    .charity-content-cta-section {
        margin-top:4%;
        margin-bottom:4%;
    }
    .btn-download-brochure {
        margin:0px 8px;
        padding:31px 90px 31px 58px;
        background: #F05323;
        color: #FFFFFF;
        font-size: 14px;
       cursor: pointer;
        line-height: 30px;
        font-family: Nexa;
        font-style: normal;
        background-image: url(/images/arrow-large-down.png);
        background-repeat: no-repeat;
        //background-position: 250px 18px;
        background-position: 83% 50%;
        @media screen and (max-width: 767px) {
               //background-position: 260px 24px;
               background-position: 90% 50%;
               margin-bottom: 15px;
               margin-left: 0px;
               padding:20px 50px;
       }
    }
 .container-charity-bottom {
           padding-left: 3%;
        @media screen and (max-width: 767px) and (orientation:landscape) {
           padding:0 15%;
       }
    }
    .btn-virtual-gallery {
        margin:0px 8px;
        padding:31px 110px 31px 80px;
        background: #F05323;
        cursor: pointer;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 30px;
        font-family: Nexa;
        font-style: normal;
        background-image: url(/images/arrow-large-down.png);
        background-repeat: no-repeat;
        background-position: 77% 50%;
        @media screen and (max-width: 767px) {
               background-position: 90% 50%;
               margin-left: 0px;
               padding:20px 60px;
       }
        @media screen and (max-width: 767px) and (orientation:landscape){
               margin-top: 5px;
       }
       @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
               margin-top: 10px;
       }
       @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
               background-position: 83% 50%;
               padding:31px 70px;
       }
    }
    .charity-content-fdlink-section {
        margin-bottom:3%;
    }
.btn-fd {
        margin:0 auto;
        font-family: Nexa;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        color: #efc4bb;
        @media screen and (max-width: 767px) {
               margin: 0px auto;
       }
        @media screen and (max-width: 767px) and (orientation:landscape) {
               margin: 0px auto;
       }
       @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
               margin: 10px auto;
       }
       @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
               margin: 10px auto;
       }
    }
}
.footer-static{
  position: fixed;
   left: 0;
   bottom: 0;
   width: 70px;
   height: 70px;
   margin-left: 48%;
   background-color: #f05323;
   color: white;
   text-align: center;
   z-index: 250;
   @media (max-width: 998px) {
      display: none;
   }
}
