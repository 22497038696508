.responsive-only,
.mobile-only,
.tablet-only,
.tablet-and-larger {
  display : none;
}


@media @desktop-large{
  @import "responsive/desktop-large";
}

@media @desktop {
  @import "responsive/desktop";
}

@media @tablet-xl {
  @import "responsive/tablet-xl";
}

@media @mobile {
  @import "responsive/mobile";
}
@media @mobile-landscape {
  @import "responsive/mobile-landscape";
}

@media @tablet {
  @import "responsive/tablet";
}
