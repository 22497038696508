.footer-bar {
  padding : 25px 0 0;
  height  : 90px;
  .purplegradient;
  img {
	margin-top : 5px;
	float      : left;
  }
}

.footer {
  background : #3A1A27;
  color      : @white;
  font-family: "NexaBold";
  padding    : 62px 0 0;
  .clearfix;
  .copy {
	font-size   : 12px;
	float       : left;
	width       : 100%;
	line-height : 30px;
	padding-top : 5px;
	ul {
	  float   : left;
	  margin  : 30px 0 22px;
	  padding : 0;
	  li {
		list-style : none;
		margin     : 0;
		padding    : 0;
		float      : none;
		&:first-of-type {
		  padding-left : 0;
		}
	  }
	}
	a {
	  text-decoration : none;
	  color           : #7a696f;
	  &:hover {
		color : @purplelight;
	  }
	}
	p {
	  float     : right;
	  font-size : 12px;
	  margin    : 0;
	  padding   : 0;
	  color     : #7a696f;
	}
	.tagline {
	  color          : @orange;
	  display        : block;
	  float          : none;
	  font-size      : 1.25*@p;
          padding        : 40px 0 20px 0;
	  text-align     : right;
	  /*border-bottom  : 1px solid #2a121a;*/
	  i {
		font-size      : 2em;
		vertical-align : middle;
		&:before {
		  margin-right : 0;
		}
	  }
	}
        .footer-address {
           float:right;
           p {
             color:#fff;
             width:100%;
             font-size:100%;
             padding:5px 0;
             display:block;
             line-height:normal;
             text-align:left;
             font-weight:100;
           }
        }
	img {
	  margin-left    : 7px;
	  vertical-align : middle;
	  position       : relative;
	  top            : -1px;
	}
  }
  .footer-address {
      margin-top: 5px;
      p {
          line-height: 16px;
          font-size : 12px !important;
          color     : #7a696f;
          margin    : 20px 0 -10px 0;
      }
  }
}
.footer-bottom-nav {
    .container {
        max-width: 100%;
        width: 87%;
    }
    padding: 101px 0 110px;
    .row {
        .bottom-nav-menu {
            &:nth-child(1) {
                width: 17%;
            }
            &:nth-child(2) {
                width: 17%;
            }
            &:nth-child(3) {
                width: 18%;
            }
            &:nth-child(4) {
                width: 18%;
            }
            &:nth-child(5) {
                width: 14%;
            }
            &:nth-child(6) {
                width: 16%;
            }
        }
        ul {
            padding    : 0;
            border-top : none;
            margin     : 0;
            li {
                line-height : 1.75em;
                list-style  : none;
                a {
                    color : @orange;
                    font-size: 26px;
                    letter-spacing: 1px;
                    line-height: 32px;
                    font-weight: 700;
                    &:hover {
                        text-decoration : none;
                        color : @orange;
                    }
                    &:active {
                        text-decoration : none;
                        color : @orange;
                    }
                    &:focus {
                        text-decoration : none;
                        color : @orange;
                    }
                }
                ul {
                    padding    : 22px 0 0;
                    li {
                        line-height : 1.75em;
                        list-style  : none;
                        a {
                            color : @white;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 26px;
                            &:hover {
                                text-decoration : none;
                                color : @orange;
                            }
                        }
                    }
                }
            }
        }
    }
    .contact-links {
        margin-bottom: 105px;
        h3 {
            color: #ffffff;
            font-size: 16px;
	    line-height: 19px;
            font-family: NexaBold;
            margin-top: 0;
            margin-bottom: 25px;
        }
        p {
            color: #F05323;
            font-family: NexaBold;
            &.get-phone {
                font-size: 20px;
                font-family: NexaBold;
                font-weight: 700;
                line-height: 24px;
                margin-top: 25px;
            }
        }
        .contact-social {
            ul {
                li {
                    float: left;
                    margin-left: 19px;
                    &:first-child {
                        margin-left: 0;
                    }
                   a {
                        line-height: 0;
                        font-size: 18px;
                        transition: all .4s ease;
                        &:hover {
                            opacity: 0.5;
                            transition: all .4s ease;
                        }
                    }
                }
            }
        }
        .footer-button {
            height: 80px;
            width: 486px;
            background-color: #532A3A;
            margin: 0 auto 10px;
            padding: 23px 28px 19px;
            transition: all .4s ease;
            .button-title {
                opacity: 0.35;
                color: #FFFFFF;
                font-size: 30px;
                font-family: NexaBold;
                line-height: 38px;
                width: 60%;
                float: left;
            }
            .button-link {
                color: #F05323;
                font-size: 14px;
                line-height: 39px;
                text-align: right;
                text-transform: uppercase;
                padding-right: 24px;
            }
            &:hover {
                width: 530px;
                background-color: #492432;
                transition: all .4s ease;
                .button-title {
                    opacity: 1;
                }
            }
        }
    }
}
.copyrights {
    padding: 85px 0 17px;
  .row {
        ul {
          padding    : .5em 0 0;
          margin     : 0;
        }
        a {
          color : @white;
          &:hover {
                text-decoration : none;
                color           : @orange;
          }
        }
        li {
          line-height : 1.75em;
          list-style  : none;
        }
  }
  .copy {
        .footer-nav {
	    li {
		a {
		    color: #FFFFFF;
		    opacity: 0.5;
		    font-size: 12px;
		}
	    }
	}
  }
  .footer-address {
	p {
            color: #FFFFFF;
            opacity: 0.5;
	}
  }
}
.footer-logo-text {
  padding : 7px 0 0;
  .footer-logo-image {
    margin: 0px auto;
    float: none;
    width: 89%;
    text-align: right;
    a {
        margin-left: 20px;
    }
    img {
      opacity: 0.25;
      transition: all .4s ease;
      &:hover {
        opacity: 1;
        transition: all .4s ease;
      }
    }
  }
  p {
    color: #7A696F;
    font-size: 12px;
    line-height: 1.75em;
    text-align: center;
    padding: 35px 0 20px;
  }
}
.footer-seo-links {
    background: #43202d;
    color: #fff;
    padding: 91px 0 111px;
    display: block;
    zoom: 1;
    h3 {
        opacity: 0.25;
        color: #FFFFFF;
        font-size: 26px;
        letter-spacing: 1px;
        line-height: 37px;
        text-align: center;
        margin: 0;
    }
    .seo-links {
        margin-top: 0;
        padding: 0;
        background: none;
        .content {
            background: none;
            margin-bottom: 0;
            padding-bottom: 0;
            ul {
                padding: 39px 20px 0 15px;
                &:first-child {
                    padding-left: 15px;
                }
                li {
                    a {
                        color: #FFFFFF;
                        font-size: 14px;
                        font-family: "NexaBold";
                        line-height: 26px;
                        text-align: center;
                        &:hover {
                            color: #F05323;
                        }
                    }
                }
            }
        }
    }
}
body:not(.home){
    .footer-heart {
        margin-top: 80px;
    }
}
.footer-heart {
    background: #532A3A;
    font-family: "NexaBold";
    padding: 110px 0 98px;
    text-align: center;
    color: #f05323;
    i {
        font-size: 120px;
    }
    p {
        color: #F05323;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        padding-top: 16px;
    }
}
.mobile-logo-text {
    display: none;
}
.bottom-nav-menu{
    width: 20% !important;
}

@media screen and (max-width: 768px){
    .bottom-nav-menu{
        width: 90% !important;
    }
}
@media only screen and (min-device-width : 768px)and (max-device-width : 1024px)and (orientation : portrait) {
	.sidebar .orange-boxes .item h3 {
		margin-top: 25px;
	}
}
@media screen and (min-width: 1200px){
	.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
			top: 69px;
			    right: -5px;
	}
}
@media only screen and (min-device-width : 768px)and (max-device-width : 1024px)and (orientation : landscape) {
	.navbar-inverse .navbar-nav {
			width: 100%;
	}
}
div#myModal {
    margin: 0 auto;
    z-index: 999999;
}
.modal-lg.in {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    .close {
        opacity: 1;
	margin-top: -10px;
    }
}
.modal-dialog.modal-lg {
    width: 100%;
}
.virtualFrame {
   width: 100%;
   height: 90vh;
   @media (max-width: 800px) {
	 height: 80vh;
   }
}

//cookie policy

body {
    .freeprivacypolicy-com---nb-interstitial-overlay {
        background: rgba(0,0,0,.5);
    }
    .freeprivacypolicy-com---nb-interstitial {
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        height: auto;
    }
    .freeprivacypolicy-com---nb {
        &.freeprivacypolicy-com---palette-dark {
            background: #532A3A;
        }
        .cc-nb-main-container {
            padding: 32px;
            @media (max-width: 767px) {
                padding: 20px;
            }
        }
        .cc-nb-title {
            font-family: NexaBold;
            margin-bottom: 10px;
            @media (max-width: 767px) {
                font-size: 20px;
                margin-bottom: 6px;
            }
        }
        .cc-nb-text {
            font-family: NexaBold;
            @media (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 14px;
            }
        }
        .cc-nb-buttons-container {
            button {
                font-family: NexaBold;
                text-transform: uppercase;
                color: #fff;
                padding: 11px 17px;
                background: #F05323;
                letter-spacing: .5px;
                box-shadow: none !important;
                &.cc-nb-reject {
                    display: none;
                }
		&.cc-nb-okagree {
	            color: #fff !important;
                    background: #F05323;
				}
                &.cc-nb-changep {
                    color: #fff;
                    border: 1px solid white !important;
		    background: #532A3A !important;
		    padding: 10px 17px;
                }
            }
        }
    }
    .freeprivacypolicy-com---pc-overlay {
        overflow-y: auto;
    }
    .freeprivacypolicy-com---pc-dialog {
        .cc-cp-foot-byline {
            visibility: hidden;
            @media (max-width: 767px) {
                width: 0;
                padding: 0;
            }
        }
        input[type=checkbox].cc-custom-checkbox+label {
            padding: 0px 0 0 50px;
            font-size: 18px;
            color: #fff;
        }
    }
	.footer__nav .style-type{
		list-style-type: none;
	}
    .freeprivacypolicy-com---palette-dark {
        .cc-pc-head-lang {
            select {
                height: 24px;
                -webkit-appearance: auto;
                font-size: 14px;
                &:focus {
                    box-shadow: none;
                }
            }
            .cc-pc-head-close {
                font-size: 16px;
            }
        }
        .cc-cp-foot-save {
            font-family: NexaBold;
            text-transform: uppercase;
			      color: #fff !important;
            background: #F05323 !important;
            padding: 11px 17px;
            letter-spacing: .5px;
        }
    }
}
//cookie policy
