#gdpr-modal-container {
    position: relative;
}
#gdpr-cookie-info {
    display:none;
    position: fixed;
    width: 276px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.5);
    right: 0;
    left: 3%;
    z-index: 10000;
    padding: 0px;
    bottom: 4%;
}
#gdpr-cookie-info .gdpr-container {
   width: 100%;
   padding: 33px;
}
#gdpr-cookie-info h4 {
    color: #4B4B4B;
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    margin: 0px 0px 20px 0px;
    padding:0px;
    line-height: 22px;
}
#gdpr-cookie-info .text {
    color: #737373;
    font-size: 12px;
    line-height: 21px;
    padding-bottom: 10px;
    text-align: left;
    margin:0px;
}
#gdpr-cookie-info .accept a {  
    height: 38px;
    width: 200px;
    text-align: center;
    float: none;
    line-height: 39px;
    margin: 16px 0px 0px 0px;
    white-space: nowrap;
    font-weight: 500;
    position: relative;
}
#gdpr-cookie-info .accept .btn-gdpr {
    background: #43232D;
    padding: 10px 34px 10px 34px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-transform: none;
}
@media (min-width: 768px) and (max-width: 1199px) {
    #gdpr-cookie-info .gdpr-container {
       padding: 30px;
   }
}
@media (max-width: 767px) {
   #gdpr-cookie-info {
       width: 40%;
   }
   #gdpr-cookie-info .gdpr-container {
       padding: 20px;
   }
}
@media (max-width:480px) {
    #gdpr-cookie-info {
       width: 65%;
       left: 1%;
       bottom: 1%;
    }
    .formPg form {
         .gdpr-field {
               width: 90%;
               margin-left: 5%;
         }
    }

}
form {
	.gdpr-field {
		width: 100%;
                margin-bottom: 10px;
		padding: 0px;
		.checkbox {
			padding-left: 0;
		}
	}
}
aside {
	form {
		.gdpr-field {
			width: 100%;
			margin-left: 0%;
		}
	}
}

table.cookies-table.privacy td {
    width: 20px;
}
