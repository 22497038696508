.office {
  .content-box {
	margin-top  : 0;
	padding-top : 0;
  }
  .row {
	padding-top : 30px;
	[class*="col-"]:first-of-type {
	  padding-left : 36px;
	}
	[class*="col-"]:last-of-type {
	  padding-right : 36px;
	}
	.complaints {
	  padding-left : 0px !important;
	}
  }
  .ribbon {
	&.horizontal {
	  &.inline {
		color         : @white;
		left          : -20px;
		margin-bottom : 40px;
	  }
	}
  }
  .vcard {
	span {
	  display : block;
	}
	margin-bottom : 2em;
  }

  p,
  .vcard span {
	font-size : 17px;
  }

  .phone {
	font-size   : 2*@p;
	font-weight : bold;
  }

  #sales-map {
	min-height : 510px;
  }
}
