@media screen and (max-width: 1000px) {
    .blog.news h3 {
        height: auto;
    }
}
.newsflash {
  padding-bottom : 1.5*@p;
}

.news {
  margin-top : 61px;
  .item {
	background   : @greylight;
	margin-right : -8px;
	min-height   : 285px;
  }
  .col-md-6:last-child {
	.item {
	  margin : 0 0 0 -9px;
	}
  }
  h3 {
	color       : @purpledark;
	font-size   : 30px;
	line-height : normal;
	margin      : 15px -5px;
	padding     : 0 53px 0 22px;
	line-height : 50px;
	display     : inline-block;
	.nexa;
  }
  .date {
	font-size   : 13px;
	line-height : 13px;
	color       : @greymiddle;
	margin      : 0;
	padding     : 8px 0 0 20px;
  }
  a.btn {
	margin  : 10px 0 0 20px;
	padding : 0 15px;
  }
  .more {
	margin-left : 20px;
	font-size   : 13px;
  }
  h4 {
	font-size   : @pbig2;
	line-height : @pbig2-lh;
	margin      : 4px 0;
	padding     : 0 40px 0 20px;
	font-weight : bold;
	a {
	  color : @purpledark;
	}
  }
  .tweet {
	padding     : 8px 0 0 50px;
	font-size   : 13px;
	margin      : 10px 21px 16px;
	line-height : 17px;
	color       : @greymiddle;
	position    : relative;
	p {
	  margin : 0;
	}
	&:last-child {
	  margin-bottom : 20px;
	}
	&:before {
	  .tk-icon-font;
	  background  : @purple;
	  color       : @orange;
	  content     : '\e80e';
	  font-size   : 2em;
	  height      : 30px;
	  left        : 0;
	  margin-left : 0;
	  padding     : 2px;
	  position    : absolute;
	  top         : 0;
	  width       : 30px;
	}
  }
  .ribbon {
	&.horizontal {
	  &.grey {
		&:before {
		  border-width : 25px;
		  right        : -50px;
		}
		@media @tablet-xl {
		  &:before {
			border-width : 20px;
			right        : -40px;
		  }
		}
	  }
	}
  }
}

.blog.news,
.item-page.news {
  margin-top : 0;
  .item {
	background    : @greylight;
	display       : inline-block;
	margin-bottom : @p;
	margin-right  : 0;
	padding-top   : @p;
  }
  .social {
	color    : @orange;
	position : relative;
	right    : @p;
        display  : block;
        z-index  : 999;
	&.top {
	  top : @p;
	}
  }
  .col-md-6:last-child .item {
	margin : 0 -10px 0 -8px;
  }
  .ribbon {
	opacity : .6;
	&:before {
	  border-width : 25px;
	}
  }
  .inner {
	margin         : @p;
	padding-bottom : 2*@p;
  }
  h3,
  h3 a {
	color       : @purpledark;
	font-size   : 30px;
	line-height : normal;
	margin      : 0 0 1.5*@p 0;
	padding     : 0;
	line-height : 1.25em;
	display     : inline-block;
	.nexa;
  }
  .create,
  .createdby {
	font-size   : @p;
	line-height : @p-lh;
	color       : @greymiddle;
	margin      : 0;
	padding     : 0 0 0 20px;
  }
  .create {
	color     : @purpledark;
	font-size : @p*1.15;
	padding   : 8px 0 0 20px;
  }
  a.btn {
	margin  : 10px 0 0 20px;
	padding : 0 15px;
  }
  .more {
	margin-left : 0;
	font-size   : 13px;
  }
  h4 {
	font-size   : @pbig2;
	line-height : @pbig2-lh;
	margin      : 4px 0;
	padding     : 0 40px 0 20px;
	font-weight : bold;
	a {
	  color : @purpledark;
	}
  }
}

.orange-boxes {
  padding : 49px 0;
  .col-md-6:last-child {
	.item {
	  margin-right : 0;
	}
  }
  .item {
	background  : @orange90;
	text-align  : center;
	font-size   : @pbig2;
	padding     : 20px 0 25px;
	line-height : @pbig2-lh;
	color       : @white;
	&:hover {
	  background : @orange;
	}
	h3 {
	  color     : @purpledark;
	  margin    : 0 0 15px;
	  font-size : 30px;
	  .nexa;
	}
	p {
	  margin : 0 0 17px;
	}
	.btn {
	  margin     : 0;
	  min-width  : 218px;
	  padding    : 0 40px;
	  text-align : center;
	}
  }
}
