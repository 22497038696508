.team_member_chat_content {
    margin-top: 8px !important;
}
.tablet-only {
  display : none;
}
.header-center {
  display : none;
}
.header-right {
    .contact-wraper {
        display : none;
    }
}
.mobile-only {
  display : block;
}

.contact {
  img {
	max-width : 100%;
	top       : 0;
  }
}

.directions-elements {
  li {
	margin : 30px 0 0;
	.btn {
	  margin : 0 0 0 30px;
	}
  }
}

.hide-mobile {
  display : none;
}
.home .ico-down {
    display : none;
}

.home-slider-wrapper {
  .owl-carousel {
	height : 449px !important;
        max-height: 449px;
        .ribbon-image {
            width: 100%;
            position: absolute;
        }
        .ribbon {
            left: 100%;
            position: initial;
            float: right;
            margin-right: 18px;
        }
        img {
            height: 184px !important;
        }
        .text-wrapper {
            text-align: left;
            padding: 16px 18px;
            height: auto;
            h3 {
                font-size: 26px;
                line-height: 32px;
                text-align: left;
                padding: 0;
            }
            .price-text {
                font-size: 26px;
                 line-height: 32px;
            }
            .price {
                font-size: 26px;
                 line-height: 32px;
            }
        }
        .owl-dots {
            bottom: 20px;
            left: 18px;
            margin-left: 0;
        }
  }
  // Property text within slide
  .wrapper {
	.container {
	  .content {
		font-size : 18px;
	  }
	  .price {
		display : block;
	  }
	  .price,
	  .price + .btn {
		margin-left : 65px;
	  }
	}
  }
}

.home {
  .featured-line {
	min-height : 1097px;
        margin-top: 70px;
  }
  .welcome {
    h2 {
        width: 100%;
        font-size: 26px;
        line-height: 32px;

    }
    p {
        width: 100%;
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
    }
    .btn {
        font-size: 14px;
        line-height: 30px;
        margin: 38px 0 0;

    }
  }
  .area-guide {
	h2 {
	  font-size : 3em;
	}
  }
}
.navbar-header {
    float: left;
}
.mobile-links {
    margin: 0 17px 0 0;
    padding: 25px 0 0 0;
  &.contact-mobile {
    padding: 25px 0 0 0;
    width: 20%;
    position: absolute;
    right: 18px;
    margin: 0;
    .ico-contacts {
      display: block;
      margin-left: 0;
      text-align: right;
      width: 100%;
      margin-top: -5px;
      &:before {
          font-size: 2em;
          color: #f05323;
          margin-right: 0;
       }
     }
  }

}

// Open mobile menu item styles
.navbar-inverse {
  .navbar-nav {
	.open {
	  .dropdown-menu > .active > {
		a, a:hover, a:focus {
		  background : transparent;
		  color      : @orange;
		}
	  }
	  .dropdown-menu > li > a {
		color : @white;
		&:hover {
		  color : @orange;
		}
	  }
	}
  }
  .navbar-brand {
	margin : 21px 15px 0 15px !important;
        width: 160px;
        height: 30px;
  }
}

.office {
  .phone {
	font-size : 1.5 * @p;
  }
  .row [class*="col-"]:last-of-type {
	padding-right : 15px;
  }
}

.properties {
  &.similar-row {
	.item {
	  display    : block;
	  margin-top : @p;
	  width      : 100% !important;
	  &:first-of-type {
		margin-top : 0;
	  }
	  .btn {
		position : relative;
		bottom   : 0;
	  }
	}
  }
}

.sales-search {
  &.grid-style {
	article {
	  width      : 100%;
	  margin     : 0 0 15px;
	  min-height : inherit;
	}
	.buttons {
	  bottom   : 0;
	  position : relative;
	}
  }
}

.sales-search {
  margin  : 0;
  padding : 0;
  h1 {
	line-height    : 29px;
	font-size      : 24px;
	padding-bottom : 3px;
  }
  section {
	padding-bottom : 25px;
	margin         : 0;
  }
  img {
	margin    : 0;
	max-width : 100%;
  }
  .currencies {
	margin-left : 13px;
  }

  article {
	padding-left   : 20px;
	padding-bottom : 20px;
	h2 {
	  font-size     : @pbigger;
	  line-height   : 18px;
	  padding-right : 40%;
	}
	.image {
	  float         : none;
	  margin-left   : 15px;
	  margin-bottom : @p;
	}
	.price,
	.price-text {
	  font-size   : @pbigger;
	  line-height : normal;
	  margin-top  : -5px;
	}
	.specs {
	  margin : -15px 0 1px;
	}
  }
  .btn span {
	display : none;
  }
}

.content-box {
  /* Conflicts with ribbon styles
h2 {
height          : auto;
background-size : auto 100%;
font-size       : @pbig2;
padding-right   : 0;
line-height     : @p-lh;
padding-top     : 10px;
float           : left;
padding-bottom  : 10px;
margin-right    : 54px;
}
*/
  .title {
	.clearfix;
  }
}

.interest-line {
  padding-top : 3px;
  .btn {
	margin-top : @p;
	width      : 100%;
  }
  p,
  .phone {
	display    : block;
	float      : none;
	text-align : center;
  }
  .phone {
	font-size   : @pbig2;
	color       : @orange;
	font-weight : bold;
  }
}

.gallery .slides a:before {
  display : none;
}
.sidebar {
    top: 27px;
}
.sidebar .contacts-item {
  padding-top    : 25px;
  line-height    : 18px;
  padding-bottom : 35px;
  .set {
	padding-top   : 17px;
	margin-bottom : -15px;
  }
  p {
	margin      : 0 0;
	display     : inline;
	padding     : 0;
	line-height : 18px;
	strong {
	  line-height : 18px;
	}
  }
}
.footer-seo-links {
     padding: 50px 0 47px;
     h3 {
         padding-bottom: 37px;
     }

}
.seo-links {
  margin-top : 70px;
  .content {
	clear  : left;
	margin : 0 7px 10px 7px;
        text-align: center;
	ul {
	  width        : 100%;
	  padding: 10px 0 0 0 !important;
	  &:first-child {
		padding-left : 0 !important;
	  }
	  li {
		&:last-child {
		  display : block;
		}
		a {
		  height       : 20px !important;
		  line-height  : 20px;
		  padding-left : 14px;
		  &:after {
			content : '';
		  }
		}
	  }
	}
  }

  .nav-tabs li {
	width         : 100%;
	margin-bottom : 10px;
	a {
	  height : 54px !important;
	}
  }
}

.sidebar .orange-boxes .item {
  width : 100%;
}

.property {
  h1 {
	font-size   : 24px;
	line-height : 28px;
	padding-top : 2px;
  }
  .nav-tabs {
	display : block;
  }
}

.form {
  padding : 28px 20px;
  p {
	font-size      : 13px;
	line-height    : 18px;
	padding-bottom : 14px;
	br {
	  display : none;
	}
  }
  .btn {
	font-size     : @pbig2;
	height        : 40px;
	margin-bottom : 15px;
  }
  textarea.form-control {
	height        : 90px;
	margin-bottom : 0;
  }
  .full {
	padding-top : 10px;
  }
  .center {
	width     : auto;
	max-width : none;
  }
  fieldset {
	padding-bottom : 0;
	& > div {
	  width   : 100% !important;
	  padding : 0 !important;
	}
  }
  .set {
	padding-bottom : 25px;
	margin-bottom  : 30px;
	padding-top    : 8px;
	h6 {
	  padding    : 0;
	  margin     : 0;
	  float      : none;
	  text-align : left;
	  display    : block;
	}
	label {
	  line-height : normal;
	  margin      : 0;
	  display     : block;
	  float       : none;
	  padding     : 2px 0 9px;
	  text-align  : left;
	  &:after {
		top : 8px !important;
	  }
	}
  }
}

.featured-line h2 {
  padding        : 27px 0 0 25px;
  margin         : 10px -28px 127px -20px;
  float          : none;
  font-size      : 24px;
  letter-spacing : -.5px;
  line-height    : 30px;
}

.welcome {
  padding : 20px 15px !important;
  margin  : 0;
  .col-md-4 {
	float   : left;
	margin  : 0;
	padding : 0 40px 0 0;
	width   : 100%;
  }
  .col-md-8 {
	clear   : none;
	float   : right;
	width   : 100%;
	margin  : 35px 0 10px;
	padding : 0;
  }
}

.orange-boxes {
  padding : 40px 0 28px;
  .item {
	min-height     : 0;
	padding-bottom : 30px;
	margin         : 0 0 10px !important;
	h3 {
	  padding-top : 5px;
	  font-size   : 24px;
	}
	p {
	  min-height : 0;
	  padding    : 0 25px 8px;
	}
	.btn {
	  min-width : 150px;
	  padding   : 0 8px;
	}
  }
}

.module-group {
  [class*="col-"]:first-of-type {
	.orange-boxes {
	  padding-bottom : 0;
	}
  }
  [class*="col-"]:last-of-type {
	.orange-boxes {
	  padding-top : 0;
	}
  }
}

.news {
  margin         : -5px 0;
  padding-bottom : 70px;
  h4 {
	padding-right : 15px;
  }
  h3 {
	height          : 40px;
	background-size : auto 100%;
	font-size       : @pbig2;
	line-height     : 40px;
  }
  a.btn {
	margin-top : 7px;
  }
  .tweet {
	padding       : 40px 0 0;
	margin-bottom : 22px;
	line-height   : 18px;
	&:last-child {
	  margin-bottom : 0;
	}
  }
  .item {
	padding-bottom : 28px;
	margin         : 0 -13px 12px !important;
	min-height     : 0;
  }
}

.bx-wrapper {
  max-width : 260px !important;
  margin    : 0 auto !important;
}
.desktop-logo-text {
    display: none;
}
.mobile-logo-text {
    display: block;
    .footer-logo-text {
        padding-top: 50px;
        padding-bottom: 20px;
        .footer-logo-image {
            width: 100%;
            text-align: left;
            a {
                margin-left: 0;
                margin-right: 15px;
                img {
                    margin-bottom: 0px;
                    width: 21%;
                    height: auto;
                }
                &:first-child {
                    margin-left: 0;
                }
                &.img-small{
                    img {
                        width: 47px;
                    }
                }
            }
        }
    }
}
.footer-logo-text {
  padding: 0px;
  .col-xs-5 {
    padding-left: 30px !important;
  }
  .col-xs-10 {
    padding-left: 30px !important;
  }
}
.copyrights {
  padding: 0 0 45px !important;
  .copyright {
    margin-top: 5px !important;
  }
}
.footer {
  padding-top : 22px;
  .col-sm-6 {
	padding-bottom : 60px;
  }
  .footer-address {
      margin-top: 31px;
      .copyright {
          padding-top: 13px;
      }
  }
  .copy {
	margin-top : 0;
	ul {
	  margin  : 10px 0 0;
	  display : block;
	}
	p {
	  margin  : 0;
	  display : block;
	}
	.copyright {
	  margin-top : 18px;
	}
	.tagline {
	  text-align     : left;
	  margin-top     : 28px;
	  padding-bottom : 0px;
	}
        .footer-address{
          margin-top:30px;
        }
	ul li {
	  display     : block;
	  line-height : normal;
	  padding     : 0 0 1px;
	  &:nth-last-child(-n+2) {
		margin-top : 20px;
	  }
	  &:last-child {
		margin-top : 0;
	  }
	  a {
		line-height : normal;
		display     : block;
	  }
	}
  }
  .row {
	ul {
	  margin-top : 35px;
	}
  }
}

// Team individual page styles
.content-article {
  .team {
	.ribbon {
	  &.horizontal {
		bottom      : -3.5em;
		line-height : 12px;
		padding     : .5em;
		position    : absolute;
		&:before {
		  .flag-right(@purpledark, 15px);
		}
	  }
	}
	.content-main {
	  padding-top : 6em;
	}
  }
}

.home-slider-wrapper {
  .wrapper {
	.container {
	  line-height : 40px;
	  .content {
		display     : block;
		font-size   : 16px;
		font-weight : normal;
		line-height : 1.5em;
		margin      : 0;
		padding-top : .5em;
	  }
	  .ribbon {
		display : none;
	  }
	  .price {
		display     : block;
		font-size   : 16px;
		margin-left : 0;
	  }
	}
  }
}
.home-people-intro {
    .about-content {
        padding: 97px 20px 155px;
        p {
            font-size: 18px;
            line-height: 30px;
            padding: 0 0 38px;
        }
        h3 {
            font-size: 26px;
            line-height: 32px;
            padding: 0 0 38px;
        }
    }
    .person-left {
        float: left;
        z-index: 1;
        .person-text {
            position: absolute;
            top: 250px;
            left: -10px;
            width: 90%;
            h2 {
                margin: 8px 0 10px 26px;
                padding-top: 38px;
                font-size: 26px;
                line-height: 28px;
            }
            p {
                padding-left: 0px !important;
            }
            h2, a {
                padding-right: 0;
                float: none;
                opacity: 1;
            }
        }
        .person-image {
            right: -11px;
            img {
                height: 235px;
                filter: grayscale(0%);
            }
        }
    }
    .person-right {
        .person-text {
            position: absolute;
            top: 250px;
            right: 4px;
            width: 40%;
            padding-right: 0;
            h2 {
                margin: 8px 0 11px -12px;
                padding-top: 38px;
                font-size: 26px;
                line-height: 28px;
            }
            .team_member_title {
                padding-top: 0 !important;
                padding-bottom: 3px !important;
            }
            p {
                line-height: 24px;
            }
            h2, a {
                opacity: 1;
            }
            a {
                padding-left: 0px;
            }
        }
        .person-image {
            left: 2px;
            img {
                height: 235px;
                filter: grayscale(0%);
            }
        }
    }
    .person-year {
        z-index: 3;
        width: 112%;
        margin: 0 -20px;
        position: relative;
        height: 220px;
        img {
            top: -56px;
            width: 110px;
        }
    }
}

.featured-wrapper {
    .properties {
        padding: 65px 0 19px;
        h2 {
            font-size: 46px;
            line-height: 38px;
            margin: 0 0 65px;
        }
        &:hover {
           background-color: #43202d;
           .text-wrapper {
                background-color: #43202d;
            }
        }
    }
    .text-wrapper {
        padding: 18px;
        margin-top: 0;
        height: 350px;
    }
    .owl-dots {
        bottom: 8px !important;
        right: 0;
        left: 18px !important;
        margin-left: 0 !important;
    }
    .owl-carousel img {
        height: 184px !important;
    }
    &.featured-right {
        .properties {
            padding: 65px 0 19px;
        }
        .featured-container {
            padding-bottom: 0;
        }
        .price,.propertytype, h3 {
            text-align: left !important;
        }
        .image-wrapper {
            float: none !important;
        }
        .owl-dots {
            bottom: 2px !important;
            right: 0;
            left: 18px;
            margin-right: 0;
        }
    }
}

.instagram-wrapper {
    .diamond {
        background: url(../../../images/social-icon-background.png) no-repeat top center;
        background-size: cover;
        margin-top: 50px;
        margin-bottom: 10px;
    }
    h2 {
        padding-top: 81px;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 13px;
    }
    ul {
        margin-bottom: 45px;
        li {
            margin-left: 18px;
            a {
                font-size: 25px;
            }
        }
    }
    #diamond-bg {
        width: 280px;
        height: 280px;
        -webkit-transform-origin: -3% 4%;
        -moz-transform-origin: -3% 4%;
        -ms-transform-origin: -3% 4%;
        -o-transform-origin: -3% 4%;
        transform-origin: -3% 4%;
    }
    .photo-wrapper {
        width: 100%;
        margin-bottom: 90px;
        float: left;
        display: inline-block;
        text-align: center;
        .image-item {
           width: 155px;
           height: 155px;           
	   float: none;
           margin: 3px 3px; 
           padding-left: 7px;
            padding-bottom: 7px;
            img {
                width: 192px;
                height: 192px;
            }
            &:nth-of-type(odd) {
                padding-left: 5px;
            }
            &:nth-of-type(even) {
                padding-right: 5px;
            }

        }
    }
}
.footer-heart {
    padding: 60px 0 44px;
    i {
        font-size: 90px;
    }
    p {
        font-size: 16px;
        line-height: 30px;
    }
}
.footer-bottom-nav {
    .container {
        width: 100%;
    }
    padding: 97px 0 25px !important;
    .contact-links {
        margin-bottom: 65px;
        h3 {
            font-size: 20px;
            line-height: 24px;

        }
        p {
            font-size: 18px;
            line-height: 30px;
            &.get-phone {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .contact-social {
            padding-top: 50px;
            ul {
                margin-bottom: 150px;
            }
        }
        .footer-button {
            width: 100%;
            float: left;
            padding: 23px 22px 19px;
            &:hover {
                width: 100%;
            }
            .button-title {
                font-size: 24px;
                line-height: 38px;
                width: 56%;
            }
            .button-link {
                padding-right: 0;
                p {
                    font-size: 14px;
                    line-height: 41px;
                }
            }
        }
    }
    .row {
        .bottom-nav-menu {
            padding-bottom: 40px;
            &:nth-child(1) {
                width: 100%;
            }
            &:nth-child(2) {
                width: 100%;
            }
            &:nth-child(3) {
                width: 100%;
            }
            &:nth-child(4) {
                width: 100%;
            }
            &:nth-child(5) {
                width: 100%;
            }
            &:nth-child(6) {
                width: 100%;
            }
        }
        ul {
            margin-top: 0 !important;
        }

    }
}

.home-top-banner {
    height: 160px;
    p {
        font-size: 20px;
        line-height: 26px;
        &.title-text {
            padding-top: 54px;
        }
    }
    &:before {
        height: 230px;
    }
    .home-banner-search {
        background-color: #532A3A;
        height: 461px;
        .search-button-type {
            label:not(.checked) {
                background-color: #472230;
                opacity: 0.4;
            }
        }
        .search {
            padding: 50px 0;
        }
        i {
            position: inherit;
            font-size: 1em;
        }
        .links {
            margin-top: 41px;
        }
        .form-group {
            height: 60px;
            width: 100%;
            margin-bottom: 0;
            padding-right: 0;
            &.first {
                &:before {
                    width: 92%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 4%;
                    top: 0;
                }
            }
            &.last {
                &:before {
                    width: 92%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 4%;
                    top: 0;
                }
                .crf-s:after {
                    left: 88%;
                }
            }
             .form-control {
                height: 60px;
                width: 100%;
                padding-left: 14px;
                padding-top: 25px;
                padding-bottom: 22px;
            }
            .ico-search {
                &:before {
                    top: -5px;
                    position: relative;
                }
            }
            .crf-s {
                padding: 0;
                line-height: 61px;
                height: 60px;
            }
                .crf-s:before {
                    left: 83.5%;
                }
               .crf-s:after {
                    left: 88%;
                    top: 0px;
                }
        }
    }
}
.home-top-slider {
    .ImageSliderOuter.featured .slide{
        height: 160px;
        p {
            font-size: 20px;
            line-height: 26px;
            &.title-text {
                padding: 40px 5px 0;
            }
        }
        &:before {
            height: 230px;
        }
    }
}
.home-slider-wrapper {
    top: 415px;
    .owl-carousel {
        &:hover {
            background-color: #43202d;
        }
    }
}
.home-orange-boxes .item h3 {
    margin-top: 0;
    padding: 83px 0 22px;
}
.home-orange-boxes {
    .item {
        height: 416px;
        &.first {
            p {
                padding: 0 19% 22px;
            }
        }
        &.last {
            p {
                padding: 0 13% 22px;
            }
        }
    }
}
.container.main {
    margin-bottom: 10px;
}
body:not(.home) .footer-heart {
    margin-top: 50px;
}
.module.area-guide {
    margin: 40px 0 0;
}
.navbar-collapse {
     [class*="col-"]{
        padding : 0 5px;
    }

    .burger-close {
        margin: 25px 2px 43px;
    }
    .navbar-nav {
        padding: 0;
        margin-bottom: 40px;
        li {
            margin-left: 15px;
            .nav-child {
                li {
                    margin-left: 0;
                    a {
                        padding: 5px 0 5px;
                    }
                }
            }
        }
    }
    .footer-button {
        width: 100%;
        margin: 10px 0;
        padding: 22px 22px 20px;
        &:hover {
            width: 100%;
        }
        .button-title {
            width: 56%;
        }
        .button-link {
            padding-right: 0px;
        }
    }
    .contact-social {
        margin-top: 35px;
        margin-bottom: 120px;
        left: -4px;
    }
}
.top-search-wrapper {
        height: 488px;
        padding-left: 21px;
        .search {
            width: 100%;
            padding-top: 30px;
        }
        i {
            position: inherit;
            font-size: 1em;
            padding-top: 9px;
        }
        .links {
            margin-top: 41px;
        }
        .form-group {
            height: 60px;
            width: 100% !important;
            margin-bottom: 0;
            padding-right: 0 !important;
            &.first {
                &:before {
                    width: 92%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 4%;
                    top: 0;
                }
            }
            &.last {
                &:before {
                    width: 92%;
                    height: 0;
                    border-top: 1px solid #532A3A;
                    border-left: none;
                    left: 4%;
                    top: 0;
                }
                .crf-s:after {
                    left: 88%;
                }
            }
            .form-control {
                height: 60px;
                width: 100%;
                padding-top: 25px;
                padding-bottom: 22px;
            }
            .ico-search:before {
                top: -5px;
                position: relative;
            }
            .crf-s {
                padding: 0;
                height: 60px;
                line-height: 61px;
            }
            .crf-s:before {
                left: 83.5%;
            }
           .crf-s:after {
                left: 88%;
                top: 0px;
            }
            &.buttons {
                .btn {
                    width: 100% !important;
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
}
.top-header {
    .mobile-links {
        .ico-search {
            margin-right: 18px;
            margin-left: 0;
        }
    }
}
.slide-wrap {
    display: table !important;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .slider-text {
	display: table-cell;
        vertical-align: middle;
    }
}
.home-top-slider .ImageSliderOuter.featured .slide p.title-text {
    padding: 22px 5px 0;
}
.navbar-collapse .contact-links a {
    font-size: 18px;
    line-height: 22px;
}
.featured-wrapper .properties:hover, .featured-wrapper .properties:hover .text-wrapper {
    background-color: #43202d;
}
.area-button-last {
    margin-top: 25px;
    margin-bottom: 10px;
}
a.fixed-btns {
    max-width: 100%;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    /* word-wrap: break-word; */
    height: 100% !important;
}
.navbar-collapse .contact-links {
    left: -5px;
}
.home-team-btn .btn-primary {
    padding: 31px 71.21px;
}
.home-team-btn .btn-primary:hover {
    padding: 31px 91.21px;
}
.home-team-btn {
   margin-bottom: 19px;
}
