.item-page.team {
  .page-header {
	position : relative;
  }
}

.row-fluid + .content-main {
  margin-top : 40px;
}

.team {
  .sidebar {
	h3 {
	  font-size     : 1.25em;
	  margin-bottom : 0;
	}
	.phone {
	  font-size   : 1.5em;
	  font-weight : bold;
	}
	.btn {
	  min-width  : 218px;
	  padding    : 0 40px;
	  text-align : center;
	}
  }
  p {
	&.loves {
	  color         : @purpledark;
	  margin-bottom : 25px;
	}
  }
  .loves {
	font-size : 1.25em;
	i {
	  color          : @orange;
	  font-size      : 2em;
	  vertical-align : middle;
	  &:before {
		margin-left  : 0;
		margin-right : 10px;
	  }
	}
  }
}

.tagged-item {
  margin   : 0;
  padding  : 0;
  margin-bottom: 45px;
  h3 {
    color      : @orange;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 1px;
    margin: 0;
    padding: 20px 0 10px 0;
    @media @mobile {
        font-size: 17px;
    }
    span {
      display :block;
      color   :@purple;
      font-weight:700;
      font-size: 14px;
      line-height: 26px;
      &.job-title {
        letter-spacing: 0;
      }
    }
    span.qualification {
        display: inline-block;
        color      : @orange;
        line-height: 18px;
    }
  }
  a {
	max-width     : 100%;
	position      : relative;
    display: block;
  }
  form .email {
      font-family: 'Nexa';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;
      color: @orange;
      background: none;
      border: none;
      padding: 0;
  }
}
.team .ribbon.horizontal {
  left: -.1em;
  line-height: 1.3em !important;
  padding: 0 0 11px .6em !important;
  top: auto;
  text-align: inherit;
  text-transform: none;
  width: auto;
  &:before{
   border-width: 32px;
  border-style: solid;
  border-color: #43202d transparent #43202d #43202d;
  }
 h2{
    color: #f05323;
    margin-bottom: 4px;
    margin-top:10px;
    line-height: .9025em;
    padding-right:10px;
  }
  span {
    color:#fff;
    font-size:13px;
    display:block;
    padding-left:1px;
    padding-right:5px;
  }
}
.team .ribbon.horizontal:before {
  border-width: 32px !important;
}
@media @mobile {

  .tagged-item {
	a {
	  bottom : 2px;
	}
  }

   .team .ribbon.horizontal {
      bottom: -4.9em !important;
     }
}

.team-layout {
    .featured-line {
        overflow: visible;
        margin-top : 70px;
        margin-bottom: 200px;
        background: @greylight;
        @media screen and (max-width : 1200px) {
            margin-bottom : 70px;
        }
        .team-banner-content {
            padding-top: 80px;
            padding-bottom: 120px;
            @media screen and (max-width : 1200px) {
                padding-top: 20px;
                padding-bottom: 30px;
            }
            h1 {
                font-size: 44px;
                line-height: 56px;
                margin-bottom: 42px;
                @media @tablet-xl {
                    margin-bottom: 20px;
                }
                @media @mobile {
                    font-size: 26px;
                    line-height: 32px;
                    margin-bottom: 38px;
                }
            }
            p {
                font-size: 22px;
                line-height: 38px;
                width: 92%;
                @media @tablet-xl {
                    width: 100%;
                    font-size: 20px;
                    line-height: 30px;
                }
                @media @mobile {
                    font-size: 18px;
                }
            }
            img {
                position: absolute;
                top: 60px;
                right: 18px;
                width: 100%;
                height: 510px;
                object-fit: contain;
                @media screen and (max-width : 1200px) {
                    width: 95%;
                }
                @media @tablet-xl {
                    top: 70px;
                    height: 265px;
                }
                @media @mobile {
                    position: relative;
                    right: 0;
                    margin-top: -30px;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .body {
        .container.main {
            margin-bottom: 0;
        }
        .team {
            .team-row {
                margin: 0 -12px;
                @media @tablet-xl {
                    margin: 0 -15px;
                }
                .tagged-item {
                    padding: 0 25px;
                    display: inline-block;
                    vertical-align: top;
                    @media @tablet-xl {
                        padding: 0 20px;
                    }
                    @media @mobile {
                        padding: 0 15px;
                    }
                    a.email {
                        font-weight: 700;
                        line-height: 30px;
                    }
                    a img {
                        width: 322px;
                        filter: grayscale(1);
                        @media screen and (max-width : 1200px) {
                            width: 443px;
                        }
                        @media @tablet-xl {
                            width: 342px;
                        }
                        @media @mobile {
                            width: 301px;
                        }
                        @media screen and (max-width : 480px) {
                            width: 440px;
                        }
                    }
                    a img:hover {
                        filter: grayscale(0);
                    }
                    .team-des-scoll {
                        display: none;
                    }
                    .team-desc .team-des-scoll {
                        display: block;
                    }
                    .hovering {
                        // cursor: pointer;
                    }
                    &.item_hover {
                        opacity: 1;
                    }
                    &.blur-div {
                        .hovering {
                            opacity: .4;
                        }
                        &.item_hover {
                            .hovering {
                                opacity: 1;
                            }
                        }
                    }
                    &.non-blur-div {
                        .hovering {
                            opacity: 1;
                        }
                    }
                }
            }
            .og-grid {
                .tagged-item.og-expanded {
                    margin-bottom: 120px;
                    @media @tablet-xl {
                        margin-bottom: 100px;
                    }
                    @media @mobile {
                        margin-bottom: 90px;
                    }
                    img {
                        filter: grayscale(0);
                    }
                }
            }
            .og-grid .tagged-item.og-expanded > a.team_click::after {
                position: absolute;
            	bottom: -205px;
                left: 50%;
            	border: solid transparent;
            	content: " ";
            	height: 40px;
            	width: 40px;
                background: #532A3A;
                transform: rotate(45deg);
            	pointer-events: none;
            	margin: -10px 0 0 -15px;
                opacity: 1;
                @media @mobile {
                    bottom: -215px;
                }
            }

            .og-expander {
            	position: absolute;
            	background: #fff;
            	top: auto;
            	left: 0;
            	width: 100%;
            	margin-top: 45px;
                margin-bottom: 80px;
            	text-align: left;
            	height: 0;
            	overflow: hidden;
                min-height:395px;
                @media @tablet-xl {
                    margin-bottom: 55px;
                }
                @media screen and (max-width : 480px) {
                    margin-bottom: 40px;
                }
            }

            .og-expander-inner {
            	padding: 100px 95px;
            	height: 100%;
                background: #532A3A;
                position: relative;
                @media @tablet-xl {
                    padding: 60px 55px;
                }
                @media screen and (max-width : 480px) {
                    padding: 50px 15px;
                }
            }

            .og-close {
            	position: absolute;
            	width: 30px;
            	height: 30px;
            	top: 30px;
            	right: 40px;
            	cursor: pointer;
                @media screen and (max-width : 480px) {
                    width: 25px;
                	height: 25px;
                	top: 12px;
                	right: 15px;
                }
            }

            .og-close::before,
            .og-close::after {
            	content: '';
            	position: absolute;
            	width: 100%;
            	top: 50%;
            	height: 1px;
            	background: @white;
            	-webkit-transform: rotate(45deg);
            	-moz-transform: rotate(45deg);
            	transform: rotate(45deg);
            }

            .og-close::after {
            	-webkit-transform: rotate(-45deg);
            	-moz-transform: rotate(-45deg);
            	transform: rotate(-45deg);
            }

            .og-close:hover::before,
            .og-close:hover::after {
            	background: @white;
            }

            .og-fullimg {
               width: 30%;
               @media screen and (max-width : 480px) {
                   width: 100%;
                   margin-bottom: 40px;
               }
            }
            .og-details {
                width: 70%;
                @media screen and (max-width : 480px) {
                    width: 100%;
                }
            }
            .og-fullimg,
            .og-details {
            	float: left;
            	height: 100%;
            	overflow: hidden;
            	position: relative;
                @media screen and (max-width : 480px) {
                    height: auto;
                }
            }

            .og-details {
            	padding: 0 0 0 50px;
                color: @white;
                @media @tablet-xl {
                    padding: 0 0 0 40px;
                }
                @media screen and (max-width : 480px) {
                    padding: 0;
                }
                a {
                    display: block;
                    font-weight: 700;
                    line-height: 20px;
                    &.peoples-phone {
                        margin-top: 16px;
                        font-size: 20px;
                        margin-bottom: 20px;
                    }
                }
                .team-des-scoll{
                    height: 250px;
                    overflow-y: auto;
                    padding-right: 10px;
                }
            }

            .og-fullimg {
            	text-align: center;
            }

            .og-fullimg img {
            	display: inline-block;
            	max-height: 100%;
            	max-width: 100%;
                width: 440px;
            }

            .og-details h3 {
                padding-top: 0;
                padding-bottom: 8px;
            }
            .og-details h6 {
                margin-top:0px;
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 700;
            }
            .og-details p {
                padding-top:10px;
                color: @white;
                font-size: 18px;
                font-weight: 300;
                line-height: 28px;
            }

            .og-loading {
            	width: 20px;
            	height: 20px;
            	border-radius: 50%;
            	background: #ddd;
            	box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ccc;
            	position: absolute;
            	top: 50%;
            	left: 50%;
            	margin: -25px 0 0 -25px;
            	-webkit-animation: loader 0.5s infinite ease-in-out both;
            	-moz-animation: loader 0.5s infinite ease-in-out both;
            	animation: loader 0.5s infinite ease-in-out both;
            }

            @-webkit-keyframes loader {
            	0% { background: #ddd; }
            	33% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd; }
            	66% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc; }
            }

            @-moz-keyframes loader {
            	0% { background: #ddd; }
            	33% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd; }
            	66% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc; }
            }

            @keyframes loader {
            	0% { background: #ddd; }
            	33% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd; }
            	66% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc; }
            }
        }
    }
}
