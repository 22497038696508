// IE10 and IE11
@media screen and (-ms-high-contrast : active), (-ms-high-contrast : none) {

  blockquote,
  q {
	font-size : @qsmall;
	&:before,
	&:after {
	  font-size : 90px;
	}
	&.small {
	  &:before,
	  &:after {
		font-size : 72px;
	  }
	}
  }

  .properties {
	&.similar-row {
	  .item {
		h3,
		.price,
		.price-text {
		  font-size : @p;
		}
	  }
	}
  }

}

@media screen and (min-width : 992px) and (-ms-high-contrast : active), (-ms-high-contrast : none) {
  .col-md-6 {
	width : 49%;
  }
}

// IE9 and before
.oldie {

  blockquote,
  q {
	font-size : @qsmall;
	&:before,
	&:after {
	  font-size : 90px;
	}
	&.small {
	  &:before,
	  &:after {
		font-size : 72px;
	  }
	}
  }

  .properties {
	&.similar-row {
	  .item {
		h3,
		.price,
		.price-text {
		  font-size : @p;
		}
	  }
	}
  }

  @media screen and (min-width : 992px) {
	.col-md-6 {
	  width : 49%;
	}
  }

}