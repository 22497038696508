.contact {
  .person {
	img {
	  max-width : 140px;
	}
	padding-bottom : 2px;
	h3 {
	  font-weight : bold;
	  margin-top  : 46px;
	}
  }

  .ribbon.horizontal.inline {
	left          : 0;
	margin-bottom : 0;
	padding-left  : 15px;
  }

  .vcard {
	span {
	  display : block;
	}
	margin-bottom : 2em;
  }

  p,
  .vcard span {
	font-size : 17px;
  }

  .phone {
	font-size   : 2*@p;
	font-weight : bold;
  }
}
