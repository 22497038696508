.col-xs-2-4,
.col-sm-2-4,
.col-md-2-4,
.col-lg-2-4 {
  position      : relative;
  min-height    : 1px;
  padding-right : 15px;
  padding-left  : 15px;
}

.col-xs-2-4 {
  width : 20%;
  float : left;
}

@media (min-width : 768px) {
  .col-sm-2-4 {
	width : 20%;
	float : left;
  }
}

@media (min-width : 992px) {
  .col-md-2-4 {
	width : 20%;
	float : left;
  }
}

@media (min-width : 1200px) {
  .col-lg-2-4 {
	width : 20%;
	float : left;
  }
}

// Bootstrap grid adjustments to fit design
@right-offset: -5px;

.blog {
  .page-header {
	margin-right : @right-offset;
  }
  .item {
	.page-header {
	  margin-right : 0;
	}
  }
  .items-row {
	[class*="col-"]:first-of-type {
	  padding-left : 0;
	}
	[class*="col-"]:last-of-type {
	  padding-right : 0;
	  .item {
		margin-right : @right-offset;
	  }
	}
  }
}

.col-md-9 .content-main,
.col-md-9 .item-page,
.startek-details .property {
  margin-right : @right-offset;
}

.landing-fixflo-page, .landing-property-management {
    .banner-wrapper {
        position: relative;
        @media @tablet-xl {
            margin-top : -10px;
        }
        .item-image {
            img {
                width: 100%;
                max-height: 500px;
                object-fit: cover;
                object-position: top;
                @media @mobile {
                    height: 330px;
                }
            }
        }
        .banner-left-img {
            position: absolute;
            left: 120px;
            top: 50%;
            transform: translateY(-50%);
            @media @tablet-xl {
                left: 40px;
            }
            @media @mobile {
                left: 50%;
                transform: translateX(-50%);
                top: 70px;
            }
            img {
                width: 120px;
                @media @mobile {
                    width: 70px;
                }
            }
        }
        .banner-content {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            @media @mobile {
                // width: 100%;
                // top: 140px;
                // transform: translateX(-50%);
            }
            h1 {
                color: @white;
                font-size: 40px;
                font-weight: 400;
                line-height: 48px;
                margin-top: 5px;
                @media @mobile {
                    font-size: 28px;
                    line-height: 34px;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }
            }
            p {
                color: @white;
                font-family: "nexa";
                font-size: 20px;
                font-weight: 400;
                line-height: 22px;
            }
            .btn.btn-primary {
                background: @orange;
                border-color: @orange;
                padding: 4px 44px;
                font-family: "nexa";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;
                .icon-tk-lrg_arrow_right:before {
                    font-size: 22px;
                    position: relative;
                    margin-right: -5px;
                    top: 2px;
                }
            }
        }
    }
}
.landing-fixflo-page {
    .container.main {
        margin-top: 20px;
        margin-bottom: 20px;
        @media @tablet-xl {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .banner-wrapper {
        .banner-left-img {
            @media @mobile {
                top: 40px;
            }
        }
        .banner-content {
            @media @mobile {
                // top: 140px;
            }
            h1 {
                margin-bottom: 0;
            }
            .footer-heart {
                background: none;
                padding: 0;
                h1 {
                    color: @orange;
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 30px;
                    padding-top: 16px;
                    margin: 0;
                }
            }
        }
    }
    .embed-responsive.embed-responsive-16by9 {
        padding-bottom: 750px;
        @media @tablet-xl {
            margin-bottom: -20px;
        }
        @media @mobile {
            margin-bottom: -40px;
        }
        .embed-responsive-item {
            height: 750px;
        }
    }
    .col-md-9.col-main {
        width: 100%;
    }
    .footer-heart {
        margin-top: 0 !important;
    }
}

.landing-property-management {
    .banner-wrapper {
        .banner-left-img {
            @media @mobile {
                top: 30px;
            }
        }
        .banner-content {
            @media @tablet-xl {
                // left: 60%;
                width: 80%;
            }
            @media @mobile {
                // top: 100px;
                width: 100%;
                // left: 50%;
                padding: 0 10px;
            }
            h1 {
                @media @mobile {
                    font-size: 24px;
                }
            }
        }
        .banner-bottom-arrow {
            position: absolute;
            bottom: 32px;
            left: 50%;
            transform: translateX(-50%);
            @media @tablet-xl {
                bottom: 10px;
            }
            @media @mobile {
                bottom: 0px;
            }
            .icon-tk-lrg_arrow_down:before {
                content: '\e800';
                font-size: 2em;
                color: @white;
            }
        }
    }
    .sidebar {
        margin-left: 0;
        top: 0;
        @media @tablet-xl {
            margin-bottom: 40px;
        }
        @media @mobile {
            margin-top: 15px;
            margin-bottom: 10px;
        }
        .contacts-item {
            background: transparent;
            margin-bottom: 0;
            @media @mobile {
                padding-bottom: 26px;
            }
            .contact-img {
                margin: -17px -20px 0 -20px;
                img {
                    width: 100%;
                }
                @media @tablet-xl {
                    margin: 0;
                }
            }
            p {
                font-family: "nexa";
                font-size: 20px;
                line-height: 24px;
                margin: 32px 0;
                &.name {
                    margin-top: 16px;
                    font-weight: bold;
                    margin-bottom: -16px;
                }
                @media @mobile {
                    font-size: 18px;
                    display: block;
                    margin: 24px 0;
                }
                & + .set {
                    top: 0;
                    @media @mobile {
                        padding-top: 0;
                    }
                }
            }
            .set {
                .btn {
                    font-family: "nexa";
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 36px;
                    text-transform: uppercase;
                    margin-top: 0;
                    margin-bottom: 16px;
                    & + .btn {
                        margin-bottom: 6px;
                    }
                }
            }
        }
        .orange-boxes {
            @media @tablet-xl {
                margin-top: 24px;
            }
            .item {
                @media @tablet-xl {
                    float: none !important;
                    width: 100%;
                    min-height: auto;
                }
                h3 {
                    font-family: "nexa";
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-top: 0;
                }
                .btn {
                    font-family: "nexa";
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 36px;
                    text-transform: uppercase;
                    @media @tablet-xl {
                        min-width: 250px;
                    }
                }
            }
        }
        .magazine-module {
            h4 {
                margin-top: 40px;
                color: @purpledark;
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
    .welcome {
        margin-top: 80px;
        @media @tablet-xl {
            margin-top: 40px;
        }
        @media @mobile {
            margin-top: 20px;
        }
    }
    .container.main {
        margin-bottom: 0;
    }
    .content-top-module {
        img {
            width: 100%;
        }
        h2 {
            font-size: 32px;
            line-height: 39px;
            margin: 48px 0 24px;
            @media @mobile {
                font-size: 24px;
            }
        }
        p {
            font-family: Nexa;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .table {
            margin-bottom: 0;
            tbody {
                td {
                    font-family: Nexa;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
            tr {
                th, td {
                    text-align: center;
                    &:first-child {
                        text-align: left;
                    }
                }
            }
            span.tick {
                background-image: url(../images/ico_check.png);
                background-repeat: no-repeat;
                background-size: contain;
                width: 12px;
                height: 9px;
                display: block;
                position: relative;
                left: 50%;
                top: 5px;
                margin-left: -6px;
            }
        }
    }
    .btn.btn-form-link {
        min-width: 156px;
        background: @orange;
        border: 0;
        padding: 0 25px;
        font-family: Nexa;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .faq-below-module {
        background-color: #532A3A;
        padding: 80px 0 64px 0;
        @media @tablet-xl {
            padding: 60px 0 44px 0;
        }
        @media @mobile {
            padding: 40px 0 24px 0;
        }
        &.both-faq-merged {
            padding-bottom: 64px;
            @media @tablet-xl {
                padding-bottom: 44px;
            }
            @media @mobile {
                padding-bottom: 0;
            }
        }
        h2, h3 {
            color: @white;
            line-height: 100%;
        }
        h2 {
            font-size: 40px;
            margin: 0 0 48px;
            @media @mobile {
                font-size: 24px;
                margin-bottom: 32px;
            }
        }
        h3 {
            font-size: 32px;
            margin: 0 0 32px;
            @media @mobile {
                font-size: 24px;
                margin-bottom: 24px;
            }
        }
        .panel-group {
            margin-bottom: 0;
        }
        .panel {
            background: none;
            box-shadow: none;
            border-top: 1px solid #E0E0E0;
            padding: 16px 0;
            margin: 0;
            .panel-title {
                position: relative;
                display: block;
                color: @white;
                font-family: Nexa;
                font-size: 18px;
                font-weight: 700;
                line-height: 140%;
                @media @mobile {
                    font-size: 16px;
                }
            }
            a.panel-title:before {
                content: "";
                background-image: url(../images/Minus.png);
                width: 16px;
                height: 16px;
                float: right;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            a.panel-title.collapsed:before {
                content: "";
                background-image: url(../images/Plus.png);
            }
            a.panel-title:hover,
            a.panel-title:active,
            a.panel-title:focus  {
                text-decoration:none;
            }
            .panel-collapse {
                padding-top: 16px;
                div, p, li {
                    color: @white;
                    font-family: Nexa;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 140%;
                    opacity: .9;
                    @media @mobile {
                        font-size: 16px;
                    }
                }
            }
        }
        .split-faq {
            height: 8px;
            @media @mobile {
                height: 0;
            }
        }
        .btn-form-link {
            @media @mobile {
                width: 100%;
            }
        }
    }
    .content-top-module.left-right-module {
        padding: 80px 0;
        @media @tablet-xl {
            padding: 60px 0;
        }
        @media @mobile {
            padding: 40px 0;
        }
        .split-row {
            display: flex;
            align-items: center;
            @media @mobile {
                flex-direction: column-reverse;
                &.version-2 {
                    flex-direction: column;
                }
            }
            .left-side {
                text-align: center;
                @media @mobile {
                    text-align: left;
                }
                h2 {
                    margin-top: 0;
                }
                a.btn {
                    margin-top: 16px;
                }
            }
            .right-side {
                img {
                    height: 370px;
                    object-fit: cover;
                    @media @mobile {
                        height: auto;
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
    .testimonial-module {
        h2 {
            color: #3E222D;
            font-size: 40px;
            font-weight: 400;
            line-height: 100%;
            margin: 0 0 40px;
            @media @mobile {
                font-size: 24px;
                margin-bottom: 32px;
            }
        }
        #owl-demo .owl-item {
            text-align: center;
            h4 {
                color: @orange;
                font-family: Nexa;
                font-size: 20px;
                font-weight: 700;
                line-height: 22px;
                margin: 0 0 24px;
            }
            p {
                font-family: Nexa;
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 0;
                &.content {
                    margin-bottom: 32px;
                }
                &.author {
                    color: #3E222D;
                    line-height: 22px;
                }
            }
        }
        .btn.btn-form-link {
            background: #532A3A;
            margin-top: 40px;
            @media @mobile {
                width: 100%;
                margin-top: 32px;
            }
        }
    }
    .footer-heart {
        @media @tablet-xl {
            margin-top: 60px !important;
        }
        @media @mobile {
            margin-top: 40px !important;
        }
    }
}
