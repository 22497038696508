/*******************************
   Details Overlay slider Styles
   Much of this is based on https://github.com/starberry/sandfords2/blob/master/httpdocs/templates/sandfords/less/src/slider-details-overlay.less
*******************************/
.details-overlay-slider-modal {
  position : fixed;
  top      : 70px;
  left     : 0;
  width    : 100%;
  height   : 100%;
  z-index  : 1031;
  display  : none;
}

.details-overlay-slider-wrapper {
  position         : relative;
  background-color : rgba(0, 0, 0, 0.8);
}

.slideshow.fullscreen {
  width      : 100%;
  height     : 100%;
  color      : #fff;
  text-align : center;
  .slider {
	height : 100%;
	.swiper-wrapper-transition;
  }
  .swiper-slide {
	position            : relative;
	background-repeat   : no-repeat;
	background-position : center center;
	.background-cover();
	overflow            : hidden;
	height              : 100%;
	width               : 100%;
  }
  .pagination {
	position : absolute;
	z-index  : 20;
	left     : 10px;
	bottom   : 10px;
  }
  .swiper-pagination-switch {
	display       : inline-block;
	width         : 8px;
	height        : 8px;
	background    : #7E606C;
	margin-right  : 5px;
	opacity       : 0.8;
	cursor        : pointer;
  }
  .swiper-visible-switch {
	background : #fff;
  }
  .swiper-active-switch {
	background : #fff;
  }
}

// Controls (prev, next, close)
.ImageSliderOuter.fullscreen {
  .ImageSliderPrevNext,
  .btn-prev, .btn-next {
	position   : absolute;
	top        : 50%;
	z-index    : 100;
	margin-top : -45px;
	display    : block;
	width      : 49px;
	height     : 90px;
	opacity    : .7;
	.transition(opacity .3s ease);
	&:hover {
	  opacity : 1;
	}
  }
  .ImageSliderClose {
	position : absolute;
	top      : 30px;
	right    : 30px;
	z-index  : 1001;
	display  : block;
	width    : 50px;
	height   : 50px;
	opacity  : .7;
	.transition(opacity .3s ease);
	&:hover {
	  opacity : 1;
	}
  }
  .ImageSliderPrevNext,
  .ImageSliderClose {
	font-size : 4em;
  }
  .ImageSliderPrev,
  .btn-prev {
	left : 30px;
  }
  .ImageSliderNext,
  .btn-next {
	right : 30px;
  }
  // Hide text, markup order is important for this to work.
  .ImageSliderPrevNext span,
  .ImageSliderClose span {
	display     : block;
	text-indent : 100%;
	overflow    : hidden;
  }
}

// On load fade in of gallery
.details-overlay-slider-wrapper {
  .transition(opacity 1.3s ease);
  opacity : 0;
  &.loaded {
	opacity : 1;
  }
}

@media @tablet-xl {
  .ImageSliderOuter.fullscreen {
	.ImageSliderPrevNext,
	.ImageSliderClose {
	  font-size : 3em;
	}
  }
}

@media @mobile {
  .ImageSliderOuter.fullscreen {
	.ImageSliderPrevNext,
	.btn-prev, .btn-next {
	  display : none;
	}
	.ImageSliderClose {
	  font-size : 2em;
	}
  }
}
